import { makeObservable, observable, action } from 'mobx';

class LoadingStore {
  appLoading = false;

  constructor() {
    makeObservable(this, {
        appLoading: observable,
        setAppLoading: action,
    });
  }

  setAppLoading(status: boolean) {
    this.appLoading = status;
  }
}
const loadingStore = new LoadingStore();
export default loadingStore;