import { Tooltip, IconButton, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper'
import useStore from '../../../../../../store'
import EditIcon from '../../../../../../assets/images/note.png'
import { observer } from 'mobx-react'

const DefaultEarningPoints = observer(() => {
    const { DEFAULT_POINTS } = useStore()
    const navigate = useNavigate()
    const { fetchDefaultPointTypesList } = DEFAULT_POINTS
    const [rowData, setRowData] = useState<any>([]);
  
    const actionColumnRenderer = (params: any) => {
      return (
        <>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                navigate('edit/' + params?.data?.enc_id)
              }}
            >
             <img src={EditIcon} alt="edit" className='editicon' />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  
  
    const [columnDefs, ] = useState<any>([
      {
        headerName: 'Point Type',
        field: 'point_type',
      },
      {
        headerName: 'Point Applicable To',
        field: 'point_applicable_to',
      },
      {
        headerName: 'Point Value Type ',
        field: 'point_value_type',
      },
      {
        headerName: 'Action',
        field: 'action',
        sortable : false,
        filter: false,
        cellRenderer: actionColumnRenderer,
      }
    ])
  
    useEffect(()=> {
      fetchDefaultPointTypesList().then((data:any)=> {
         setRowData(data)
      }).catch((error:any)=>{
        setRowData([])
      })
    },[fetchDefaultPointTypesList])
  
    return (
      <>
        <Box sx={{ mt : "20px" }}>
        <AgGridWrapper
          type="clientSide"
          columnDefs={columnDefs}
          rowData={rowData}
        />
        </Box>
      </>
    )
})

export default DefaultEarningPoints