import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import ScoreboardRoundedIcon from '../../../../../assets/images/bar-chart.png';
import PersonRemoveRoundedIcon from '../../../../../assets/images/goals.png';

const tabRoutes = [
  { label: 'Default Points System', value: '/masters/earning-points/point-system', icon: <img  src={ScoreboardRoundedIcon} alt='ScoreboardRoundedIcon' className='list-icon' /> },
  { label: 'Default Earning Points Cutoff', value: '/masters/earning-points/default-point-cutoffs', icon: <img  src={PersonRemoveRoundedIcon} alt='PersonRemoveRoundedIcon' className='list-icon' />},
  // Add more tabs if needed
];

const EarningPoints = () => {
    const location = useLocation();
    const activeTabValue = tabRoutes.find(tab => location.pathname.startsWith(tab.value))?.value;

    return (
          <>
            <h1>Earning Points</h1>
            <Box sx={{ width: '100%' }}>
            <Tabs value={activeTabValue} aria-label="icon label tabs example"  sx={{
            bgcolor: '#5c5c5c',
          }}>
              {tabRoutes.map(tab => (
                <Tab
                  key={tab.value}
                  icon={tab.icon}
                  label={tab.label}
                  value={tab.value}
                  component={Link}
                  to={tab.value}
                  sx={{
                    color: '#ffff',
                  }}
                />
              ))}
            </Tabs>
            </Box>
            <Outlet />
          </>
    )
}

export default EarningPoints