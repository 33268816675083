import React from 'react'
import {
    Box,
    Button,
  } from '@mui/material'

const CancelOrderComponent = ({ cancelOrderButtonHandle }: any) => {
  return (
    <>
    <Box style={{ textAlign: 'right', marginBottom: '20px', marginTop : '10px' }}>
          <Button variant="contained" color="primary" onClick={cancelOrderButtonHandle}>
              Cancel Order
          </Button>
    </Box>
    </>
  )
}

export default CancelOrderComponent