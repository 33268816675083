import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'

const CancelledStatusRemarksPrompt: React.FC<any> = observer(
  ({ visible, close }) => {
    const { QUOTE } = useStore()
    const { changeStatus, statusValues } = QUOTE
    const [remarks, setRemarks] = useState<any>('');

    const handleRemarksChange = (event: any) => {
        setRemarks(event.target.value);
    };

    const changeStatusHandle = async () => {
      try {
        let _data:any = statusValues
        if(remarks === "" || remarks === null || remarks === undefined){
          toast.error("Please enter remarks!", Toaster); 
          return
        }
        _data.remarks = remarks
        const resData: any = await changeStatus(_data)
        if (resData.error === false) {
          toast.success(resData.message, Toaster)
          close()
        } else {
          toast.error(resData.message, Toaster)
        }
      } catch (err: any) {
        close()
        console.error(err)
        toast.error(err.message, Toaster)
      }
    }

    return (
      <>
        <div>
          <Dialog
            open={visible}
            onClose={close}
            maxWidth={`sm`}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Confirm Cancellation'}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="remarks"
                label="Remarks"
                type="text"
                onChange={handleRemarksChange}
                fullWidth
                variant="standard"
                placeholder='Please enter your remarks'
                multiline
                rows={2}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={close}>Close</Button>
              <Button onClick={changeStatusHandle} autoFocus>
                Yes, Cancel!
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  },
)

export default CancelledStatusRemarksPrompt
