import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Box, CardContent, Typography, TextField, CardActions, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toaster from '../../../../../config/Toaster';
import { BaseResponse } from '../../../../../interfaces/ApiResponse';
import useStore from '../../../../../store';
import * as Yup from "yup";
import { observer } from 'mobx-react'
import AlertComponent, { AlertData } from '../../../../../components/utilities/AlertComponent';

const validationSchema = Yup.object().shape({
    checklist_type : Yup.number(),
    client_id : Yup.number(),
    title: Yup.string().required("Title is required").min(2, "Title must be at least 2 characters long").matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
    description: Yup.string().required('Description is required'),
  });

const DefaultChecklistEdit = observer(() => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { CHECKLIST } = useStore();
    const { fetchChecklist, updateChecklist} = CHECKLIST;
    const {
      handleSubmit,
      setError,
      setValue,
      control,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
      mode: "onTouched",
      defaultValues: {
        checklist_type : 1,
        client_id : 0,
        title: "",
        description: "",
      },
    });
  
    const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  
    const onSubmit = async (_data: any) => {
      try {
        const resData: BaseResponse = await updateChecklist(id, _data);
          if(resData.error === false){
            toast.success(resData?.message, Toaster)
            navigate("../default-checklists")
          }else{
            toast.error(resData?.message, Toaster)
            setAlertMessage({severity:'error', message: resData.message, data: resData.data});

          }
        } catch(err:any){
          console.error(err)
          const errData: BaseResponse = err;
          if(errData.data && errData.data['title']){
            setError("title", {
              type: "manual",
              message: errData.data['title'][0],
            });
          }
          toast.error(errData?.message, Toaster);
          window.scrollTo({top: 0, behavior: 'smooth' });
          setAlertMessage({severity:'error', message: errData.message, data: errData.data});
        }
    };
  
    const fetchChecklistData = async (_id: string | undefined) => {
      try {
        const resData: any = await fetchChecklist(id)
        if (resData.error === false) {
          setValue('title', resData?.data?.checklist?.title || '')
          setValue('description', resData?.data?.checklist?.description || '')
        } else {
           toast.error(resData?.message, Toaster)
           window.scrollTo({top: 0, behavior: 'smooth' });
           setAlertMessage({severity:'error', message: resData.message, data: resData.data});
        }
      } catch (err: any) {
        console.error(err)
        const errData: BaseResponse = err
        toast.error(errData.message, Toaster)
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
    }
  
    useEffect(() => {
      fetchChecklistData(id)
    }, [])
    
    return (
      <>
        <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
        <Card sx={{ maxWidth: "100%"}} variant='outlined'>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ mt: 2, mb: 2 }}
              >
                Edit Default Checklist
              </Typography>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="title"
                        label="Title"
                        variant="outlined"
                        helperText={errors.title?.message}
                        error={errors.title ? true : false}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="description"
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                      helperText={errors.description?.message}
                      error={errors.description ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
        
              </Grid>
            </CardContent>
            <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
                Save
              </Button>
              <Button type="button" variant="outlined" size="medium" onClick={ (e) => navigate(-1)}>
                Cancel
              </Button>
            </CardActions>
          </Box>
        </Card>
      </>
    );
})

export default DefaultChecklistEdit