export const PER_PAGE_RECORD = [
  { id: 10, value: "10" },
  { id: 20, value: "20" },
  { id: 50, value: "50" },
  { id: 100, value: "100" }
]

export const dateFormat =  "YYYY-MM-DD"
export const dateTimeFormat =  "YYYY-MM-DD HH:mm:ss"

export const DEFAULT_CURRENCY = "USD"

export const DEBOUNCE_AFTER_CHARS = 3 

export const CHANGE_STATUS = "Change Status"

export const USER_STATUSES = [
  { id : 1, name : "Active" },
  { id : 0, name : "Inactive" },
]

export const CURRENCIES = [
  { id : 1, label : "USD" },
  { id : 2, label : "AUD" },
  { id : 3, label : "EUR" },
  { id : 4, label : "GBP" },
  { id : 5, label : "CAD" },
] 

export const QUOTE_STATUSES = [
  { id: 0, name: 'In Discussion' },
  { id: 1, name: 'In Review' },
  { id: 2, name: 'Confirmed' },
  { id: 3, name: 'Cancelled' },
]

export const CLIENT_TYPES = [
  { id: 0, name: 'Direct Client' },
  { id: 1, name: 'Agency' },
  { id: 2, name: 'ESP' },
  { id: 3, name: 'Freelancer' },
]

export const DISCUSSION_TYPES = [
  { id: 0, name: 'Quote Discussion with Prospect' },
  { id: 1, name: 'Client Discussion' },
  { id: 2, name: 'Client Query Solving' },
]

export const DISCUSSION_STATUSES = [
  { id: 0, name: 'Pending' },
  { id: 1, name: 'Approved' },
  { id: 2, name: 'Declined' },
]

export const ESCALATION_ZONE = [
  { id: 'Green', label: 'Green' },
  { id: 'Yellow', label: 'Yellow' },
  { id: 'Red', label: 'Red' },
]
export const ESCALATION_TYPE = [
  { id: "Internal", name: 'Internal' },
  { id: "Client Side", name: 'Client Side' },
]
export const ESCALATION_IMPACT = [
  { id: 'Minor', name: 'Minor' },
  { id: 'Major', name: 'Major' },
  { id: 'Super Major', name: 'Super Major' },
]
export const ESCALATION_STATUSES = [
  { id: 0, name: 'In Discussion' },
  { id: 1, name: 'Resolved' },
  { id: 2, name: 'Cancelled' },
]

export const ORDER_STATUSES = [
  { id: 0, name: 'Under Review' },
  { id: 1, name: 'Assigned' },
  { id: 2, name: 'In Progress' },
  { id: 3, name: 'Completed' },
  { id: 4, name: 'Cancelled' },
]

export const EMAIL_ONLY_CODING = "Email Only Coding"
export const EMAIL_DESIGN_AND_CODING = "Email Design + Coding"

export const EMAIL_SERVICES = [
  { id: 0, label: "Email Only Coding" },
  { id: 1, label: "Email Design + Coding" },
  { id: 2, label: "LP Only Coding" },
  { id: 3, label: "LP Design + Coding" },
]

export const HEIGHTS = [
  { id: "1px to 4000px", label: "1px to 4000px" },
  { id: "4001px to 6000px", label: "4001px to 6000px" },
  { id: "6001px to 8000px", label: "6001px to 8000px" },
  { id: "8001px to 10000px", label: "8001px to 10000px" },
  { id: "10001px and above", label: "10001px and above" },
]

export const CHANGE_REQUEST_TYPES = [
  { id: 0, name: 'Changes' },
  { id: 1, name: 'New Request' },
]

export const BACKLOG_STATUSES = [
  { id : 1, name : "In Review"},
  { id : 2, name : "Confirmed"},
  { id : 3, name : "In Progress"},
  { id : 4, name : "Ready to deliver"},
  { id : 5, name : "Delivered"},
  { id : 6, name : "Cancelled"},
]

export const CHANGE_REQUEST_STATUSES = [
  { id : 1, name : "In Review"},
  { id : 2, name : "Confirmed"},
  { id : 3, name : "In Progress"},
  { id : 4, name : "Ready to deliver"},
  { id : 5, name : "Delivered"},
  { id : 6, name : "Cancelled"},
]

export const EXTRA_POINT_STATUSES = [
  { id: 0, name: 'Pending' },
  { id: 1, name: 'Approved' },
  { id: 2, name: 'Cancelled' },
]

export const ESP_TYPES = [
  { id : 0, name : "Basic" },
  { id : 1, name : "Advance" },
]

export const TIME_SLUG = [
  { id : 0, name : "Hrs" },
  { id : 1, name : "Days" },
]

export const COUPON_VALUE_TYPES = [
  { id : 0, label : "Fixed" },
  { id : 1, label : "Percentage" },
]

export const COUPON_RESTRICTION_TYPES = [
  { id : 0, label : "By Domain" },
  { id : 1, label : "By Email" },
] 
