import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import AssignOrderIcon from '../../../../assets/images/assign-order.png'
import OrderInfoIcon from '../../../../assets/images/info.png'
import OrderCompletedIcon from '../../../../assets/images/order-completed.png'
import OrderCancelledIcon from '../../../../assets/images/order-cancelled.png'
import Box from '@mui/material/Box'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'

const OrderList = observer(() => {
  const { ORDER } = useStore()
  const navigate = useNavigate()
  const { setupGrid, setPageSize, perPage, setCurrentOrderStatusValue, setCurrentOrderIDValue } = ORDER

  const actionColumnRenderer = (params: any) => {
    return (
      <>
      {params.data.order_status && params.data.order_status === 'Under Review' && <>
          <Tooltip title="Assign Order">
            <IconButton
              onClick={() => {
                navigate('../assign-order/' + params.data.enc_order_id)
              }}
            >
              <img src={AssignOrderIcon} alt="AssignOrderIcon" className='assignicon' />
            </IconButton>
          </Tooltip>
      </>}
      {params.data.order_status && (params.data.order_status === 'Assigned' || params.data.order_status === 'In Progress' || params.data.order_status === 'Completed') && <>
        <Tooltip title="Order Info">
            <IconButton
              onClick={() => {
                setCurrentOrderIDValue(params?.data?.order_id)
                setCurrentOrderStatusValue(params?.data?.order_status)
                navigate('../templates/' + params.data.enc_order_id)
              }}
            >
              { params.data.order_status === "Completed" ? <img src={OrderCompletedIcon} alt="OrderCompletedIcon"/> : <img src={OrderInfoIcon} alt="OrderInfoIcon" className='' /> }
            </IconButton>
          </Tooltip>
        </>}
        {(params.data.order_status && (params.data.order_status === 'Cancelled')) && <>
            <Tooltip title={params.data.order_status}>
              <IconButton disabled={true}>
                <img src={OrderCancelledIcon} alt="OrderCancelledIcon" />
              </IconButton>
            </Tooltip>
        </>}
      </>
    )
  }

  const [columnDefs, ] = useState<any>([
    {
      headerName: 'Order ID',
      field: 'order_id',
    },
    {
      headerName: 'Client Email',
      field: 'client_email',
    },
    {
      headerName: 'TL',
      field: 'assign_tl_name',
      sortable: false,
    },
    {
      headerName: 'AM',
      field: 'assign_am_name',
      sortable: false,
    },
    {
      headerName: 'GEO',
      field: 'client_geo_name',
    },
    {
      headerName: 'Final Amount',
      field: 'final_amount',
    },
    {
      headerName: 'Currency',
      field: 'currency',
    },
    {
      headerName: 'Engagement Type',
      field: 'engagement_type',
    },
    {
      headerName: 'Source',
      field: 'source',
    },
    {
      headerName: 'Invoice Pattern',
      field: 'invoice_pattern',
    },
    { 
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter', 
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false, 
      },
      pinned: 'right'
    },
    {
      headerName: 'Status',
      field: 'order_status',
      pinned: 'right'
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 70,
      minWidth: 80,
      pinned: 'right'
    },
  ])

  return (
    <>
      <h1>Orders</h1>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
      />
      <Box className="record-per-page-filter" sx={{mt: '10px', ml: '-10px'}}>
        <RecordPerPage
          style={{ width: '150px' }}
          defaultValue={`${perPage}`}
          onChange={(e: any) => setPageSize(e.target.value)}
        />
      </Box>
    </>
  )
})

export default OrderList
