import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

interface LoaderComponentProps {
  open: boolean
}

const LoaderComponent: React.FC<LoaderComponentProps> = ({ open }) => {
  return (
    <>
      <Backdrop
        sx={{ color: '#000', zIndex: (theme) => 9 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default LoaderComponent
