import axios from 'axios'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import { AddonListProps } from './AddonInterface'
import { CHANGE_STATUS } from '../../config/Constant'

export default class AddonStore {
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: AddonListProps[]
  editValues = null
  deleteValues = null

  constructor() {
    makeAutoObservable(this)
  }

  // API Functions
  public fetchAddonList = async (): Promise<any> => {
    try{
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.ADDON.LIST)
      loadingStore.setAppLoading(false)
        return Promise.resolve(data.data.addons)
    } catch(err: any) {
      loadingStore.setAppLoading(false)
        return Promise.reject(err.response.data)
    }
  }

  public getCreateFormFieldData = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const {data} = await axios.post(API_URL.ADDON.GET_LIST_ITEMS, _data)
      let dataObj = {
        data: data
      }
      loadingStore.setAppLoading(false)
      return Promise.resolve(dataObj)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public createAddon = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.ADDON.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public fetchAddon = async (_id: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.ADDON.VIEW(_id))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateAddon = async (_id: any, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.ADDON.UPDATE(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public changeStatus = async (_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     _data.action_type = CHANGE_STATUS;
     const { data } = await axios.put(API_URL.ADDON.CHANGE_STATUS(_data.id),_data)
    //  this.setupGrid(this.agGridParams)
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }
}
