import React, { useState } from 'react'
import useStore from '../../../../store'
import Box from '@mui/material/Box'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'
import { observer } from 'mobx-react'

const FeedbackList = observer(() => {
  const { FEEDBACK } = useStore()
  const { setupGrid, setPageSize, perPage } = FEEDBACK

  const [columnDefs, ] = useState<any>([
    {
      headerName: 'Order ID',
      field: 'order_id',
    },
    {
        headerName: 'Client Email',
        field: 'client_email_address',
        sortable: false,
    },
    {
      headerName: 'GEO',
      field: 'geo_name',
    },
    {
      headerName: 'Status',
      field: 'status',
    }
  ])

  return (
    <>
     <h1>Feedbacks</h1>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
      />
      <Box className="record-per-page-filter" sx={{ mt : '10px' , ml: '-10px' }}>
        <RecordPerPage
          style={{ width: "150px" }}
          defaultValue={`${perPage}`}
          onChange={(e:any)=> setPageSize(e.target.value)}
        />
      </Box>
    </>
  )
})

export default FeedbackList
