import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Box, CardContent, Typography, TextField, CardActions, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toaster from '../../../../../config/Toaster';
import { BaseResponse } from '../../../../../interfaces/ApiResponse';
import useStore from '../../../../../store';
import * as Yup from "yup";
import { observer } from 'mobx-react'
import AlertComponent, { AlertData } from '../../../../../components/utilities/AlertComponent';

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Shift name is required").min(2, "Shift name must be at least 2 characters long").matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
  });

const ShiftEdit = observer(() => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { SHIFT } = useStore();
    const { fetchShift, updateShift} = SHIFT;
    const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
    
    const {
      handleSubmit,
      setError,
      setValue,
      control,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
      mode: "onTouched",
      defaultValues: {
        name: ""
      },
    });
  
  
  
    const onSubmit = async (_data: any) => {
      try {
        const resData: BaseResponse = await updateShift(id, _data);
          if(resData.error === false){
            toast.success(resData?.message, Toaster)
            navigate("../shifts")
          }else{
            toast.error(resData?.message, Toaster)
          }
        } catch(err:any){
          console.error(err)
          const errData: BaseResponse = err;
          if(errData.data && errData.data['name']){
            setError("name", {
              type: "manual",
              message: errData.data['name'][0],
            });
          }
          toast.error(errData?.message, Toaster);
        }
    };
  
    const fetchShiftData = async (_id: string | undefined) => {
    
      try {
        const resData: any = await fetchShift(id)
        if (resData.error === false) {
          await setValue('name', resData.data.shift?.name || '')
        } else {
          setAlertMessage({severity:'error', message: resData.message, data: resData.data});

           toast.error(resData?.message, Toaster)
        }
      } catch (err: any) {
        console.error(err)
        const errData: BaseResponse = err
        toast.error(errData.message, Toaster)
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
    }
  
    useEffect(() => {
      fetchShiftData(id)
    }, [])
    
    return (
      <>
        <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
        <Card sx={{ maxWidth: "100%"}} variant='outlined'>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ mt: 2, mb: 2 }}
              >
                Edit Shift
              </Typography>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={6}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="name"
                        label="Shift Name"
                        variant="outlined"
                        helperText={errors.name?.message}
                        error={errors.name ? true : false}
                        {...field}
                      />
                    )}
                  />
                </Grid>
        
              </Grid>
            </CardContent>
            <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
                Save
              </Button>
              <Button type="button" variant="outlined" size="medium" onClick={ (e) => navigate(-1)}>
                Cancel
              </Button>
            </CardActions>
          </Box>
        </Card>
      </>
    );
})

export default ShiftEdit