import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  CardActions,
  Button,
  Grid,
  CircularProgress,
  InputAdornment,
} from '@mui/material'

import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import * as uuid from 'uuid'
import AlertComponent, {
  AlertData,
} from '../../../../../../components/utilities/AlertComponent'
import Toaster from '../../../../../../config/Toaster'
import { BaseResponse } from '../../../../../../interfaces/ApiResponse'
import useStore from '../../../../../../store'

const CurrencyConversion = observer(() => {
  const { CURRENCY } = useStore()
  const { updateCurrencyConverion, fetchCurrencyConverionList } = CURRENCY
  const [alertMessage, setAlertMessage] = useState<AlertData | null>(null)
  const [lastUpdatedTime, setLastUpdatedTime] = useState<any>(null)


  const validationSchema = Yup.object().shape({
    currencies: Yup.array().of(
        Yup.object().shape({
          currency: Yup.string().nullable(),
          conversion_rate: Yup.number().required().typeError('Please enter valid rate').min(0,'Value must be great or equal to 0'),
        }),
      ),
  })

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      currencies: [],
    },
  })

  const {
    fields: currencyFields,
  } = useFieldArray<any>({
    control,
    name: 'currencies',
  })

  const fetchCurrencyConverionListData = async () => {
    try {
        const resData: any = await fetchCurrencyConverionList()
        if (resData.error === false) {
          setValue("currencies", resData?.data?.currency_conversion_rates)
          setLastUpdatedTime(resData?.data?.currency_conversion_rates?.[0]?.updated_at)
        } else {
          setAlertMessage({severity:'error', message: resData.message, data: resData.data});
          toast.error(resData?.message, Toaster)
        }
      } catch (err: any) {
        console.error(err)
        const errData: BaseResponse = err
        toast.error(errData.message, Toaster)
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
  }

  const onSubmit = async (_data: any) => {
      try {
      if(_data.currencies === undefined || _data.currencies === null || _data.currencies.length === 0) {
        toast.error("Currencies values are missing!", Toaster)
         return;
      }  
      const postData = _data
      const resData: BaseResponse = await updateCurrencyConverion(postData);
      if (resData.error === false) {
        setLastUpdatedTime(resData?.data?.lastUpdateAt)
        toast.success(resData?.message, Toaster)
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setAlertMessage({
          severity: 'error',
          message: resData.message,
          data: resData.data,
        })
        toast.error(resData?.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setAlertMessage({
        severity: 'error',
        message: errData.message,
        data: errData.data,
      })
      toast.error(errData?.message, Toaster)
    }
  }

  useEffect(() => {
    fetchCurrencyConverionListData()
  }, [])


  console.error(errors)

  return (
    <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Currency Conversion Rate
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={12}>
                {currencyFields ? (
                  currencyFields.map((currency: any, currencyIndex: any) => (
                    <Box key={currencyIndex} sx={{ mb: '10px' }}>
                          <Box sx={{ px: 10, py: 2 }}>
                            <Grid container spacing={2}>
                                  <Grid
                                    sx={{
                                      mb: '4px',
                                      mt: '4px',
                                      alignItems: 'center',
                                    }}
                                    container
                                    spacing={1}
                                    key={currencyIndex}
                                  >
                                    <Grid item xs={2}>
                                      <label
                                        htmlFor={`currencies.${currencyIndex}.id`}
                                      >
                                        1 USD =
                                      </label>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`currencies.${currencyIndex}.conversion_rate`}
                                        control={control}
                                        render={({
                                          field,
                                          fieldState,
                                        }: any) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            type="number"
                                            id={`currencies.${currencyIndex}.conversion_rate`}
                                            label="Conversion Rate"
                                            variant="outlined"
                                            onChange={(e: any, newVal: any) => {
                                              field.onChange(e);
                                            }}
                                            error={fieldState.invalid}
                                            helperText={
                                              fieldState?.error?.message
                                            }
                                            InputProps={{
                                              inputProps: { min: 0 }, 
                                              endAdornment: (
                                                <InputAdornment position="end">x {currency.currency}</InputAdornment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                            </Grid>
                          </Box>
                    </Box>
                  ))
                ) : (
                  <CircularProgress /> // or any loading indicator
                )}
              </Grid>
            </Grid>
            <p>
               Last updated at : { lastUpdatedTime }
             </p>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default CurrencyConversion
