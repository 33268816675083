import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Login from "../views/app_pages/auth/Login";
import PublicLayout from "../views/layouts/PublicLayout";
import ProtectedLayout from "../views/layouts/ProtectedLayout";
import Dashboard from "../views/app_pages/dashboard/Dashboard";
import PageNotFound from "../views/errors/PageNotFound";
import RoleList from "../views/app_pages/dashboard/roles/RoleList";
import ProfileManagement from "../views/app_pages/dashboard/settings/Profile";
import ForgetPassword from "../views/app_pages/auth/ForgetPassword";
import ResetPassword from "../views/app_pages/auth/ResetPassword";
import UserList from "../views/app_pages/dashboard/users/UserList";
import UserCreate from "../views/app_pages/dashboard/users/UserCreate";
import UserEdit from "../views/app_pages/dashboard/users/UserEdit";
import RoleCreate from "../views/app_pages/dashboard/roles/RoleCreate";
import RoleEdit from "../views/app_pages/dashboard/roles/RoleEdit";
import QuoteCreate from "../views/app_pages/dashboard/quotes/QuoteCreate";
import QuoteEdit from "../views/app_pages/dashboard/quotes/QuoteEdit";
import QuoteList from "../views/app_pages/dashboard/quotes/QuoteList";
import ClientCreate from "../views/app_pages/dashboard/clients/ClientCreate";
import ClientEdit from "../views/app_pages/dashboard/clients/ClientEdit";
import ClientList from "../views/app_pages/dashboard/clients/ClientList";
import DiscussionList from "../views/app_pages/dashboard/discussions/DiscussionList";
import DiscussionCreate from "../views/app_pages/dashboard/discussions/DiscussionCreate";
import DiscussionEdit from "../views/app_pages/dashboard/discussions/DiscussionEdit";
import Masters from "../views/app_pages/dashboard/masters/Masters";
import DepartmentList from "../views/app_pages/dashboard/masters/departments/DepartmentList";
import DepartmentCreate from "../views/app_pages/dashboard/masters/departments/DepartmentCreate";
import DepartmentEdit from "../views/app_pages/dashboard/masters/departments/DepartmentEdit";
import ShiftList from "../views/app_pages/dashboard/masters/shifts/ShiftList";
import ShiftCreate from "../views/app_pages/dashboard/masters/shifts/ShiftCreate";
import ShiftEdit from "../views/app_pages/dashboard/masters/shifts/ShiftEdit";
import FeedbackList from "../views/app_pages/dashboard/feedbacks/FeedbackList";
import OrderList from "../views/app_pages/dashboard/orders/OrderList";
import EscalationList from "../views/app_pages/dashboard/escalations/EscalationList";
import EscalationCreate from "../views/app_pages/dashboard/escalations/EscalationCreate";
import Escalation from "../views/app_pages/dashboard/masters/escalations/Escalation";
import CategoryList from "../views/app_pages/dashboard/masters/escalations/category/CategoryList";
import CategoryCreate from "../views/app_pages/dashboard/masters/escalations/category/CategoryCreate";
import SubCategoryList from "../views/app_pages/dashboard/masters/escalations/category/SubCategoryList";
import SubCategoryCreate from "../views/app_pages/dashboard/masters/escalations/category/SubCategoryCreate";
import AssignOrder from "../views/app_pages/dashboard/orders/AssignOrder";
import OrderInfo from "../views/app_pages/dashboard/orders/OrderInfo";
import EarningPoints from "../views/app_pages/dashboard/masters/earning-points/EarningPoints";
import Parameters from "../views/app_pages/dashboard/masters/parameters/Parameters";
import DefaultPointCutoffList from "../views/app_pages/dashboard/masters/earning-points/DefaultPointCutoffs/DefaultPointCutoffList";
import DefaultPointCutoffCreate from "../views/app_pages/dashboard/masters/earning-points/DefaultPointCutoffs/DefaultPointCutoffCreate";
import DefaultPointCutoffEdit from "../views/app_pages/dashboard/masters/earning-points/DefaultPointCutoffs/DefaultPointCutoffEdit";
import DefaultEarningPoints from "../views/app_pages/dashboard/masters/earning-points/PointSystem/DefaultEarningPoints";
import DefaultEarningPointsEdit from "../views/app_pages/dashboard/masters/earning-points/PointSystem/DefaultEarningPointsEdit";
import ESPList from "../views/app_pages/dashboard/masters/parameters/ESP/ESPList";
import ESPCreate from "../views/app_pages/dashboard/masters/parameters/ESP/ESPCreate";
import ESPEdit from "../views/app_pages/dashboard/masters/parameters/ESP/ESPEdit";
import AddonList from "../views/app_pages/dashboard/masters/parameters/Addons/AddonList";
import AddonCreate from "../views/app_pages/dashboard/masters/parameters/Addons/AddonCreate";
import AddonEdit from "../views/app_pages/dashboard/masters/parameters/Addons/AddonEdit";
import LayoutList from "../views/app_pages/dashboard/masters/parameters/Layouts/LayoutList";
import LayoutCreate from "../views/app_pages/dashboard/masters/parameters/Layouts/LayoutCreate";
import LayoutEdit from "../views/app_pages/dashboard/masters/parameters/Layouts/LayoutEdit";
import TimeslotList from "../views/app_pages/dashboard/masters/parameters/Timeslots/TimeslotList";
import TimeslotCreate from "../views/app_pages/dashboard/masters/parameters/Timeslots/TimeslotCreate";
import TimeslotEdit from "../views/app_pages/dashboard/masters/parameters/Timeslots/TimeslotEdit";
import CurrencyConversion from "../views/app_pages/dashboard/masters/parameters/CurrencyConversion/CurrencyConversion";
import ExtraPointList from "../views/app_pages/dashboard/extra-points/ExtraPointList";
import ExtraPointCreate from "../views/app_pages/dashboard/extra-points/ExtraPointCreate";
import ExtraPointEdit from "../views/app_pages/dashboard/extra-points/ExtraPointEdit";
import ClientInfo from "../views/app_pages/dashboard/clients/ClientInfo";
import EarningPointLogList from "../views/app_pages/dashboard/earning-points-log/EarningPointLogList";
import useStore from "../store";
import AllPermissionList from "../config/AllPermissionList";
import Unauthorized from "../views/errors/Unauthorized";
import EscalationEdit from "../views/app_pages/dashboard/escalations/EscalationEdit";
import ZoneList from "../views/app_pages/dashboard/zones/ZoneList";
import ZoneCreate from "../views/app_pages/dashboard/zones/ZoneCreate";
import ZoneEdit from "../views/app_pages/dashboard/zones/ZoneEdit";
import CouponList from "../views/app_pages/dashboard/masters/coupons/CouponList";
import CouponCreate from "../views/app_pages/dashboard/masters/coupons/CouponCreate";
import CouponEdit from "../views/app_pages/dashboard/masters/coupons/CouponEdit";
import ChangePassword from "../views/app_pages/dashboard/settings/ChangePassword";
import Checklist from "../views/app_pages/dashboard/checklists/Checklist";
import DefaultChecklistList from "../views/app_pages/dashboard/checklists/checklist/DefaultChecklistList";
import DefaultChecklistCreate from "../views/app_pages/dashboard/checklists/checklist/DefaultChecklistCreate";
import ClientChecklistList from "../views/app_pages/dashboard/checklists/checklist/ClientChecklistList";
import ClientChecklistCreate from "../views/app_pages/dashboard/checklists/checklist/ClientChecklistCreate";
import DefaultChecklistEdit from "../views/app_pages/dashboard/checklists/checklist/DefaultChecklistEdit";
import ClientChecklistEdit from "../views/app_pages/dashboard/checklists/checklist/ClientChecklistEdit";

export default function Router() {
  const { AUTH } = useStore();
	const { permissions } = AUTH

  function hasPermission(permission: any) {
    return permissions && permissions.indexOf(permission) >= 0
  }

  const RouterConfig = [
    {
      element: <ProtectedLayout />,
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/users",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_USERS) ? <UserList /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "create",
              element: hasPermission(AllPermissionList.ADD_USER) ? <UserCreate /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "edit/:id",
              element: hasPermission(AllPermissionList.EDIT_USER) ? <UserEdit /> : <Navigate to="/unauthorized" />,
            },
          ]
        },
        {
          path: "/clients",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_CLIENTS) ? <ClientList /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "create",
              element: hasPermission(AllPermissionList.ADD_CLIENT) ? <ClientCreate /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "edit/:id",
              element: hasPermission(AllPermissionList.EDIT_CLIENT) ? <ClientEdit /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "orders/:id",
              element: <ClientInfo/>,
            },
            {
              path: "summary/:id",
              element: <ClientInfo/>,
            },
          ]
        },
        {
          path: "/quotes",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_QUOTES) ? <QuoteList /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "create",
              element: hasPermission(AllPermissionList.ADD_QUOTE) ? <QuoteCreate /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "edit/:id",
              element: hasPermission(AllPermissionList.EDIT_QUOTE) ? <QuoteEdit /> : <Navigate to="/unauthorized" />,
            },
          ]
        },
        {
          path: "/orders",
          children: [
            {
              path: "",
              element: <Navigate to="list" />
            },
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_ORDERS) ? <OrderList /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "assign-order/:id",
              element: <AssignOrder/>,
            },
            {
              path: "templates/:id",
              element: <OrderInfo/>,
            },
            {
              path: "change-requests/:id",
              element: hasPermission(AllPermissionList.LIST_ORDER_CHANGE_REQUESTS)  ? <OrderInfo /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "backlogs/:id",
              element: hasPermission(AllPermissionList.LIST_ORDER_BACKLOGS) ? <OrderInfo /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "details/:id",
              element: <OrderInfo/>,
            },
            {
              path: "team/:id",
              element: <OrderInfo/>,
            },
          ]
        },
        {
          path: "checklists",
          element: <Checklist/>,
          children: [
            {
              path: "",
              element: <Navigate to="default-checklists" />
            },
            {
              path: "default-checklists",
              element: hasPermission(AllPermissionList.LIST_CHECKLISTS) ? (
                <DefaultChecklistList/>
              ) : (
                <Navigate to="/unauthorized" />
              ),
            },
            {
              path: "default-checklists/create",
              element: hasPermission(AllPermissionList.ADD_CHECKLIST) ? (
                <DefaultChecklistCreate/>
              ) : (
                <Navigate to="/unauthorized" />
              ),
            },
            {
              path: "default-checklists/edit/:id",
              element: hasPermission(AllPermissionList.EDIT_CHECKLIST) ? (
                <DefaultChecklistEdit/>
              ) : (
                <Navigate to="/unauthorized" />
              ),
            },
            {
              path: "client-checklists",
              element: hasPermission(AllPermissionList.LIST_CHECKLISTS) ? (
               <ClientChecklistList/>
              ) : (
                <Navigate to="/unauthorized" />
              ),
            },
            {
              path: "client-checklists/create",
              element: hasPermission(AllPermissionList.ADD_CHECKLIST) ? (
                <ClientChecklistCreate/>
              ) : (
                <Navigate to="/unauthorized" />
              ),
            },
            {
              path: "client-checklists/edit/:id",
              element: hasPermission(AllPermissionList.EDIT_CHECKLIST) ? (
                <ClientChecklistEdit/>
              ) : (
                <Navigate to="/unauthorized" />
              ),
            },
          ]
        },
        {
          path: "/discussions",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_DISCUSSIONS) ? <DiscussionList /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "create",
              element: hasPermission(AllPermissionList.ADD_DISCUSSION) ? <DiscussionCreate /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "edit/:id",
              element: hasPermission(AllPermissionList.EDIT_DISCUSSION) ? <DiscussionEdit /> : <Navigate to="/unauthorized" />,
            },
          ]
        },
        {
          path: "/extra-points",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_EXTRA_POINTS) ? <ExtraPointList /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "create",
              element: hasPermission(AllPermissionList.ADD_EXTRA_POINT) ? <ExtraPointCreate /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "edit/:id",
              element: hasPermission(AllPermissionList.EDIT_EXTRA_POINT) ? <ExtraPointEdit /> : <Navigate to="/unauthorized" />,
            },
          ]
        },
        {
          path: "/earning-points-log",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_EARNING_POINTS) ? <EarningPointLogList /> : <Navigate to="/unauthorized" />,
            },
          ]
        },
        {
          path: "/escalations",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_ESCALATIONS) ? <EscalationList /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "create",
              element: hasPermission(AllPermissionList.ADD_ESCALATION) ? <EscalationCreate /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "edit/:id",
              element: hasPermission(AllPermissionList.EDIT_ESCALATION) ? <EscalationEdit /> : <Navigate to="/unauthorized" />,
            },
          ]
        },
        {
          path: "/zones",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_ZONES) ? <ZoneList /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "create",
              element: hasPermission(AllPermissionList.ADD_ZONE) ? <ZoneCreate /> : <Navigate to="/unauthorized" />,
            },
            {
              path: "edit/:id",
              element: hasPermission(AllPermissionList.EDIT_ZONE) ? <ZoneEdit /> : <Navigate to="/unauthorized" />,
            },
          ]
        },
        {
          path: "/feedbacks",
          children: [
            {
              path: "list",
              element: hasPermission(AllPermissionList.LIST_FEEDBACKS) ? <FeedbackList /> : <Navigate to="/unauthorized" />,
            }
          ]
        },
        {
          path: "/masters",
          children: [
            {
              path: "",
              element: <Navigate to="users" />
            },
            {
              path: "users",
              element: <Masters />,
              children: [
                {
                  path: "",
                  element: <Navigate to="departments" />
                },
                {
                  path: "departments",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <DepartmentList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "departments/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <DepartmentCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "departments/edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <DepartmentEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "shifts",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <ShiftList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "shifts/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <ShiftCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "shifts/edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <ShiftEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
              ]
            },
            {
              path: "escalations",
              element: <Escalation />,
              children: [
                {
                  path: "",
                  element: <Navigate to="catgories" />
                },
                {
                  path: "catgories",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <CategoryList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "catgories/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <CategoryCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "subcategories",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <SubCategoryList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "subcategories/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <SubCategoryCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                }
              ]
            },
            {
              path: "earning-points",
              element: <EarningPoints />,
              children: [
                {
                  path: "",
                  element: <Navigate to="point-system" />
                },
                {
                  path: "point-system",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <DefaultEarningPoints />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "point-system/edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <DefaultEarningPointsEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "default-point-cutoffs",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <DefaultPointCutoffList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "default-point-cutoffs/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <DefaultPointCutoffCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "default-point-cutoffs/edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <DefaultPointCutoffEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
              ]
            },
            {
              path: "parameters",
              element: <Parameters />,
              children: [
                {
                  path: "",
                  element: <Navigate to="esps" />
                },
                {
                  path: "esps",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <ESPList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "esps/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <ESPCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "esps/edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <ESPEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "addons",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <AddonList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "addons/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <AddonCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "addons/edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <AddonEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "layouts",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <LayoutList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "layouts/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <LayoutCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "layouts/edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <LayoutEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "timeslots",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <TimeslotList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "timeslots/create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <TimeslotCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "timeslots/edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <TimeslotEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "currency-conversion-rates",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <CurrencyConversion />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
              ]
            },
            {
              path: "coupons",
              children: [
                {
                  path: "",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <CouponList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <CouponCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <CouponEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
              ]
            },
            {
              path: "roles-permissions",
              children: [
                {
                  path: "",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <RoleList />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "create",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <RoleCreate />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
                {
                  path: "edit/:id",
                  element: hasPermission(AllPermissionList.ACCESS_ALL_MASTERS) ? (
                    <RoleEdit />
                  ) : (
                    <Navigate to="/unauthorized" />
                  ),
                },
              ]
            },
          ]
        },
        {
          path: "/dashboard/profile",
          element: <ProfileManagement />,
        },
        {
          path: "/dashboard/change-password",
          element: <ChangePassword />,
        }
      ],
    },
    {
      element: <PublicLayout />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "/reset-password/:token",
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/unauthorized",
      element: <Unauthorized />,
    },
  ];

  const element = useRoutes(RouterConfig);
  return element;
}
