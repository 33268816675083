import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  CardActions,
  Button,
  Grid,
  Autocomplete,
} from '@mui/material'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import AlertComponent, {
  AlertData,
} from '../../../../components/utilities/AlertComponent'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import Toaster from '../../../../config/Toaster'
import { toast } from 'react-toastify'
import UserPointFields from './UserPointFields'

const ExtraPointEdit = observer(() => {
  const { id } = useParams()
  const { EXTRA_POINT } = useStore()
  const { updateExtraPoints, fetchExtraPoint } = EXTRA_POINT
  const [alertMessage, setAlertMessage] = useState<AlertData | null>(null)
  const navigate = useNavigate()
  const [extraPointTypes, setExtraPointTypes] = useState<any>([])
  const [dropdownUsers, setDrodownUsers] = useState<any>([])

  const validationSchema = Yup.object().shape({
    extra_point_type_id: Yup.object({
      label: Yup.string().required('Extra point type is required'),
      id: Yup.number().nullable(),
    }).required('Extra point type is required'),
    order_id: Yup.string().nullable().test('special-characters', 'Special characters are not allowed', function(value: any) {
      if (value !== "" && value !== null && value !== undefined) {
        return /^[a-zA-Z0-9 ]+$/.test(value);
      }
      return true; // Return true for null values to skip validation
    }),
    remarks : Yup.string().required('Remarks is required'),
    users_points: Yup.array().of(
      Yup.object().shape({
        user_id: Yup.object({
          label: Yup.string().required('Please select user'),
          id: Yup.number().nullable(),
        }).required('Please select user'),
        points: Yup.number()
          .required('Points are required')
          .typeError('Please enter valid points')
          .min(-99, 'Points must be greater than -99')
          .max(100, 'Points must be less or equal to 100').test('not-zero', "Points shouldn't be 0", (value: any) => value !== 0),
      }),
    ),
  })

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      extra_point_type_id: { label: '', id: null },
      order_id: '',
      remarks: '',
      users_points: [],
    },
  })

  const {
    fields: userFields,
    append: appendUserFields,
    remove: removeUserFields,
  } = useFieldArray<any>({
    control,
    name: 'users_points',
  })

  function hasUniqueUserIds(data: any) {
    const userIds = new Set()
    for (const item of data) {
      if (userIds.has(item?.user_id?.id)) {
        return false
      }
      userIds.add(item?.user_id?.id)
    }
    // it's unique
    return true
  }

  const onSubmit = async (_data: any) => {
    try {
      if (_data?.users_points) {
        if (_data?.users_points.length <= 0) {
          toast.error("Please add alteast one users's points", Toaster)
          return
        }
      }
      const areUserIdsUnique = hasUniqueUserIds(_data?.users_points)
      if (areUserIdsUnique === false) {
        toast.error('Please select unique users for points', Toaster)
        return
      }
      const postData =  {
        extra_point_type_id: _data?.extra_point_type_id?.id,
        order_id: _data?.order_id || "",
        remarks: _data?.remarks || "",
        users_points: _data?.users_points.map((item: any) => ({
          user_id: item?.user_id.id,
          points: item?.points 
        }))
      };
      const resData: BaseResponse = await updateExtraPoints(id, postData)
      if (resData.error === false) {
        toast.success(resData?.message, Toaster)
        navigate("../list")
      } else {
        toast.error(resData?.message, Toaster)
        setAlertMessage({
          severity: 'error',
          message: resData.message,
          data: resData.data,
        })
      }
    } catch (err: any) {
      console.error(err)
      const errData: BaseResponse = err
      toast.error(errData?.message, Toaster)
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setAlertMessage({
        severity: 'error',
        message: errData.message,
        data: errData.data,
      })
    }
  }

  const setInitialFormValues = async (_extraPointData: any) => {
    try{
      setValue("extra_point_type_id", _extraPointData?.extra_point_type || "");
      setValue("order_id", _extraPointData?.order_id || "");
      setValue("remarks", _extraPointData?.remarks || "");
      setValue("users_points", _extraPointData?.users_points || []); 
      return true;
    }catch(err: any){
      return false;
    }
  };

const fetchExtraPointsData = async (_id: string | undefined) => {
    try {
    const resData: any = await fetchExtraPoint(_id);
    if (resData.error === false) {
        setExtraPointTypes(resData?.data?.extra_point_types)
        setDrodownUsers(resData?.data?.users)
        await setInitialFormValues(resData?.data?.extra_point);
    } else {
        toast.error(resData.message, Toaster);
    }
    } catch (err: any) {
    console.error(err);
    toast.error(err.message, Toaster);
    }
};

  useEffect(() => {
    fetchExtraPointsData(id);
  }, [id]);

  console.error(errors)
  return (
    <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Edit Extra Points
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={4}>
                <Controller
                  name="extra_point_type_id"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={extraPointTypes ?? []}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Extra Point Type"
                          variant="outlined"
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="order_id"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      id="order_id"
                      label="Order Id"
                      variant="outlined"
                      helperText={fieldState?.error?.message}
                      error={fieldState.invalid ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {userFields.map((item: any, index: number) => {
                  return (
                    <UserPointFields
                      key={index}
                      id={index}
                      control={control}
                      item={item}
                      removeUserFields={removeUserFields}
                      dropdownUsers={dropdownUsers}          
                    />
                  )
                })}
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  startIcon={<AddCircleIcon />}
                  variant="contained"
                  color="success"
                  onClick={() =>
                    appendUserFields({
                      user_id: { label: '', id: null },
                      points: 0,
                    })
                  }
                >
                  ADD USER'S POINTS
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name={`remarks`}
                  control={control}
                  defaultValue={''}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      fullWidth
                      id={`remarks`}
                      label="Remarks"
                      variant="outlined"
                      multiline
                      rows={4}
                      error={fieldState.invalid}
                      helperText={
                        fieldState?.error?.message ||
                        fieldState?.error?.label?.message
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={(e) => navigate(-1)}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default ExtraPointEdit
