import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { ChangeRequestListProps } from './ChangeRequestInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import RootStore from '../RootStore/RootStore'
import OrderStore from '../OrderStore/OrderStore'
import { CHANGE_STATUS } from '../../config/Constant'

export default class ChangeRequestStore {
  private rootstore: RootStore
  public orderStore: OrderStore
  agGridParams: any = null
  orderID: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: ChangeRequestListProps[]
  changeRequestStatusValue = null
  editingChangeRequestId = null 

  constructor() {
    this.rootstore = new RootStore()
    this.orderStore = new OrderStore()
    makeAutoObservable(this)
  }

  setChangeRequestStatusValue = (data: any) => {
    this.changeRequestStatusValue = data
  }

  setEditingChangeRequestId  = (data: any) => {
    this.editingChangeRequestId = data
  }

  setorderID = (data : any) =>  this.orderID = data

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent, orderID: any = this.orderID) => {
    this.setorderID(orderID)
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions, orderID)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions, orderID?: any) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request, orderID).then((data) => {
          params.success({
            rowData: data.order_change_requests.rows,
            rowCount: data.order_change_requests.lastRow,
          })
          //  TODO : set order id and status from here and in backlog also
          console.log(data.order_id, "yes order id");
          //set order id and status in store value
          this.orderStore.setCurrentOrderIDValue(data.order_id)
          this.orderStore.setCurrentOrderStatusValue(data.order_status)
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  // Setter Functions
  private setListData = (value?: ChangeRequestListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any, orderID: any): Promise<any> => {
    try{
      this.setListData([])
      const { data } = await axios.post(API_URL.CHANGE_REQUEST.LIST(orderID), payload)
      return Promise.resolve(data.data)
    } catch(err: any) {
      return Promise.reject(err.response.data)
    }
  }

  public getCreateFormFieldData = async (orderID: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const {data} = await axios.get(API_URL.CHANGE_REQUEST.CREATE_FORM_LIST_ITEMS(orderID))
      let dataObj = {
        data: data
      }
      loadingStore.setAppLoading(false)
      return Promise.resolve(dataObj)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public createChangeRequest = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.CHANGE_REQUEST.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public fetchChangeRequest = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.CHANGE_REQUEST.VIEW(_data))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateChangeRequest = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.CHANGE_REQUEST.UPDATE(_data?.order_change_request_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateChangeRequestStatus = async (_id: any,_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     _data.action_type = CHANGE_STATUS;
     const { data } = await axios.put(API_URL.CHANGE_REQUEST.CHANGE_STATUS(_id),_data)
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }
}
