import React, { useState } from 'react'
import useStore from '../../../../../../store'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '../../../../../../assets/images/note.png'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../../../components/AgGridWrapper/RecordPerPage'
import { observer } from 'mobx-react'
import { Stack } from '@mui/material'

const DefaultPointCutoffList = observer(() => {
  const { DEFAULT_POINT_CUTOFF } = useStore()
  const navigate = useNavigate()
  const { setupGrid, setPageSize, perPage } = DEFAULT_POINT_CUTOFF

  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              navigate('edit/' + params?.data?.enc_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const [columnDefs, ] = useState<any>([
    {
      headerName: 'Name',
      field: 'user_name',
      sortable : false
    },
    {
      headerName: 'Employee ID',
      field: 'user_employee_id',
      sortable : false,
    },
    {
      headerName: 'Role',
      field: 'user_role',
      sortable : false,
    },
    {
      headerName: 'Cutoff Points',
      field: 'cut_off_point',
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable : false,
      filter: false,
      cellRenderer: actionColumnRenderer,
    },
  ])

  return (
    <>
     {/* <h2>Default Earning Point Cutoff</h2> */}
     <Box sx={{ mt : "20px" }}>
     <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        spacing={2}
        sx={{ my: '20px' }}
      >
        <Box></Box>
        <Box sx={{ mb: '20px' }}>
        <Button
          onClick={() => navigate('./create')}
          variant="contained"
          startIcon={<AddCircleIcon />}
        >
          ADD CUTOFF
        </Button>
      </Box>
      </Stack>
    
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
      />
      <Box className="record-per-page-filter" sx={{ mt: '10px', ml: '-10px' }}>
        <RecordPerPage
          style={{ width: "150px" }}
          defaultValue={`${perPage}`}
          onChange={(e:any)=> setPageSize(e.target.value)}
        />
      </Box>
     </Box>
    </>
  )
})

export default DefaultPointCutoffList
