import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { DiscussionListProps } from './DiscussionInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import { CHANGE_STATUS } from '../../config/Constant'

export default class DiscussionStore {
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: DiscussionListProps[]
  editValues = null
  statusValues = { id : 0, discussion_status : "" }
  deleteValues = null

  constructor() {
    makeAutoObservable(this)
  }

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent) => {
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request).then(({data}) => {
          params.success({
            rowData: data.discussions.rows,
            rowCount: data.discussions.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  setDeleteValues = (data: any) => {
    this.deleteValues = data
  }

  setStatusValue = (data: any) => {
    this.statusValues = data
  }
  
  setEditValues = (data: any) => {
    this.editValues = data
  }

  // Setter Functions
  private setListData = (value?: DiscussionListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
    try{
    this.setListData([])
    const { data } = await axios.post(API_URL.DISCUSSION.LIST, payload)
    return Promise.resolve(data)
    } catch(err: any) {
        return Promise.reject(err.response.data)
    }
  }

  public createDiscussion = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.DISCUSSION.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public getDiscussionFormFieldData = async (): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const resData = await Promise.all([
        await axios.get(API_URL.GEO.GET_LIST_ITEMS),
      ])

      const dataObj: any = {}
      await Promise.all(
        resData.map((res: any) => {
          switch (res.config.url) {
            case API_URL.GEO.GET_LIST_ITEMS:
              dataObj.geos = res.data.data.geos
              return true
          }
        }),
      )
      loadingStore.setAppLoading(false)
      return Promise.resolve(dataObj)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public fetchDiscussion = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.DISCUSSION.VIEW(_data))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateDiscussion = async (_id: number, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.DISCUSSION.UPDATE(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public changeStatus = async (_data: any): Promise<any> => {
    try {
      _data.action_type = CHANGE_STATUS;
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(
        API_URL.DISCUSSION.CHANGE_STATUS(_data.id),
        _data,
      )
      //  this.setupGrid(this.agGridParams)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public deleteData = async (_id: number): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.delete(API_URL.DISCUSSION.DELETE(_id))
      this.setupGrid(this.agGridParams)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
}
