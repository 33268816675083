import React, { useState } from 'react'
import useStore from '../../../../store'
import { useNavigate } from 'react-router-dom'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '../../../../assets/images/note.png'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import { QUOTE_STATUSES } from '../../../../config/Constant'
import CancelledStatusRemarksPrompt from './CancelledStatusRemarksPrompt'
import StatusConfirmModal from './StatusConfirmModal'
import AllPermissionList from '../../../../config/AllPermissionList'
import { Stack } from '@mui/material'

const QuoteList = () => {
  const { QUOTE, AUTH } = useStore()
  const navigate = useNavigate()
  const {
    setupGrid,
    agGridParams,
    setStatusValue,
    setPageSize,
    perPage,
  } = QUOTE
  const { permissions } = AUTH
  const [cancelledStatusModal, setCancelledStatusModal] = useState<boolean>(false)
  const [statusConfirmModal, setStatusConfirmModal] = useState<boolean>(false)

  const changeStatusHandle = (_data: any) => {
    try {
      if(_data.quote_status === "Cancelled") {
        setStatusValue(_data)
        setCancelledStatusModal(true)
      }else{
        setStatusValue(_data)
        setStatusConfirmModal(true)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  const actionColumnRenderer = (params: any) => {
    return (
      <>
      {/* Can Edit only If quote status is "In Discussion" or "In Review" */}
      <Box sx={{ display: ((params?.data?.quote_status === QUOTE_STATUSES[0]?.name || params?.data?.quote_status === QUOTE_STATUSES[1]?.name) ? "block" : "none" ) }}>
        <Tooltip title="Edit" sx={{ display : (permissions && permissions.indexOf(AllPermissionList.EDIT_QUOTE) >= 0) ? "inline-flex" : "none" }}>
          <IconButton
            onClick={() => {
              navigate('../edit/' + params?.data?.enc_quote_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </Box>
      </>
    )
  }

  const statusColumnRenderer = (params: any) => {
    let STATUSES = QUOTE_STATUSES
    let currentStatusIndex = STATUSES.findIndex(status => status.name === params.value);
    
    if(params.value === "Confirmed"){
      // display only confirmed status
      STATUSES = STATUSES.filter(status => status.name === params.value);
    }else{
      // hide previous option of status with slice based on current status
      STATUSES = STATUSES.slice(currentStatusIndex);
    }

    return (
      <>
        {/* width of select box in status will be managed from here  */}
        <FormControl sx={{ m: "4px", width: 150 }} size="small">
          <Select
            id={`quote-status-${params.data?.enc_quote_id}`}
            defaultValue={params.value}
            onChange={(e) => changeStatusHandle({
              id: params.data?.enc_quote_id,
              quote_status: e.target.value,
            })}
          >
            {STATUSES.map((item: any) => (
                <MenuItem value={item.name} key={item.enc_quote_id}>
                  {item.name}
                </MenuItem>
              ))} 
          </Select>
        </FormControl>
      </>
    )
  }

  const [columnDefs, ] = useState<any>([
    {
      headerName: 'Quote ID',
      field: 'quote_id',
    },
    {
      headerName: 'Client Email',
      field: 'client_email',
    },
    {
      headerName: 'Billing Email',
      field: 'client_billing_email',
    },
    {
      headerName: 'Source',
      field: 'source',
    },
    {
      headerName: 'Invoice Pattern',
      field: 'invoice_pattern',
    },
    {
      headerName: 'Engagement Type',
      field: 'engagement_type',
    },
    {
      headerName: 'GEO',
      field: 'client_geo_name',
    },
    {
      headerName: 'Currency',
      field: 'currency',
    },
    { 
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter', 
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false, 
      },
      pinned: 'right'
    },
    {
      headerName: 'Quote Status',
      field: 'quote_status',
      cellRenderer: statusColumnRenderer,
      pinned: 'right'
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 70,
      minWidth: 80,
      pinned: 'right'
    },
  ])

  const closeStatusConfirmModal = () => {
    setStatusConfirmModal(false)
    setupGrid(agGridParams)
  }

  const closeCancelledStatusModal = () => {
    setCancelledStatusModal(false)
    setupGrid(agGridParams)
  }

  return (
    <>
      <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      spacing={2}
      sx={{ mb: '20px' }}
    > 
     <h1>Quotes</h1>
      <Box sx={{ mb: '20px' }}>
        <Button
          onClick={() => navigate('../create')}
          variant="contained"
          startIcon={<AddCircleIcon />}
          sx={{ display : (permissions && permissions.indexOf(AllPermissionList.ADD_QUOTE) >= 0) ? "inline-flex" : "none" }}
        >
          NEW QUOTE
        </Button>
      </Box>
      </Stack>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
        rowHeight={55}
      />
      <Box className="record-per-page-filter" sx={{ mt: '10px', ml: '-10px' }}>
        <RecordPerPage
          style={{ width: '150px' }}
          defaultValue={`${perPage}`}
          onChange={(e: any) => setPageSize(e.target.value)}
        />
      </Box>
      <CancelledStatusRemarksPrompt visible={cancelledStatusModal} close={closeCancelledStatusModal} />
      <StatusConfirmModal visible={statusConfirmModal} close={closeStatusConfirmModal} />
    </>
  )
}

export default QuoteList
