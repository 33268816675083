const fieldName = "The Field";

export interface MessageProps {
	required: (field_name?: string) => string;
	max: (max_limit: number, field_name?: any) => string;
	min: (min_limit: number, field_name?: any) => string;
	type: {
		email: (field_name?: string) => string;
		pattern: (field_name?: string) => string;
	};
	pattern: {
		password: (field_name?: string) => string;
	};
	global: {
		networkIssue: string;
	};
}

const Message: MessageProps = {
	required: (field_name = fieldName) => `${field_name} is required.`,
	max: (max_limit, field_name = fieldName) =>
		`Please enter ${field_name} with maximum ${max_limit} characters.`,
	min: (min_limit, field_name = fieldName) =>
		`Please enter ${field_name} with at least ${min_limit} characters.`,
	type: {
		email: (field_name = fieldName) => `Please enter valid ${field_name}.`,
		pattern: (field_name = fieldName) => `Please enter valid ${field_name}.`,
	},
	pattern: {
		password: (field_name = fieldName) =>
			`${field_name} should contain at least an uppercase letter, a lowercase letter, a number and a special character.`,
	},
	global: {
		networkIssue: "Network Error!",
	},
};

export default Message;