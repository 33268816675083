import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Box, Button, Tab, Tabs} from '@mui/material'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import OrderIcon from '../../../../assets/images/order.png'
import AnalyticsIcon from '../../../../assets/images/analytics.png'
import ClientOrdersList from './Tabs/ClientOrdersList'
import Summary from './Tabs/Summary'
import { Stack } from '@mui/material'

const ClientInfo = observer(() => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [clientName, setClientName] = useState<any>(null)
  const { CLIENT_ORDER } = useStore()
  // TODO : should not get clear on refresh (call API to get the details) 
  const { currentClientValue } = CLIENT_ORDER
  const location = useLocation()

  const tabRoutes = [
    {
      label: 'Orders',
      value: `/clients/orders/${id}`,
      icon: <img src={OrderIcon} alt='OrderIcon' className='list-icon'/>,
    },
    {
      label: 'Summary',
      value: `/clients/summary/${id}`,
      icon: <img src={AnalyticsIcon} alt='AnalyticsIcon' className='list-icon'/>,
    },
    // Add more tabs if needed
  ]

  const activeTabInfo = tabRoutes.find((tab) =>
    location.pathname.startsWith(tab.value),
  )

  useEffect(()=>{
    setClientName(currentClientValue?.client_name)
  },[currentClientValue])

  return (
    <>
     <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      spacing={2}
      sx={{ mb: '20px' }}
    > 
      <h2>Client Info { clientName && <> : ({clientName})</>} </h2>
      <Box sx={{ mb: '20px' }}>
        <Button
          onClick={() => navigate('../list')}
          variant="contained"
          startIcon={<KeyboardBackspaceRoundedIcon/>}
        >
          BACK TO LIST
        </Button>
      </Box>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={activeTabInfo?.value}
          aria-label="icon label tabs example"
          sx={{
            bgcolor: '#5c5c5c',
          }}
        >
          {tabRoutes.map((tab) => (
            <Tab
              key={tab.value}
              icon={tab.icon}
              label={tab.label}
              value={tab.value}
              component={Link}
              to={tab.value}
              sx={{
                color: '#ffff',
              }}
            />
          ))}
        </Tabs>
      </Box>
      {activeTabInfo && activeTabInfo?.label === 'Orders' && (
        <ClientOrdersList />
      )}
      {activeTabInfo && activeTabInfo?.label === 'Summary' && <Summary />}
      {!activeTabInfo && <></>}{' '}
      {/* Render a message if neither condition is met */}
    </>
  )
})

export default ClientInfo
