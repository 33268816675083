import axios from 'axios'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { EscalationCategoryListProps } from './EscalationCategoryInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'

export default class EscalationCategoryStore {
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: EscalationCategoryListProps[]
  editValues = null
  deleteValues = null

  constructor() {
    makeAutoObservable(this)
  }

  // API Functions
  public fetchCategoryList = async (payload: any): Promise<any> => {
    try{
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.ESCALATION_CATEGORY.LIST, payload)
      loadingStore.setAppLoading(false)
    return Promise.resolve(data.data.escalation_categories)
    } catch(err: any) {
      loadingStore.setAppLoading(false)
        return Promise.reject(err.response.data)
    }
  }

  public createEscalationCategory = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.ESCALATION_CATEGORY.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }


  // API Functions
  public fetchSubCategoryList = async (payload: any): Promise<any> => {
    try{
    const { data } = await axios.get(API_URL.ESCALATION_SUBCATEGORY.LIST, payload)
    return Promise.resolve(data.data.escalation_sub_categories)
    } catch(err: any) {
        return Promise.reject(err.response.data)
    }
  }

  public createEscalationSubCategory = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.ESCALATION_SUBCATEGORY.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }



}
