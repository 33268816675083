import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { BacklogListProps } from './BacklogInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import RootStore from '../RootStore/RootStore'
import { CHANGE_STATUS } from '../../config/Constant'

export default class BacklogStore {
  private rootstore: RootStore
  agGridParams: any = null
  orderID: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: BacklogListProps[]
  backlogStatusValue = null
  editingBacklogId = null 

  constructor() {
    this.rootstore = new RootStore()
    makeAutoObservable(this)
  }

  setBacklogStatusValue = (data: any) => {
    this.backlogStatusValue = data
  }

  setEditingBacklogId = (data: any) => {
    this.editingBacklogId = data
  }

  setorderID = (data : any) =>  this.orderID = data

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent, orderID: any = this.orderID) => {
    this.setorderID(orderID)
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions, orderID)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions, orderID?: any) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request, orderID).then((data) => {
          params.success({
            rowData: data.order_backlogs.rows,
            rowCount: data.order_backlogs.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  // Setter Functions
  private setListData = (value?: BacklogListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any, orderID: any): Promise<any> => {
    try{
      this.setListData([])
      const { data } = await axios.post(API_URL.BACKLOG.LIST(orderID), payload)
      return Promise.resolve(data.data)
    } catch(err: any) {
      return Promise.reject(err.response.data)
    }
  }

  public getCreateFormFieldData = async (orderID: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const {data} = await axios.get(API_URL.BACKLOG.CREATE_FORM_LIST_ITEMS(orderID))
      let dataObj = {
        data: data
      }
      loadingStore.setAppLoading(false)
      return Promise.resolve(dataObj)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public createBacklog = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.BACKLOG.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public fetchBacklog = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.BACKLOG.VIEW(_data))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateBacklog = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.BACKLOG.UPDATE(_data?.order_backlog_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateBacklogStatus = async (_id: any,_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     _data.action_type = CHANGE_STATUS;
     const { data } = await axios.put(API_URL.BACKLOG.CHANGE_STATUS(_id),_data)
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }
}
