import React from 'react'
import { TextField, IconButton, Tooltip, MenuItem } from '@mui/material'
import Grid from '@mui/material/Grid'
import { observer } from 'mobx-react'
import { Controller } from 'react-hook-form'
import * as uuid from 'uuid'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import useStore from '../../../../../../store'

const DynamicRolePointsRow = observer(
  ({ id, register, errors, control, removeJobsFields, item }: any) => {
    const uuidValue = () => uuid.v4()
    const { DEFAULT_POINTS } = useStore()
    const { rolesArray } = DEFAULT_POINTS
    return (
      <div>
        <Grid
          key={uuidValue()}
          container
          sx={{ py: 1 }}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4}>
            <Controller
              name={`user_roles.${id}.user_role_id`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id={`user_roles.${id}.user_role_id`}
                  select
                  label="Role Type"
                  fullWidth
                  helperText={fieldState?.error?.message}
                  error={fieldState.invalid ? true : false}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem key={uuidValue()} disabled value={''}>
                    Select Role Type
                  </MenuItem>
                  {rolesArray &&
                    rolesArray.map((type: any) => (
                      <MenuItem key={uuidValue()} value={type.id}>
                        {type.label}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name={`user_roles.${id}.point`}
              control={control}
              render={({ field, fieldState }) => (
                <div className="d-flex flex-row">
                  <TextField
                    fullWidth
                    id={`user_roles.${id}.point`}
                    type="text"
                    label="Point"
                    variant="outlined"
                    helperText={fieldState?.error?.message}
                    error={fieldState.invalid ? true : false}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          {item.point_value_type === 1 ? (
            <Grid item xs={1}>
              {' '}
              <span>%</span>
            </Grid>
          ) : null}
          <Grid item xs={1}>
            <Tooltip
              title="Remove"
              sx={{ visibility: id === 0 ? 'hidden' : '' }}
            >
              <IconButton onClick={() => removeJobsFields(id)}>
                <DeleteForeverRoundedIcon color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    )
  },
)

export default DynamicRolePointsRow
