import { AgGridReactProps, AgReactUiProps } from "ag-grid-react";

interface ConfigProps {
  [key: string]: any;
  grid: {
    local: AgGridReactProps | AgReactUiProps;
    server: AgGridReactProps | AgReactUiProps;
  };
}

export const onFilterChanged = (params: any) => {
  const agGridParams = params;
  if (agGridParams && agGridParams.api.getModel().getRowCount() === 0) {
    agGridParams.api.showNoRowsOverlay();
  }
  if (agGridParams && agGridParams.api.getModel().getRowCount() > 0) {
    agGridParams.api.hideOverlay();
  }
};

const Config: ConfigProps = {
  grid: {
    server: {
      rowModelType: "serverSide",
      // serverSideStoreType: "partial",
      gridOptions: {
        animateRows: true,
        pagination: true,
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        floatingFiltersHeight: 40,
        // enableRangeSelection: false,
        cacheBlockSize: 50,
        blockLoadDebounceMillis: 500,
        // rowSelection: "multiple",
        domLayout: "autoHeight",
        overlayNoRowsTemplate: '<span class="ag-overlay-no-rows">No Data Found</span>',
      },
      defaultColDef: {
        flex: 1,
        minWidth: 180,
        resizable: true,
        sortable: true,
        cellClass: "text-center",
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ['reset'],
          maxNumConditions : 1 // maximum number of conditions in filter ('OR' & 'AND')
        },
        floatingFilterComponentParams: {
              suppressFilterButton: true, // to enable filter button besides search make it false
        },
        floatingFilter: true,
        suppressMenu: true, // to enable enterprise features make it false
      },
      columnTypes: {
        actionColumn: {
          cellRenderer: "ActionRenderer",
        },
      },
    },
    local: {
      gridOptions: {
        animateRows: true,
        pagination: true,
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        floatingFiltersHeight: 40,
        enableRangeSelection: true,
        cacheBlockSize: 50,
        blockLoadDebounceMillis: 500,
        rowSelection: "multiple",
        domLayout: "autoHeight",
        overlayNoRowsTemplate: "No Records Found.",
      },
      defaultColDef: {
        flex: 1,
        minWidth: 180,
        resizable: true,
        sortable: true,
        cellClass: "text-center",
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ['reset']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: false, // to enable filter button besides search make it false
        },
        floatingFilter: true,
        suppressMenu: false, // to enable enterprise features make it false
      },
      columnTypes: {
        actionColumn: {
          cellRenderer: "ActionRenderer",
        },
      },
      onFilterChanged: onFilterChanged,
      onSortChanged: onFilterChanged,
    },
  },
};

export default Config;
