import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Box, Grid } from '@mui/material'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const EarningPointLogList = observer(() => {
  const { EARNING_POINT_LOG } = useStore()
  const { setupGrid, agGridParams, setPageSize, perPage, usersList } = EARNING_POINT_LOG
  const initialFilterData = {
    user_id: 0,
    view_point_type: "All",
  };
  const [filterData, setFilterData] = useState<any>(initialFilterData);

  const pointTypes = [
    { id: 0, label: "All" },
    { id: 1, label: "Default Points" },
    { id: 2, label: "Extra Points" },
  ]

  const handleUserChange  = (event: any, newValue: any) => {
    setFilterData({
      ...filterData,
      user_id: newValue?.id || '',
    });
  };
  
  const handlePointTypeChange  = (event: any, newValue: any) => {
    setFilterData({
      ...filterData,
      view_point_type: newValue?.label || 'All',
    });
  };

  useEffect(() => {
    if (filterData?.user_id !== 0 && filterData?.view_point_type !== null) {
      setupGrid(agGridParams, filterData);
    }
  }, [filterData]);

  const [columnDefs, ] = useState<any>([
    {
      headerName: 'User',
      field: 'user_name',
      sortable: false,
    },
    {
      headerName: 'Points',
      field: 'points',
    },
    {
      headerName: 'Default Point Type',
      field: 'default_point_type_name',
      sortable: false,
    },
    {
      headerName: 'Extra Point Type',
      field: 'extra_point_type_name',
      sortable: false,
    },
    {
      headerName: 'Order ID',
      field: 'order_id',
      sortable: false,
    },
    {
      headerName: 'Quote ID',
      field: 'quote_id',
      sortable: false,
    },
    {
      headerName: 'Template ID',
      field: 'order_template_id',
      sortable: false,
    },
    {
      headerName: 'Change Request ID',
      field: 'order_change_request_id',
      sortable: false,
    },
    {
      headerName: 'Backlog ID',
      field: 'order_backlog_id',
      sortable: false,
    },
    {
      headerName: 'Discussion ID',
      field: 'discussion_id',
      sortable: false,
    },
    {
      headerName: 'Escalation ID',
      field: 'escalation_id',
      sortable: false,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter',
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false,
      },
      pinned: 'right'
    },
  ])

  return (
    <>
      <h1>My Earning Points</h1>
      <Box sx={{ mb: '20px' }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              margin: '0px 0px',
              bgcolor: '#b3b1b1',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Filters</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ mt : 2 }}>
            <Grid
              sx={{ px: 2 }}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 3, sm: 6, md: 12 }}
            >
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={usersList}
                  onChange={handleUserChange}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.label === value.label
                  }
                  getOptionLabel={(option: any) => option.label || ''}
                  renderInput={(params) => (
                    <TextField {...params} label="User" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pointTypes}
                  onChange={handlePointTypeChange}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.label === value.label
                  }
                  getOptionLabel={(option: any) => option.label || ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Point Type" />
                  )}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
        rowHeight={55}
      />
      <Box className="record-per-page-filter"  sx={{ mt : '10px' , ml: '-10px' }}>
        <RecordPerPage
          style={{ width: '150px' }}
          defaultValue={`${perPage}`}
          onChange={(e: any) => setPageSize(e.target.value)}
        />
      </Box>
    </>
  )
})

export default EarningPointLogList
