import { makeAutoObservable } from "mobx";
import axios from "axios";
import {  loginType, forgotPassewordType, resetPasswordType } from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";
import loadingStore from "../LoadingStore/LoadingStore";

export default class AuthStore {
  // Variables
  public user?: any;
  public token?: string;
  public email?: string;
  private rootStore: RootStore;
  public permissions?: any[];

  constructor() {
    this.rootStore = new RootStore();
    makeAutoObservable(this);
  }

  // Initiate Application Functions
  public InitializeApp = () => {
    this.rootStore.setAxiosBaseUrl();
    this.rootStore.setAxiosInterceptor();
    this.setupHeaders();
  };

  public setupHeaders = async (access_token?: string): Promise<void> => {
    try{
      const token =  await this.rootStore.setAxiosHeaders(access_token)
      this.setToken(token);
      await this.fetchAuthUser();
      } catch(err){
        this.setToken();
        this.setUser();
      }
  };

  // Setter Functions
  public setUser = (value?: any): void => {
    this.user = value;
  };
  public setEmail = (value: string): void => {
    this.email = value
  }
  public setToken = (value?: string): void => {
    this.token = value;
  };
	public setPermissions = (value:any[]): void => {
		this.permissions = value;
	};

  // API Functions
  public doLogin = async (payload: loginType): Promise<any> => {
      try{
        loadingStore.setAppLoading(true);
        const { data }= await axios.post(API_URL.LOGIN, payload)
        loadingStore.setAppLoading(false);
        const acess_token = data.data.auth.access_token;
        this.setToken(acess_token);
        this.setupHeaders(acess_token);
        return Promise.resolve(data);
      }catch(err:any){
        loadingStore.setAppLoading(false);
        this.setToken();
        this.setUser();
        return Promise.reject(err.response.data);
      }
  };

	public fetchAuthUser = async (): Promise<any> => {
    try{
      loadingStore.setAppLoading(true);
      const { data }= await axios.get(API_URL.ME)
      loadingStore.setAppLoading(false);
      this.setUser(data?.data?.user);
      const permissions = data.data?.user?.role?.permissions?.map((item:any) => {return item.name});
      this.setPermissions(permissions);
      return Promise.resolve(data);
    }catch(err:any){
      loadingStore.setAppLoading(false);
      this.rootStore.resetStore();
      this.setToken();
      this.setUser();
			this.setPermissions([]);
      return Promise.reject(err.response.data);
    }
	};

  public doLogout = async (): Promise<any> => {
    try{
        loadingStore.setAppLoading(true);
        const { data } = await axios.post(API_URL.LOGOUT);
        loadingStore.setAppLoading(false);
        this.rootStore.resetStore();
        this.setToken();
        this.setUser();
				this.setPermissions([]);
        return Promise.resolve(data);
      }
      catch(err:any)  {
        loadingStore.setAppLoading(false);
        return Promise.reject(err.response.data);
      }
  };

  public forgetPassword = async (payload: forgotPassewordType): Promise<any> => {
    try{
      loadingStore.setAppLoading(true);
      const { data }= await axios.post(API_URL.FORGET_PASSWORD, payload)
      this.setEmail(payload.email);
      loadingStore.setAppLoading(false);
      return Promise.resolve(data);
    }catch(err:any){
      this.setEmail("");
      loadingStore.setAppLoading(false);
      return Promise.reject(err.response.data);
    }
  };

  public resetPasswordWithToken = async (payload: resetPasswordType): Promise<any> => {
    try{
      loadingStore.setAppLoading(true);
      const { data }= await axios.post(API_URL.RESET_PASSWORD, payload)
      loadingStore.setAppLoading(false);
      return Promise.resolve(data);
    }catch(err:any){
      this.setEmail("");
      loadingStore.setAppLoading(false);
      return Promise.reject(err.response.data);
    }
	};

  public getMyProfile = async (): Promise<any> => {
    try{
      loadingStore.setAppLoading(true);
      const { data }= await axios.get(API_URL.MY_PROFILE)
      loadingStore.setAppLoading(false);
      return Promise.resolve(data);
    }catch(err:any){
      loadingStore.setAppLoading(false);
      this.rootStore.resetStore();
      this.setToken();
      this.setUser();
			this.setPermissions([]);
      return Promise.reject(err.response.data);
    }
	};
  public updateProfilePassword = async (payload: any): Promise<any> => {
    try{
      loadingStore.setAppLoading(true);
      const { data }= await axios.post(API_URL.UPDATE_PROFILE_PASSWORD, payload)
      loadingStore.setAppLoading(false);
      return Promise.resolve(data);
    }catch(err:any){
      loadingStore.setAppLoading(false);
      this.rootStore.resetStore();
      this.setToken();
      this.setUser();
			this.setPermissions([]);
      return Promise.reject(err.response.data);
    }
	};
}
