import React from "react";
import { observer } from "mobx-react";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { PER_PAGE_RECORD } from "../../config/Constant";

const RecordPerPage: React.FC<any> = observer((props) => {
  return (
    <>
    <FormControl sx={{ m: 1, minWidth: 120 }} >
    <InputLabel id="per-page-label">Show Entries</InputLabel>
      <Select
       labelId="per-page-label"
       id="per-page"
       label="Show Entries"
        {...props}
      >
        {PER_PAGE_RECORD.map((item: any) => {
        return (
          <MenuItem value={item.id} key={item.id}>
            {item.value}
          </MenuItem>
        );
      })}
      </Select>
    </FormControl>
    </>
  );
});

export default RecordPerPage;
