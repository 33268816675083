import axios from 'axios'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import loadingStore from '../LoadingStore/LoadingStore'

export default class CurrencyStore {

  constructor() {
    makeAutoObservable(this)
  }

  // API Functions
  public fetchCurrencyConverionList = async (): Promise<any> => {
    try{
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.CURRENCY.CURRENCY_CONVERSION_LIST)
      loadingStore.setAppLoading(false)
        return Promise.resolve(data)
    } catch(err: any) {
      loadingStore.setAppLoading(false)
        return Promise.reject(err.response.data)
    }
  }

  public updateCurrencyConverion = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.CURRENCY.UPDATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

}
