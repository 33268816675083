import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useStore from "../../../store"
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import AlertComponent, { AlertData } from '../../../components/utilities/AlertComponent'
import { BaseResponse } from '../../../interfaces/ApiResponse'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
})

const ForgetPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  })

  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  
  const { AUTH } = useStore()
  const { forgetPassword } = AUTH

  const onSubmit = async(_data:any) => {
    // handle forgetPassword API
    try {
      setAlertMessage(null);	
      const resData: BaseResponse = await forgetPassword(_data);
      if(resData.error === false){
        setAlertMessage({
          severity:'success',
          message: resData.message,
          data: resData.data
        });
      }else{
        setAlertMessage({
          severity:'error',
          message: resData.message,
          data: resData.data
        });
      }
    } catch(err:any){
      console.error(err);
      const errData:BaseResponse = err;
      setAlertMessage({
        severity:'error',
        message: errData.message,
        data: errData.data
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockResetOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            {...register('email')}
            autoComplete="email"
            helperText={errors.email?.message}
            error={errors.email ? true : false}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>

          {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}

          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} variant='body2' to="/login">
              Back to Login</Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default ForgetPassword
