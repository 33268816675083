import React , { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import "./assets/styles/ag-grid-override.css"
import App from './App'
import PageSpinner from './components/PageSpinner/PageSpinner'
import { theme } from './theme'
import { ThemeProvider } from '@mui/material/styles'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Suspense fallback={<PageSpinner />}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Suspense>,
)
