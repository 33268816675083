// material icons 
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import SettingsIcon from '@mui/icons-material/Settings';

export const userProfileMenus = [
    {
      name : "Profile",
      url  : "/dashboard/profile",
      icon : <AccountCircleSharpIcon/>,
      cb : null
    },
    {
      name : "Change Password",
      url  : "/dashboard/change-password",
      icon : <SettingsIcon/>,
      cb : null
    }
]