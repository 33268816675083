import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import useStore from '../../../../../store'
import Box from '@mui/material/Box'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../../components/AgGridWrapper/RecordPerPage'

const ClientOrdersList = observer(() => {
  const { CLIENT_ORDER } = useStore()
  const { id } = useParams()
  const { setupGrid, setPageSize, perPage } = CLIENT_ORDER

  // const actionColumnRenderer = (params: any) => {
  //   return (
  //     <>
  //     {params.data.order_status && params.data.order_status === 'Under Review' && <>
  //         <Tooltip title="Assign Order">
  //           <IconButton
  //             onClick={() => {
  //               navigate('../assign-order/' + params.data.order_id)
  //             }}
  //           >
  //             <CallToActionRoundedIcon />
  //           </IconButton>
  //         </Tooltip>
  //     </>}
  //     {params.data.order_status && (params.data.order_status === 'Assigned' || params.data.order_status === 'In Progress') && <>
  //       <Tooltip title="Order Info">
  //           <IconButton
  //             onClick={() => {
  //               navigate('../templates/' + params.data.order_id)
  //             }}
  //           >
  //             <InfoIcon />
  //           </IconButton>
  //         </Tooltip>
  //       </>}
  //       {params.data.order_status && params.data.order_status === 'Cancelled' && <>
  //        Cancelled
  //       </>}
  //     </>
  //   )
  // }

  const [columnDefs, ] = useState<any>([
    // {
    //   headerName: 'Action',
    //   field: 'action',
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: actionColumnRenderer,
    // },
    {
      headerName: 'Order ID',
      field: 'order_id',
    },
    {
      headerName: 'TL',
      field: 'assign_tl_name',
      sortable: false,
    },
    {
      headerName: 'AM',
      field: 'assign_am_name',
      sortable: false,
    },
    {
      headerName: 'Currency',
      field: 'currency',
    },
    {
      headerName: 'Engagement Type',
      field: 'engagement_type',
    },
    {
      headerName: 'Source',
      field: 'source',
    },
    {
      headerName: 'Invoice Pattern',
      field: 'invoice_pattern',
    },
    { 
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter', 
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false, 
      }
    },
    {
      headerName: 'Status',
      field: 'order_status',
    },
  ])

  return (
    <>
     <Box sx={{ mt : '20px' }}>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={(params) => setupGrid(params, id)} 
      />
      <Box className="record-per-page-filter" sx={{ mt: '10px' , ml: '-10px' }}>
        <RecordPerPage
          style={{ width: '150px' }}
          defaultValue={`${perPage}`}
          onChange={(e: any) => setPageSize(e.target.value)}
        />
      </Box>
      </Box>
    </>
  )
})

export default ClientOrdersList
