import { Switch, Tooltip, IconButton, Box, Button, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper'
import Toaster from '../../../../../../config/Toaster'
import useStore from '../../../../../../store'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditIcon from '../../../../../../assets/images/note.png'
import { observer } from 'mobx-react'

const LayoutList = observer(() => {
  const { LAYOUT } = useStore()
  const navigate = useNavigate()
  const { fetchLayoutList, changeStatus } = LAYOUT
  const [rowData, setRowData] = useState<any>([])

  const changeStatusHandle = async (_data: any) => {
    try {
      const resData: any = await changeStatus(_data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              navigate('edit/' + params?.data?.enc_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const statusColumnRenderer = (params: any) => {
    const label = { inputProps: { 'aria-label': `Status Switch` } }
    return (
      <>
        <Switch
          {...label}
          defaultChecked={params.value === 1}
          onChange={() =>
            changeStatusHandle({
              id: params.data?.enc_id,
              status: params.value === 1 ? 0 : 1,
            })
          }
        />
      </>
    )
  }

  const [columnDefs] = useState<any>([
    {
      headerName: 'Layout Name',
      field: 'layout_name',
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: false,
      filter: false,
      cellRenderer: statusColumnRenderer,
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: false,
      filter: false,
      cellRenderer: actionColumnRenderer,
    },
  ])

  useEffect(() => {
    fetchLayoutList()
      .then((data: any) => {
        setRowData(data)
      })
      .catch((error: any) => {
        setRowData([])
      })
  }, [fetchLayoutList])

  return (
    <>
      <Box sx={{ mt: '20px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          spacing={2}
          sx={{ my: '20px' }}
        >
          <Box></Box>
          <Box sx={{ mb: '20px' }}>
            <Button
              onClick={() => navigate('./create')}
              variant="contained"
              startIcon={<AddCircleIcon />}
            >
              ADD NEW LAYOUT
            </Button>
          </Box>
        </Stack>
        <AgGridWrapper
          type="clientSide"
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </Box>
    </>
  )
})

export default LayoutList
