import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import AccessTimeIcon from '../../../../assets/images/hierarchy-structure.png';
import ApartmentIcon from '../../../../assets/images/clock.png';

  
const tabRoutes = [
  { label: 'Departments', value: '/masters/users/departments', icon: <img src={ApartmentIcon} alt='ApartmentIcon' className='list-icon'/>},
  { label: 'Shifts', value: '/masters/users/shifts', icon: <img src={AccessTimeIcon} alt='AccessTimeIcon' className='list-icon'/>},
  // Add more tabs if needed
];

const Masters = () => {
    const location = useLocation();
    const activeTabValue = tabRoutes.find(tab => location.pathname.startsWith(tab.value))?.value;

    return (
          <>
            <h1>Masters</h1>
            <Box sx={{ width: '100%' }}>
            <Tabs value={activeTabValue} aria-label="icon label tabs example"  sx={{
            bgcolor: '#5c5c5c',
          }}>
              {tabRoutes.map(tab => (
                <Tab
                  key={tab.value}
                  icon={tab.icon}
                  label={tab.label}
                  value={tab.value}
                  component={Link}
                  to={tab.value}
                  sx={{
                    color: '#ffff',
                  }}
                />
              ))}
            </Tabs>
            </Box>
            <Outlet />
          </>
    )
}

export default Masters