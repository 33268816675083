import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import useStore from '../../../../../store'
import { Box, Card, CardContent, Typography, Divider, Grid } from '@mui/material'
import Toaster from '../../../../../config/Toaster'
import { toast } from 'react-toastify'
import { BaseResponse } from '../../../../../interfaces/ApiResponse'

const Summary = observer(() => {
  const { CLIENT, CLIENT_ORDER } = useStore()
  const { id } = useParams()
  const { fetchClientSummary } = CLIENT
  const [ clientSummaryData, setClientSummaryData ] = useState<any>(null)
  const { setCurrentClientValue } = CLIENT_ORDER

  const fetchClientSummaryData = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchClientSummary(id)
      if (resData.error === false) {
        setClientSummaryData(resData?.data)
        setCurrentClientValue({ client_name : resData?.data?.client_name })
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      toast.error(errData.message, Toaster)
    }
  }

  useEffect(() => {
    fetchClientSummaryData(id)
  }, [id])

  return (
    <>
     <Box sx={{ mt : '20px' }}>
     <Card elevation={3} >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Client Summary
        </Typography>
        <Divider />
        <Grid container spacing={2} alignItems="center" sx={{ mt : "20px" }}>
          <Grid item xs={4}>
            <Typography variant="body1">Total Orders:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              {clientSummaryData?.total_orders}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Earnings:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              {clientSummaryData?.total_earnings}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Total Templates:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              {clientSummaryData?.total_templates}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
      </Box>
    </>
  )
})

export default Summary
