import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import { EarningPointsListProps } from './EarningPointsInterface'

export default class EarningPointsStore {
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: EarningPointsListProps[]
  editValues = null
  deleteValues = null

  public rolesArray:any[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  // API Functions
  public fetchDefaultPointTypesList = async (): Promise<any> => {
    try{
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.DEFAULT_POINT.LIST)
      loadingStore.setAppLoading(false)
        return Promise.resolve(data.data.default_point_types)
    } catch(err: any) {
      loadingStore.setAppLoading(false)
        return Promise.reject(err.response.data)
    }
  }

  public viewDefaultEarningPoints = async (_id: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.DEFAULT_POINT.VIEW(_id))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateDefaultEarningPoints = async (_id: any, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.DEFAULT_POINT.UPDATE(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }


  // Roles array
  public fetchRolesArray = async (): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.ROLE.GET_LIST_ITEMS)
      loadingStore.setAppLoading(false)
      this.rolesArray = data.data.roles
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }

  }



}
