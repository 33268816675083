import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { OrderListProps } from './OrderInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import RootStore from '../RootStore/RootStore'
import { CHANGE_STATUS } from '../../config/Constant'

export default class OrderStore {
  private rootstore: RootStore
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: OrderListProps[]
  statusValues = { order_status : "" }
  templatesStatusValue = null
  public currentOrderStatusValue = null
  public currentOrderIDValue = null
  remarks = ""

  constructor() {
    this.rootstore = new RootStore()
    makeAutoObservable(this)
  }

  setStatusValue = (data: any) => {
    this.statusValues = data
  }

  setRemarks = (data: any) => {
    this.remarks = data
  }

  setTemplatesStatusValue = (data: any) => {
    this.templatesStatusValue = data
  }
  
  public setCurrentOrderStatusValue = (data: any) => {
    this.currentOrderStatusValue = data
  }

  public setCurrentOrderIDValue = (data: any) => {
    this.currentOrderIDValue = data
  }

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent) => {
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request).then((data) => {
          params.success({
            rowData: data.orders.rows,
            rowCount: data.orders.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  // Setter Functions
  private setListData = (value?: OrderListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
    try{
      this.setListData([])
      const { data } = await axios.post(API_URL.ORDER.LIST, payload)
      return Promise.resolve(data.data)
    } catch(err: any) {
      return Promise.reject(err.response.data)
    }
  }

  public fetchOrder = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.ORDER.VIEW(_data))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public fetchOrderTemplateStatus = async (_id: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.ORDER.ORDER_TEMPLATE_STATUS)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public updateOrder = async (_id: any, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.ORDER.UPDATE_ORDER(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public assignOrder = async (_id: number, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.ORDER.ASSIGN_ORDER(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public updateAssignOrder = async (_id: number, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.ORDER.UPDATE_ASSIGN_ORDER(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public changeStatus = async (_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     _data.action_type = CHANGE_STATUS;
     const { data } = await axios.put(API_URL.ORDER.CHANGE_STATUS(_data.id),_data)
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }
  public updateOrderTemplatesStatus = async (_id: any,_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     const { data } = await axios.put(API_URL.ORDER.TEMPLATE_CHANGE_STATUS(_id),_data)
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }
}
