import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Box, Tab, Tabs, Stepper, Step, StepLabel, Button } from '@mui/material'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import TemplatesIcon from '../../../../assets/images/templates.png'
import ChangeRequestIcon from '../../../../assets/images/change-requests.png'
import BacklogIcon from '../../../../assets/images/backlogs.png'
import OrderDetailIcon from '../../../../assets/images/order-details.png'
import TeamIcon from '../../../../assets/images/team.png'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import AllPermissionList from "../../../../config/AllPermissionList";
import OrderTemplatesSelected from './Tabs/OrderTemplates/OrderTemplatesSelected'
import TeamSelected from './Tabs/TeamSelected/TeamSelected'
import ChangeRequestList from './Tabs/ChangeRequests/ChangeRequestList'
import OrderDetail from './Tabs/OrderDetail/OrderDetail'
import BacklogList from './Tabs/Backlogs/BacklogList'
import { ORDER_STATUSES } from '../../../../config/Constant'
import { Stack } from '@mui/material'

const OrderInfo = observer(() => {
  const { ORDER, AUTH } = useStore()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { currentOrderStatusValue, currentOrderIDValue } = ORDER
  const { permissions } = AUTH
  const [activeStep, setActiveStep] = useState<any>(0)

  // TODO : set permissions for all tabs
  const tabRoutes = [
    {
      label: 'Templates',
      value: `/orders/templates/${id}`,
      icon: <img src={TemplatesIcon} alt='TemplatesIcon' className='list-icon'/>,
      hasPermission: true,
    },
    {
      label: 'Change Requests',
      value: `/orders/change-requests/${id}`,
      icon: <img src={ChangeRequestIcon} alt='ChangeRequestIcon' className='list-icon'/>,
      hasPermission: ((permissions && permissions.indexOf(AllPermissionList.LIST_ORDER_CHANGE_REQUESTS) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_ORDER_CHANGE_REQUEST) >= 0)),
    },
    {
      label: 'Backlogs',
      value: `/orders/backlogs/${id}`,
      icon: <img src={BacklogIcon} alt='BacklogIcon' className='list-icon'/>,
      hasPermission: ((permissions && permissions.indexOf(AllPermissionList.LIST_ORDER_BACKLOGS) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_ORDER_BACKLOG) >= 0)),
    },
    {
      label: 'Details',
      value: `/orders/details/${id}`,
      icon: <img src={OrderDetailIcon} alt='OrderDetailIcon' className='list-icon'/>,
      hasPermission: true,
    },
    {
      label: 'Team',
      value: `/orders/team/${id}`,
      icon: <img src={TeamIcon} alt='TeamIcon' className='list-icon'/>,
      hasPermission: true,
    },
    // Add more tabs if needed
  ]

  const activeTabInfo = tabRoutes.find((tab) =>
    location.pathname.startsWith(tab.value),
  )

  useEffect(()=>{
    let orderStatus = ORDER_STATUSES.find((item: any) => item.name === currentOrderStatusValue);
    let orderStatusID = orderStatus ? orderStatus.id : 0;
    setActiveStep(orderStatusID)
  },[currentOrderStatusValue])

  return (
    <>
     <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      spacing={2}
      sx={{ mb : 2 }}
    > 
      <h2>Order ID { currentOrderIDValue && <> : ({currentOrderIDValue})</>} </h2>
      <Box sx={{}}>
        <Button
          onClick={() => navigate('../list')}
          variant="contained"
          startIcon={<KeyboardBackspaceRoundedIcon/>}
        >
          BACK TO LIST
        </Button>
      </Box>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ mb : 4 }}>
          {/* TODO : active status on current order status and set date too */}
          <Stepper activeStep={activeStep} alternativeLabel>
            {ORDER_STATUSES.map((label: any, index: any) => (
              <Step key={label?.name}>
                <StepLabel>{label?.name}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Tabs
          value={activeTabInfo?.value}
          aria-label="icon label tabs example"
          sx={{
            bgcolor: '#5c5c5c',
          }}
        >
          {tabRoutes.map((tab) => (
            <Tab
              key={tab.value}
              icon={tab.icon}
              label={tab.label}
              value={tab.value}
              component={Link}
              to={tab.value}
              sx={{
                color: '#ffff',
                display : tab.hasPermission ? "inline-flex" : "none"
              }}
            />
          ))}
        </Tabs>
      </Box>
      {activeTabInfo && activeTabInfo?.label === 'Templates' && (
        <OrderTemplatesSelected />
      )}
      {activeTabInfo && activeTabInfo?.label === 'Team' && <TeamSelected />}
      {activeTabInfo && activeTabInfo?.label === 'Change Requests' && (
        <ChangeRequestList />
      )}
      {activeTabInfo && activeTabInfo?.label === 'Details' && <OrderDetail />}
      {activeTabInfo && activeTabInfo?.label === 'Backlogs' && <BacklogList />}
      {!activeTabInfo && <></>}{' '}
      {/* Render a message if neither condition is met */}
    </>
  )
})

export default OrderInfo
