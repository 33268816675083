import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  Button,
  FormControl,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  TextField
} from '@mui/material'
import EditIcon from '../../../../../../assets/images/note.png'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import useStore from '../../../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../../../config/Toaster'
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper'
import {
  BACKLOG_STATUSES
} from '../../../../../../config/Constant'
import RecordPerPage from '../../../../../../components/AgGridWrapper/RecordPerPage'
import BacklogCreate from './BacklogCreate'
import BacklogEdit from './BacklogEdit'
import CancelOrderModal from '../CancelOrderModal'
import CancelOrderComponent from '../CancelOrderComponent'
import BacklogCancelledStatusRemarksPrompt from './BacklogCancelledStatusRemarksPrompt'
import AllPermissionList from '../../../../../../config/AllPermissionList'

const BacklogList = observer(() => {
  const { BACKLOG, ORDER, AUTH } = useStore()
  const { id } = useParams()
  const { setupGrid, agGridParams, setBacklogStatusValue, updateBacklogStatus, setEditingBacklogId, setPageSize, perPage } = BACKLOG
  const { permissions } = AUTH
  const { setStatusValue, setRemarks } = ORDER
  const [cancelledStatusModal, setCancelledStatusModal] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>('');
  const [backlogCancelledStatusModal, setBacklogCancelledStatusModal] = useState<boolean>(false)
  const gridApiRef = useRef<any>(null);
  const [createFormOpen, setCreateFormOpen] = useState<boolean>(false);
  const [editFormOpen, setEditFormOpen] = useState<boolean>(false);

  const handleClickCreateFormOpen = () => {
    setCreateFormOpen(true);
  };
  const handleCreateFormClose = () => {
    setCreateFormOpen(false);
  };
  const handleClickEditFormOpen = (order_backlog_id: any) => {
    setEditingBacklogId(order_backlog_id);
    setEditFormOpen(true);
  };
  const handleEditFormClose = () => {
    setEditFormOpen(false);
    setEditingBacklogId(null);
  };

  const cancelOrderButtonHandle = () => {
    setRemarks("")
    setStatusValue({
      id : id, order_status : "Cancelled"
    })
    setCancelledStatusModal(true)
  }

  const closeCancelledStatusModal = () => {
    setCancelledStatusModal(false)
    setRemarks("")
  }

  // if status has been changed / api called then reset the states
  const statusChanged = () => {
    setSelectedRows([])
    setSelectedStatus("")
  }

  const handleStatusChange = async () => {
    try {
      if(selectedStatus === "" || selectedStatus == null) {
        toast.error("Please select status", Toaster)
        return;
      }
      if(selectedRows.length <= 0){
        toast.warn("Please select at least one record", Toaster)
        return;
      }
      const postData = {
        id : id,
        order_backlogs: selectedRows,
        order_backlog_status_id: selectedStatus
      }
      // if selected status is cancelled
      if(selectedStatus === 6) {
        setBacklogStatusValue(postData)
        setBacklogCancelledStatusModal(true)
      }else{
        const resData: any = await updateBacklogStatus(id, postData)
        if (resData.error === false) {
          toast.success(resData.message, Toaster)
          statusChanged()
          gridApiRef.current.deselectAll(); // Deselect all checkboxes in the UI
          setupGrid(agGridParams)
        } else {
          toast.error(resData.message, Toaster)
        }
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
	};

  const closeBacklogCancelledStatusModal = () => {
    setBacklogCancelledStatusModal(false)
    setupGrid(agGridParams)
  }

  const actionColumnRenderer = (params: any) => {
    return (
      <>
      <Box sx={{ display: (params?.data?.order_backlog_status_name === "In Review" ? "block" : "none" ) }}>
        <Tooltip title="Edit" sx={{ display : (permissions && permissions.indexOf(AllPermissionList.EDIT_ORDER_BACKLOG) >= 0) ? "inline-flex" : "none" }}>
          <IconButton
            onClick={() => handleClickEditFormOpen(params?.data?.enc_order_backlog_id)}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </Box>
      </>
    )
  }

  const onSelectionChanged = (event: any) => {
    let selectedItems = event.api.getSelectedRows().map((row: any) => ({ order_backlog_id: row.enc_order_backlog_id }) )
    setSelectedRows(selectedItems);
  };

  const [columnDefs, ] = useState<any>([
    {
     // headerCheckboxSelection: true, // Enable "Select All" checkbox in header
     checkboxSelection: true, // Enable checkboxes for individual rows
     sortable : false,
     filter: false,
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable : false,
      filter: false,
      cellRenderer: actionColumnRenderer,
    },
    {
      headerName: 'Backlog ID',
      field: 'order_backlog_id',
    },
    {
      headerName: 'Backlog Type',
      field: 'backlog_type_name',
    },
    {
      headerName: 'Backlog Sub Type',
      field: 'backlog_sub_type_name',
    },
    {
      headerName: 'Description',
      field: 'description',
    },
    {
      headerName: 'Status',
      field: 'order_backlog_status_name',
    }
  ])

  return (
    <>
     <Box sx={{ mb: '20px', mt : '20px', textAlign: 'right' }}>
        <Button
          onClick={handleClickCreateFormOpen}
          variant="contained"
          startIcon={<AddCircleIcon />}
          sx={{ display : (permissions && permissions.indexOf(AllPermissionList.ADD_ORDER_BACKLOG) >= 0) ? "inline-flex" : "none" }}
        >
          NEW BACKLOG
        </Button>
      </Box>

      {/* Add Form */}
      <Dialog maxWidth={`xl`} fullWidth={true} open={createFormOpen} onClose={handleCreateFormClose}>
        <DialogTitle>Add Backlog</DialogTitle>
        <DialogContent>
          <BacklogCreate close={handleCreateFormClose}/>
        </DialogContent>
      </Dialog>

      {/* Edit Form */}
      <Dialog maxWidth={`xl`} fullWidth={true} open={editFormOpen} onClose={handleEditFormClose}>
        <DialogTitle>Edit Backlog</DialogTitle>
        <DialogContent>
          <BacklogEdit close={handleEditFormClose}/>
        </DialogContent>
      </Dialog>

      <Grid sx={{ mb: "20px",mt: "-70px" } } container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                select
                value={selectedStatus}
                placeholder='Select status'
                label="Select status"
                onChange={(e) => setSelectedStatus(e.target.value)}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  },
                }}
              >
                <MenuItem value={""}>Select status</MenuItem>
                {BACKLOG_STATUSES.map((e:any, index: number) => (
                    <MenuItem key={index} value={e.id}>{e.name}</MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleStatusChange()}
            >
              Change Status
            </Button>
          </Grid>
      </Grid>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={(params) => {
          setupGrid(params, id);
          gridApiRef.current = params.api; // Set the gridApiRef when the grid is ready
        }}
        rowSelection={'multiple'}
        onSelectionChanged={onSelectionChanged}
        suppressRowClickSelection={true}
      />
      <Box className="record-per-page-filter">
        <RecordPerPage
          style={{ width: "150px" }}
          defaultValue={`${perPage}`}
          onChange={(e:any)=> setPageSize(e.target.value)}
        />
      </Box>

      <CancelOrderComponent cancelOrderButtonHandle={cancelOrderButtonHandle}/>
      <CancelOrderModal visible={cancelledStatusModal} close={closeCancelledStatusModal} />
      <BacklogCancelledStatusRemarksPrompt visible={backlogCancelledStatusModal} close={closeBacklogCancelledStatusModal} statusChanged={statusChanged} />
    </>
  )
})

export default BacklogList
