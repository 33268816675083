import React from 'react'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Container, ListItemIcon, ListItemText } from '@mui/material'
import { userProfileMenus } from  "../../../../config/headerMenu"
import { useNavigate } from 'react-router'
import useStore from '../../../../store'
import { BaseResponse } from '../../../../interfaces/ApiResponse'

const drawerWidth = 260
interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

interface AppHeaderProps {
  open?: boolean
  handleDrawer?: any
}

const AppHeader: React.FC<AppHeaderProps> = ({ open, handleDrawer }) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  )

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  function stringAvatar(name: string) {
    return { 
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const { AUTH } = useStore();
  const { doLogout, user } = AUTH;
  const navigate = useNavigate();

  // call logout function
  const handleLogout = async () => {
    try {
      const resData: BaseResponse = await doLogout();
        if(resData.error === false){
          navigate("/login");
          return null;
        }else{
          return null;
        }
      } catch(err:any){
        const errData: BaseResponse = err;
        console.error(errData);
      }
  }

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon />
            </IconButton>
          
            <Box
              sx={{ flexGrow: 1, display: {  md: 'flex' } }}
              className = "notificationBar"
            >
            </Box>

            <Box sx={{ flexGrow: 0 }} className="user-avtar">
              <Tooltip title="Account Settings" >
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: "4px"  }}>
                  {/* <Avatar alt="Remy Sharp" src={userIcon} /> */}
                  <Avatar {...stringAvatar(`${user.first_name} ${user.last_name}`)} />
                </IconButton>
              </Tooltip>
               <span>{`${user.first_name} ${user.last_name}`}</span>
              <Menu
                sx={{ mt: '45px', width: 320 }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userProfileMenus.map((menu : any) => (
                  <MenuItem key={menu.name}>
                    {menu.icon}
                    <Typography sx={{ paddingLeft: "8px", width:180 }} textAlign="start" onClick={() => navigate(menu.url)} >{menu.name}</Typography>
                  </MenuItem>
                ))}
                {/* <Divider /> */}
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon/>
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default AppHeader
