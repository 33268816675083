import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
// ag grid
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
// tostify
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

// Mui-x 5
import type {} from '@mui/x-date-pickers/themeAugmentation';

// stores
import useStore from './store'
import Router from './config/RouterConfig'
import loadingStore from './store/LoadingStore/LoadingStore'

import PageSpinner from './components/PageSpinner/PageSpinner'

const App: React.FC = observer(() => {
  const { AUTH } = useStore()
  const { InitializeApp } = AUTH

  useEffect(() => {
      InitializeApp()
  }, [InitializeApp])
  return (
    <>
      {loadingStore.appLoading ? <PageSpinner /> : null}
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <ToastContainer />
    </>
  )
})

export default App
