import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  MenuItem,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import * as uuid from "uuid";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { observer } from 'mobx-react'
import useStore from "../../../../store";
import { yupResolver } from "@hookform/resolvers/yup";
import { BaseResponse } from "../../../../interfaces/ApiResponse";
import PermissionTreeView from "./PermissionTreeView";
import { PermissionRenderTree } from "../../../../store/RoleStore/PermissionInterface";
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import AlertComponent, { AlertData } from "../../../../components/utilities/AlertComponent";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Role name is required").min(2, "Role must be at least 2 characters long").matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
  status: Yup.string().required("Status is required"),
});

const RoleCreate: React.FC = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<PermissionRenderTree[]>([]);
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  const { ROLE } = useStore();
  const { createRole, fetchPermissionsTreeData } = ROLE;
  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    defaultValues: {
      name: "",
      status: "1",
    },
  });

  const setFormFields =async () => {
    try{
      const fieldsData: any = await fetchPermissionsTreeData();
      await setPermissions(fieldsData.data.permissions);

    } catch(err:any){
      console.error(err)
      const errData: BaseResponse = err;
      toast.error(errData.message, Toaster);
    }
  }


  const onSubmit = async (_data: any) => {
    _data.permissions = selectedIds;
    try {
      const resData: BaseResponse = await createRole(_data);
        if(resData.error === false){
          toast.success(resData?.message, Toaster)
          navigate("../")
        }else{
          window.scrollTo({top: 0, behavior: 'smooth' });
          setAlertMessage({severity:'error', message: resData.message, data: resData.data});
          toast.error(resData?.message, Toaster)
        }
      } catch(err:any){
        console.error(err)
        const errData: BaseResponse = err;
        if(errData.data['name']){
          setError("name", {
            type: "manual",
            message: errData.data['name'][0],
          });
        }
        toast.error(errData?.message, Toaster);
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
  };


  useEffect(() => {
    setFormFields();
  }, [])
  
  return (
    <>
    <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Add Role
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="name"
                      label="Role Name"
                      variant="outlined"
                      helperText={errors.name?.message}
                      error={errors.name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="status"
                  control={control}
                  defaultValue={"1"} // Set default value for this field
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-select-currency"
                      select
                      label="Status"
                      fullWidth
                      helperText={errors.status?.message}
                      error={errors.status ? true : false}
                    >
                      <MenuItem key={uuidValue()} value={1}>
                        Active
                      </MenuItem>
                      <MenuItem key={uuidValue()} value={0}>
                        Blocked
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <PermissionCheckList selectedIds={selectedIds} setSelectedIds={setSelectedIds} /> */}
                <PermissionTreeView selected={selectedIds} setSelected={setSelectedIds} permissions={permissions} setPermissions={setPermissions}/>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={() => navigate('../')}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  );
});

export default RoleCreate;
