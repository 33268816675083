import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import { ExtraPointListProps } from './ExtraPointInterface'
import { CHANGE_STATUS } from '../../config/Constant'

export default class ExtraPointStore {
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: ExtraPointListProps[]
  editValues = null
  deleteValues = null
  statusValues = { id : 0, status : "" }

  constructor() {
    makeAutoObservable(this)
  }

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent) => {
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request).then((data) => {
          params.success({
            rowData: data.extra_points.rows,
            rowCount: data.extra_points.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  setDeleteValues = (data: any) => {
    this.deleteValues = data
  }

  setStatusValue = (data: any) => {
    this.statusValues = data
  }

  setEditValues = (data: any) => {
    this.editValues = data
  }

  // Setter Functions
  private setListData = (value?: ExtraPointListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
    try{
    this.setListData([])
    const { data } = await axios.post(API_URL.EXTRA_POINT.LIST, payload)
    return Promise.resolve(data.data)
    } catch(err: any) {
        return Promise.reject(err.response.data)
    }
  }

  public getCreateFormFieldData = async (): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const {data} = await axios.get(API_URL.EXTRA_POINT.GET_LIST_ITEMS)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public createExtraPoints = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.EXTRA_POINT.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public fetchExtraPoint = async (_id: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.EXTRA_POINT.VIEW(_id))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateExtraPoints = async (_id: any, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.EXTRA_POINT.UPDATE(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public changeStatus = async (_data: any): Promise<any> => {
    try {
      _data.action_type = CHANGE_STATUS;
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(
        API_URL.EXTRA_POINT.CHANGE_STATUS(_data.id),
        _data,
      )
      //  this.setupGrid(this.agGridParams)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
}
