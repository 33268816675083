import { useState } from 'react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useStore from '../../../store'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import AlertComponent, { AlertData } from '../../../components/utilities/AlertComponent'
import { observer } from 'mobx-react'
import { BaseResponse} from '../../../interfaces/ApiResponse'
import { toast } from 'react-toastify'
import Toaster from '../../../config/Toaster'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
  password: Yup.string()
    .required('Password is required'),
  remember_me : Yup.boolean()
})

const Login = observer(() => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      email: sessionStorage.getItem('email') || '',
      password: sessionStorage.getItem('password') || '',
      remember_me : sessionStorage.getItem('remember_me') === 'true'
    }
  })
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate()
  const { AUTH } = useStore()
  const { doLogin } = AUTH

  const onSubmit = async (_data: any) => {
    try {
      setAlertMessage(null);	
      const resData: BaseResponse = await doLogin(_data);
      if(resData.error === false){
          const { email, password, remember_me } = _data
          if (remember_me === true) {
            sessionStorage.setItem('email', email);
            sessionStorage.setItem('password', password);
            sessionStorage.setItem('remember_me', remember_me);
          } else {
            sessionStorage.clear();
          }
          navigate("/dashboard");
          toast.success("Authorized Successfully!", Toaster);
          return null;
        }else{
          setAlertMessage({
            severity:'error',
            message: resData.message,
            data: resData.data
          });
        }
      } catch(err:any){
        const errData: BaseResponse = err;
        setAlertMessage({
          severity:'error',
          message: errData.message,
          data: errData.data
        });
      }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            {...register('email')}
            autoComplete="email"
            helperText={errors.email?.message}
            error={errors.email ? true : false}
            autoFocus
          />

          <TextField
            margin="normal"
            required
            fullWidth
            {...register('password')}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            helperText={errors.password?.message}
            error={errors.password ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
              control={
                <Controller
                  name="remember_me"
                  control={control}
                  defaultValue={false}
                  render={({ field: { value, ref, ...field } }) => (
                    <Checkbox
                      {...field}
                      inputRef={ref}
                      checked={!!value}
                      disableRipple
                    />
                  )}
                />
              }
              label="Remember me"
              labelPlacement="end"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} variant='body2' to="/forget-password">
              Forgot Password?</Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

     {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
    </Container>
  )
})

export default Login
