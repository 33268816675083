import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as uuid from 'uuid'
import Menu from '../../../../interfaces/Menu'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

interface SideBarMenuItemProps {
  item: Menu
  menuOpen: boolean
}

const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({
  item,
  menuOpen,
}) => {
  const location = useLocation()
  const [open, setOpen] = useState<boolean>(false)
  const handleClick = () => {
    setOpen(!open)
  }

  const uuidValue = () => uuid.v4()

  useEffect(() => {
    setOpen(false)
  }, [location])

  const theme = createTheme({
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: ' #fcb900',
              color: '#333',
            },
          },
        },
      },
    },
  })

  return (
    <>
      <ThemeProvider theme={theme}>
        {item.childrens ? (
          <ListItem
            key={uuidValue()}
            disablePadding
            sx={{ display: item.hasPermission ? 'block' : 'none' }}
          >
            <ListItemButton
              onClick={handleClick}
              sx={{
                minHeight: 48,
                justifyContent: menuOpen ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: menuOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{ opacity: menuOpen ? 1 : 0 }}
              />
              {menuOpen && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            <Collapse
              in={location.pathname.startsWith(item.url as string) || open}
              timeout="auto"
              unmountOnExit
            >
              {item.childrens.map((ele, i) => {
                return (
                  <List
                    key={uuidValue()}
                    component="div"
                    disablePadding
                    sx={{ display: ele.hasPermission ? 'block' : 'none' }}
                  >
                    <Link
                      to={ele.url || ''}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <ListItemButton
                        sx={{ pl: menuOpen ? 4 : 2.5 }}
                        selected={location.pathname.startsWith(
                          ele.url as string,
                        )}
                      >
                        <ListItemIcon>{ele.icon}</ListItemIcon>
                        <ListItemText
                          primary={ele.name}
                          sx={{ opacity: menuOpen ? 1 : 0, textWrap: 'wrap' }}
                        />
                      </ListItemButton>
                    </Link>
                  </List>
                )
              })}
            </Collapse>
          </ListItem>
        ) : (
          <ListItem
            key={uuidValue()}
            disablePadding
            sx={{ display: item.hasPermission ? 'block' : 'none' }}
          >
            <Link
              to={item.url || ''}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: menuOpen ? 'initial' : 'center',
                  px: 2.5,
                }}
                selected={location.pathname.startsWith(item.url as string)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: menuOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: menuOpen ? 1 : 0, textWrap: 'wrap' }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        )}
      </ThemeProvider>
    </>
  )
}

export default SideBarMenuItem
