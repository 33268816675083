import { Tooltip, IconButton, Box, Button, Switch, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
import useStore from '../../../../../store'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditIcon from '../../../../../assets/images/note.png'
import { toast } from 'react-toastify'
import Toaster from '../../../../../config/Toaster'
import { observer } from 'mobx-react'

const CouponList = observer(() => {
  const { COUPON } = useStore()
  const navigate = useNavigate()
  const { fetchList, changeStatus } = COUPON
  const [rowData, setRowData] = useState<any>([])

  const changeStatusHandle = async (_data: any) => {
    try {
      const resData: any = await changeStatus(_data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        fetchList()
          .then((data: any) => {
            setRowData(data)
          })
          .catch((error: any) => {
            setRowData([])
          })
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  const statusColumnRenderer = (params: any) => {
    const label = { inputProps: { 'aria-label': `Status Switch` } }
    return (
      <>
        <Switch
          {...label}
          defaultChecked={params.value === 1}
          onChange={() =>
            changeStatusHandle({
              id: params.data?.enc_id,
              status: params.value === 1 ? 0 : 1,
            })
          }
        />
      </>
    )
  }

  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              navigate('edit/' + params?.data?.enc_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const [columnDefs] = useState<any>([
    {
      headerName: 'Coupon Code',
      field: 'coupon_code',
    },
    {
      headerName: 'Value',
      field: 'value',
    },
    {
      headerName: 'Value Type',
      field: 'value_type',
    },
    {
      headerName: 'Can Use (Times)',
      field: 'can_use',
    },
    {
      headerName: 'Used (Times)',
      field: 'used',
    },
    {
      headerName: 'Restriction Type',
      field: 'restriction_type',
    },
    {
      headerName: 'Allow Yahoo/Gmail',
      field: 'allow_gmail_yahoo',
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: false,
      filter: false,
      cellRenderer: statusColumnRenderer,
      width: 100,
      minWidth: 100,
      pinned: 'right',
    },
    {
      headerName: 'Action',
      field: 'id',
      sortable: false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 100,
      minWidth: 100,
      pinned: 'right',
    },
  ])

  useEffect(() => {
    fetchList()
      .then((data: any) => {
        setRowData(data)
      })
      .catch((error: any) => {
        setRowData([])
      })
  }, [fetchList])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        spacing={2}
        sx={{ my: '20px' }}
      >
        <h1>Coupons</h1>
        <Box sx={{ my: '20px' }}>
          <Button
            onClick={() => navigate('create')}
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            NEW COUPON
          </Button>
        </Box>
      </Stack>
      <Box sx={{}}>
        <AgGridWrapper
          type="clientSide"
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </Box>
    </>
  )
})

export default CouponList
