import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import loadingStore from "../LoadingStore/LoadingStore";
import { CHANGE_STATUS } from "../../config/Constant";

export default class RoleStore {

  constructor() {
    makeAutoObservable(this);
  }


  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
      try{
        loadingStore.setAppLoading(true);
        const { data } = await axios.get(API_URL.ROLE.LIST)
        loadingStore.setAppLoading(false);
        return Promise.resolve((data.data.roles))
      }catch(err: any) {
        loadingStore.setAppLoading(false);
        return Promise.reject(err.response.data)
      }
  };


  public createRole = async (_data: any): Promise<any> => {
   try{
    loadingStore.setAppLoading(true);
    const { data } = await axios.post(API_URL.ROLE.CREATE, _data);
    loadingStore.setAppLoading(false);
    return Promise.resolve(data);
   }catch(err:any){
    loadingStore.setAppLoading(false);
    return Promise.reject(err.response.data);
   }
  }

  public fetchRole = async (_id: any): Promise<any> => {
   try{
    loadingStore.setAppLoading(true);
    const { data } = await axios.get(API_URL.ROLE.VIEW(_id));
    loadingStore.setAppLoading(false);
    return Promise.resolve(data);
   }catch(err:any){
    loadingStore.setAppLoading(false);
    return Promise.reject(err.response.data);
   }
  }

  public fetchPermissionsTreeData = async (_id: any): Promise<any> => {
   try{
    loadingStore.setAppLoading(true);
    const { data } = await axios.get(API_URL.PERMISSION.GET_LIST_ITEMS);
    loadingStore.setAppLoading(false);
    return Promise.resolve(data);
   }catch(err:any){
    loadingStore.setAppLoading(false);
    return Promise.reject(err.response.data);
   }
  }

  public updateRole = async (_id:number, _data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     const { data } = await axios.put(API_URL.ROLE.UPDATE(_id), _data);
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }

  public changeStatus = async (_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     _data.action_type = CHANGE_STATUS;
     const { data } = await axios.post(API_URL.ROLE.CHANGE_STATUS,_data)
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }
}
