import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import AlertComponent, {
  AlertData,
} from '../../../../components/utilities/AlertComponent'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required("Old password is required"),
  password: Yup.string().required("New password is required").test({
    name: 'password',
    skipAbsent: true,
    test(value, ctx) {
      const valString = String(value)
      if (valString && valString.length <= 8) {
        return ctx.createError({
          message: 'Password must be at least 8 characters long',
        })
      }
      return true
    },
  }),
  confirm_password: Yup.string().required("Confirm password is required").test({
    name: 'confirm_password',
    skipAbsent: true,
    test(value, ctx) {
      const { resolve } : any = this
      const password = resolve(Yup.ref('password'))
      if (!password) {
        // Password is not provided, no need to validate further
        return true
      }
      // Check if passwords match
      return (
        (password && value === password) ||
        ctx.createError({
          message: 'Password and Confirm-password do not match',
        })
      )
    },
  }),
})

const ChangePassword = observer(() => {
  const navigate = useNavigate()
  const { AUTH } = useStore()
  const {  updateProfilePassword  } = AUTH
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      old_password: '',
      password: '',
      confirm_password: '',
    },
  })

  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfNewPassword, setShowConfNewPassword] = useState<boolean>(false);

  const onSubmit = async (_data: any) => {
    try {
      if (_data.password === '') {
        delete _data['password']
        delete _data['confirm_password']
      }
      const resData: BaseResponse = await updateProfilePassword(_data)
      if (resData.error === false) {
        toast.success(resData?.message, Toaster)
        navigate('.', { replace: true });
        reset({
          old_password: '',
          password: '',
          confirm_password: '',
        });
      } else {
         window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        setAlertMessage({
          severity:'error',
          message: resData.message,
          data: resData.data
        });
        toast.error(resData?.message, Toaster)
      }
    } catch (err: any) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' 
      });
      const errData: BaseResponse = err
      setAlertMessage({
        severity:'error',
        message: errData.message,
        data: errData.data
      });
      
      toast.error(errData?.message, Toaster)
    }
  }

  useEffect(() => {
   
  }, [])

  return (
    <>
        <>
     <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mb: 4, mt: 2 }}
            >
              Change Password
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
                <Grid item xs={6}>
                  <Controller
                    name="old_password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        id="old_password"
                        label="Enter Old Password"
                        type={showOldPassword ? 'text' : 'password'}
                        autoComplete="off"
                        variant="outlined"
                        helperText={errors.old_password?.message}
                        error={errors.old_password ? true : false}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowOldPassword(!showOldPassword)}
                                edge="end"
                              >
                                {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        id="password"
                        label="Enter New Password"
                        type={showNewPassword ? 'text' : 'password'}
                        autoComplete="off"
                        variant="outlined"
                        helperText={errors.password?.message}
                        error={errors.password ? true : false}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                edge="end"
                              >
                                {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="confirm_password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        id="confirm_password"
                        label="Confirm New Password"
                        type={showConfNewPassword ? 'text' : 'password'}
                        autoComplete="off"
                        variant="outlined"
                        helperText={errors.confirm_password?.message}
                        error={errors.confirm_password ? true : false}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowConfNewPassword(!showConfNewPassword)}
                                edge="end"
                              >
                                {showConfNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button type="button" variant="outlined" size="medium" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
    </>
  )
})

export default ChangePassword
