import { observer } from "mobx-react";
import React from "react";

const Dashboard: React.FC = observer(() => {
  return (
    <>
      <div className="dashBoardWrap">
        <h1>Welcome to Dashboard</h1>
      </div>
    </>
  );
});

export default Dashboard;
