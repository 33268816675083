import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import useStore from '../../../store'
import { Outlet, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/material/styles'

// import custom components
import SidebarView from "./AppSidebar/AppSidebar";
import AppHeaderView from "./AppHeader/AppHeader";

const MainLayout: React.FC = observer(() => {
  const navigate = useNavigate()
  const { AUTH } = useStore()
  const [open, setOpen] = useState<boolean>(true)

  const { appLoading, user } = AUTH

  const handleDrawer = () => {
    setOpen(!open)
  }

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    if (!userToken) {
      navigate('/login')
    }
  }, [navigate])

  if(appLoading){
    return null
  }
  if(!user){
    return null
  }

  const DrawerHeader = styled('div')(({ theme } : any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }))

  const width = `calc(100% - 260px)`;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppHeaderView open={open} handleDrawer={handleDrawer} />
      <SidebarView open={open} handleDrawer={handleDrawer} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 , width : width }}>
        <DrawerHeader />
        {/* Body goes below */}
        {/* Breadcrums */}
        <Outlet />
      </Box>
    </Box>
  )
})

export default MainLayout
