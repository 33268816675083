import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { ClientOrderListProps } from './ClientOrderInterface'
import Config from '../../config/Config'
import RootStore from '../RootStore/RootStore'

export default class ClientOrderStore {
  private rootstore: RootStore
  agGridParams: any = null
  clientID: any = null
  currentClientValue: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: ClientOrderListProps[]

  constructor() {
    this.rootstore = new RootStore()
    makeAutoObservable(this)
  }

  setCurrentClientValue = (data : any) =>  this.currentClientValue = data

  setClientID = (data : any) =>  this.clientID = data

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent, clientID: any = this.clientID) => {
    this.setClientID(clientID)
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions, clientID)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions, clientID?: any) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request, clientID).then((data) => {
          params.success({
            rowData: data.orders.rows,
            rowCount: data.orders.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  // Setter Functions
  private setListData = (value?: ClientOrderListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any, clientID: any): Promise<any> => {
    try{
      this.setListData([])
      const { data } = await axios.post(API_URL.CLIENT.CLIENTS_ORDERS_LIST(clientID), payload)
      this.setCurrentClientValue({ client_name : data?.data?.client_name })
      return Promise.resolve(data.data)
    } catch(err: any) {
      return Promise.reject(err.response.data)
    }
  }
}
