import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
  MenuItem,
} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as uuid from 'uuid'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import AlertComponent, { AlertData } from '../../../../components/utilities/AlertComponent'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { dateFormat } from '../../../../config/Constant'

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required').matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
  last_name: Yup.string().required('Last name is required').matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
  employee_id: Yup.string().required('Employee ID is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  role: Yup.string().required('Role is required'),
  department_id: Yup.string().required('Department is required'),
  shift_id: Yup.string().required('Shift is required'),
  reporting_manager_id: Yup.string().required('Reporting manager is required'),
  status: Yup.number()
  .required('Status is required')
  .oneOf([0, 1], 'Invalid status'),
  confirmation_date: Yup.date().nullable(),
})

const UserCreate = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { USER } = useStore()
  const { getUserFormFieldData, createUser } = USER
  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      first_name: '',
      last_name: '',
      employee_id: '',
      email: '',
      password: '',
      confirm_password: '',
      role: '',
      department_id: '',
      shift_id: '',
      reporting_manager_id: '',
      status: 1,
      confirmation_date: null,
    },
  })
  const [roleMenuItems, setRoleMenuItems] = useState<any>([])
  const [reportingManagerMenuItems, setReportingManagerMenuItems] = useState<any>([])
  const [departmentMenuItems, setDepartmentMenuItems] = useState<any>([])
  const [shiftMenuItems, setShiftMenuItems] = useState<any>([])
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfPassword, setShowConfPassword] = useState<boolean>(false);

  const setFormFields = async () => {
    try {
      const fieldsData: any = await getUserFormFieldData()
      setRoleMenuItems(fieldsData.data?.roles)
      setDepartmentMenuItems(fieldsData.data?.departments)
      setReportingManagerMenuItems(fieldsData.data?.reporting_managers)
      setShiftMenuItems(fieldsData.data?.shifts)
    } catch (err: any) {
      console.error(err)
      const errData: BaseResponse = err
       window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
      setAlertMessage({
          severity:'error',
          message: errData.message,
          data: errData.data
        });
      if (errData.data['email']) {
        setError('email', {
          type: 'manual',
          message: errData.data['email'][0],
        })
      }
      toast.error(errData.message, Toaster)
    }
  }

  useEffect(() => {
    setFormFields()
  }, [])

  const onSubmit = async (_data: any) => {
    try {
      if( _data?.confirmation_date){
        _data.confirmation_date = dayjs(_data?.confirmation_date).format(dateFormat)
      }
      const resData: BaseResponse = await createUser(_data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        navigate('../list')
      } else {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        setAlertMessage({
          severity:'error',
          message: resData.message,
          data: resData.data
        });
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
       window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
      setAlertMessage({
        severity:'error',
        message: errData.message,
        data: errData.data
      });
      if (errData.data['email']) {
        setError('email', {
          type: 'manual',
          message: errData.data['email'][0],
        })
      }
      if (errData.data['employee_id']) {
        setError('employee_id', {
          type: 'manual',
          message: errData.data['employee_id'][0],
        })
      }
      toast.error(errData.message, Toaster)
    }
  }

  return (
    <>
      <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Add User
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="first_name"
                      label="First Name"
                      variant="outlined"
                      helperText={errors.first_name?.message}
                      error={errors.first_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="last_name"
                      label="Last Name"
                      variant="outlined"
                      helperText={errors.last_name?.message}
                      error={errors.last_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="employee_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="employee_id"
                      label="Employee ID"
                      variant="outlined"
                      helperText={errors.employee_id?.message}
                      error={errors.employee_id ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      id="email"
                      label="Email"
                      variant="outlined"
                      helperText={errors.email?.message}
                      error={errors.email ? true : false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      id="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      variant="outlined"
                      helperText={errors.password?.message}
                      error={errors.password ? true : false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="confirm_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      id="confirm_password"
                      label="Confirm Password"
                      type={showConfPassword ? 'text' : 'password'}
                      autoComplete="off"
                      variant="outlined"
                      helperText={errors.confirm_password?.message}
                      error={errors.confirm_password ? true : false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowConfPassword(!showConfPassword)}
                              edge="end"
                            >
                            {showConfPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                    name={`confirmation_date`}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputFormat={dateFormat} // Specify your desired date format
                        disablePast={true}
                        onChange={(newValue: any) => {
                          field.onChange(newValue)
                        }}
                        label="Confirmation Date"
                        renderInput={(params) => (
                          <TextField
                            id="confirmation_date"
                            fullWidth
                            {...params}
                            helperText={errors.confirmation_date?.message}
                            error={errors.confirmation_date ? true : false}
                          />
                        )}
                      />
                    )}
                  />
              </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="department_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      select
                      label="Department"
                      defaultValue={field.value}
                      fullWidth
                      helperText={errors.department_id?.message}
                      error={errors.department_id ? true : false}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem key={uuidValue()} value={''} disabled>
                        Select Department
                      </MenuItem>
                      {departmentMenuItems &&
                        departmentMenuItems.map((e: any) => (
                          <MenuItem key={uuidValue()} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="reporting_manager_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      select
                      label="Reporting Manager"
                      defaultValue={field.value}
                      fullWidth
                      helperText={errors.reporting_manager_id?.message}
                      error={errors.reporting_manager_id ? true : false}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem key={uuidValue()} value={''} disabled>
                        Select Reporting Manager
                      </MenuItem>
                      {reportingManagerMenuItems &&
                        reportingManagerMenuItems.map((e: any) => (
                          <MenuItem key={uuidValue()} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="shift_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      select
                      label="Shift"
                      defaultValue={field.value}
                      fullWidth
                      helperText={errors.shift_id?.message}
                      error={errors.shift_id ? true : false}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem key={uuidValue()} value={''} disabled>
                        Select Shift
                      </MenuItem>
                      {shiftMenuItems &&
                        shiftMenuItems.map((e: any) => (
                          <MenuItem key={uuidValue()} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      select
                      label="Role"
                      defaultValue={field.value}
                      fullWidth
                      helperText={errors.role?.message}
                      error={errors.role ? true : false}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        },
                      }}
                    >
                      {roleMenuItems &&
                        roleMenuItems.map((e: any) => (
                          <MenuItem key={uuidValue()} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      id="status"
                      select
                      label="Status"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e)
                      }}
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select Status
                      </MenuItem>
                      {[{ id : 1, label : "Active" }, { id : 0, label : "Inactive" }].map((status: any) => (
                          <MenuItem key={status.id} value={status.id}>
                            {status.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button type="button" variant="outlined" size="medium" onClick={()=>navigate(-1)}>
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default UserCreate
