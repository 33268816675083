import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import Config from '../../config/Config'

export default class EarningPointLogStore {
  agGridParams: any = null
  usersList: any[] = []
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: any[]

  constructor() {
    makeAutoObservable(this)
  }

  setUsersList = (data: any) => this.usersList = data

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent, filterData: any) => {
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions, filterData)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) { 
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions, filterData?: any) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        const modifiedRequest = {
          ...params.request,
          ...filterData
        };
        this.fetchList(modifiedRequest).then(({data}) => {
         this.setUsersList(data?.users)
          params.success({
            rowData: data.earning_points.rows,
            rowCount: data.earning_points.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  // Setter Functions
  private setListData = (value?: any[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
    try{
    this.setListData([])
    const { data } = await axios.post(API_URL.EARNING_POINT_LOG.LIST, payload)
    return Promise.resolve(data)
    } catch(err: any) {
        return Promise.reject(err.response.data)
    }
  }

}
