import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import IntegrationInstructionsRoundedIcon from '../../../../../assets/images/esp.png';
import ExtensionRoundedIcon from '../../../../../assets/images/addones.png';
import BackupTableRoundedIcon from '../../../../../assets/images/layout.png';
import DateRangeRoundedIcon from '../../../../../assets/images/timeslot.png';
import CurrencyExchangeRoundedIcon from '../../../../../assets/images/currency-exchange.png';

const tabRoutes = [
  { label: 'ESP', value: '/masters/parameters/esps', icon: <img src={IntegrationInstructionsRoundedIcon} alt='integration'  className='list-icon' /> },
  { label: 'Add-ons', value: '/masters/parameters/addons', icon: <img src={ExtensionRoundedIcon} alt='integration'  className='list-icon' /> },
  { label: 'Layouts', value: '/masters/parameters/layouts', icon: <img src={BackupTableRoundedIcon} alt='integration'  className='list-icon' />},
  { label: 'Timeslots', value: '/masters/parameters/timeslots', icon: <img src={DateRangeRoundedIcon} alt='integration'  className='list-icon' /> },
  { label: 'Currency Conversion Rates', value: '/masters/parameters/currency-conversion-rates', icon: <img src={CurrencyExchangeRoundedIcon} alt='integration'  className='list-icon' /> },
  // Add more tabs if needed
];

const Parameters = () => {
    const location = useLocation();
    const activeTabValue = tabRoutes.find(tab => location.pathname.startsWith(tab.value))?.value;

    return (
          <>
            <h1>Manage Parameters</h1>
            <Box sx={{ width: '100%' }}>
            <Tabs value={activeTabValue} aria-label="icon label tabs example"  sx={{
            bgcolor: '#5c5c5c',
          }}>
              {tabRoutes.map(tab => (
                <Tab
                  key={tab.value}
                  icon={tab.icon}
                  label={tab.label}
                  value={tab.value}
                  component={Link}
                  to={tab.value}
                  sx={{
                    color: '#ffff',
                  }}
                />
              ))}
            </Tabs>
            </Box>
            <Outlet />
          </>
    )
}

export default Parameters