import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Unauthorized: React.FC = () => {

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        bgcolor: 'primary.main',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" component="h1" sx={{ mt: 2 }}>
        Unauthorized Access!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        We apologize, but it seems that you do not have the required permissions to access this page or perform the requested action. Please review your user privileges or contact your system administrator for assistance.
      </Typography>
      <Link to="/dashboard" style={{ textDecoration: 'none', marginTop: '20px' }}>
            <Button variant="outlined" color="secondary">
              Go to Dashboard
            </Button>
      </Link>
    </Box>
  );
};

export default Unauthorized;