import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { EscalationListProps } from './EscalationInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import { CHANGE_STATUS } from '../../config/Constant'

export default class EscalationStore {
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: EscalationListProps[]
  editValues = null
  statusValues = { id : 0, escalation_status : "", responsible_person: "" }
  deleteValues = null

  constructor() {
    makeAutoObservable(this)
  }

  // Resolve modal
  resolveModalVisible = false;
  resolveModalData: any = {}

  setResolveModalVisible = (val: boolean) => {
    this.resolveModalVisible = val
  }
  setResolveModalData = (val: any) => {
    this.resolveModalData = val
  }
  
  submitResolve(_data: any){
    this.setResolveModalVisible(false)
  }

  setStatusValue = (data: any) => {
    this.statusValues = data
  }
  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent) => {
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request).then(({data}) => {
          params.success({
            rowData: data.escalations.rows,
            rowCount: data.escalations.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  // Setter Functions
  private setListData = (value?: EscalationListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
    try{
    this.setListData([])
    const { data } = await axios.post(API_URL.ESCALTION.LIST, payload)
    return Promise.resolve(data)
    } catch(err: any) {
        return Promise.reject(err.response.data)
    }
  }

  public createEscalation = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.ESCALTION.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public getCreateFormFieldData = async (): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const {data} = await axios.get(API_URL.ESCALTION.CREATE_FORM_LIST_ITEMS)
      let dataObj = {
        data: data
      }
      loadingStore.setAppLoading(false)
      return Promise.resolve(dataObj)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public fetchEscalation = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.ESCALTION.VIEW(_data))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateEscalation = async (_id: number, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.ESCALTION.UPDATE(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public changeStatus = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      _data.action_type = CHANGE_STATUS;
      const { data } = await axios.put(
        API_URL.ESCALTION.CHANGE_STATUS(_data?.id),
        _data,
      )
      //  this.setupGrid(this.agGridParams)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err: any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

}
