import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Box from "@mui/material/Box"

const PublicLayout: React.FC = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    let userToken = localStorage.getItem("token") || null;
		if (userToken) {
			navigate("/dashboard");
		}
	}, [navigate]);

  return (
    <>
    <Box>
        <Outlet />
    </Box>
    </>
  );
});

export default PublicLayout;
