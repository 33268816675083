import React from 'react'
import {
  TextField,
  Grid,
  Autocomplete,
  Tooltip,
  IconButton,
} from '@mui/material'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { Controller } from 'react-hook-form'
import { observer } from 'mobx-react'

const UserPointFields = observer(
  ({
    id,
    control,
    removeUserFields,
    dropdownUsers,
  }: any) => {
    return (
      <div key={id}>
        <Grid
          key={id}
          container
          sx={{ py: 1 }}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4}>
            <Controller
              name={`users_points.${id}.user_id`}
              control={control}
              render={({ field, fieldState }: any) => (
                <Autocomplete
                  {...field}
                  options={dropdownUsers ?? []}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.label === value.label
                  }
                  onChange={(e, newVal: any) => {
                    field.onChange(newVal)
                  }}
                  getOptionLabel={(option: any) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="User"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name={`users_points.${id}.points`}
              control={control}
              render={({ field, fieldState }) => (
                <div className="d-flex flex-row">
                  <TextField
                    fullWidth
                    id={`users_points.${id}.point`}
                    type="text"
                    label="Points"
                    variant="outlined"
                    helperText={fieldState?.error?.message}
                    error={fieldState.invalid ? true : false}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip
              title="Remove"
              sx={{ visibility: id === 0 ? 'hidden' : '' }}
            >
              <IconButton onClick={() => removeUserFields(id)}>
                <DeleteForeverRoundedIcon color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    )
  },
)

export default UserPointFields
