import React, { useState, useEffect, lazy, Suspense } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import {
  Card,
  Box,
  CardContent,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  CardActions,
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from '@mui/material'
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import useStore from '../../../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../../../config/Toaster'
import AlertComponent, {
  AlertData,
} from '../../../../../../components/utilities/AlertComponent'
import { BaseResponse } from '../../../../../../interfaces/ApiResponse'
import { HEIGHTS, ORDER_STATUSES } from '../../../../../../config/Constant'
// import TemplatesTable from './TemplatesTable'

const LazyTemplatesTable = lazy(() => import('./TemplatesTable'));

const TeamSelected = observer(() => {
  const { ORDER } = useStore()
  const { id } = useParams()
  const { fetchOrder, updateAssignOrder, setCurrentOrderStatusValue, setCurrentOrderIDValue } = ORDER
  const [alertMessage, setAlertMessage] = useState<AlertData | null>(null)
  const [orderStatus, setOrderStatus] = useState<any>(ORDER_STATUSES[0]?.name)
  const [TLs, setTLs] = useState<any>([])
  const [AMs, setAMs] = useState<any>([])
  const [reviewedBy, setReviewedBy] = useState<any>([])
  const [developers, setDevelopers] = useState<any>([])
  const [pcs, setPCs] = useState<any>([])
  const [qas, setQAs] = useState<any>([])
  const [cxs, setCXs] = useState<any>([])

  const validationSchema = Yup.object().shape({
    assign_tl: Yup.object({
      label: Yup.string().required('Please select TL'),
      id: Yup.number().nullable(),
    }).required('Please select TL'),
    assign_am: Yup.object({
      label: Yup.string().required('Please select AM'),
      id: Yup.number().nullable(),
    }).required('Please select AM'),
    assign_design_pc: Yup.object({
      label: Yup.string().nullable(),
      id: Yup.number().nullable(),
    }).nullable(),
    assign_coding_pc: Yup.object({
      label: Yup.string().required('Please select PC'),
      id: Yup.number().nullable(),
    }).required('Please select PC'),
    assign_coding_qa: Yup.object({
      label: Yup.string().required('Please select QA'),
      id: Yup.number().nullable(),
    }).required('Please select QA'),
    assign_cx: Yup.object({
      label: Yup.string().nullable(),
      id: Yup.number().nullable(),
    }).nullable(),
    template_height: Yup.object({
      label: Yup.string().required('Please select Height'),
      id: Yup.string().nullable(),
    }).required('Please select Height'),
    jobs: Yup.array().of(
      Yup.object().shape({
        service: Yup.string(),
        templates: Yup.array().of(
          Yup.object().shape({
            assign_developer: Yup.object({
              label: Yup.string().required('Developer is required'),
              id: Yup.number().nullable(),
            }).required('Developer is required'),
          }),
        ),
        notes: Yup.string().required('Notes is required'),
      }),
    ),
  })

  const {
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      assign_tl: { label: '', id: null },
      assign_am: { label: '', id: null },
      assign_design_pc: { label: '', id: null },
      assign_coding_pc: { label: '', id: null },
      assign_coding_qa: { label: '', id: null },
      assign_cx: { label: '', id: null },
      template_height: { label: '', id: null },
      jobs: [],
    },
  })

  const {
    fields: jobsFields,
    append: appendJobsFields,
  } = useFieldArray<any>({
    control,
    name: 'jobs',
  })

  const setInitialFormValues = async (_orderData: any) => {
    try{
      setTLs(_orderData?.TLs)
      setAMs(_orderData?.AMs)
      setDevelopers(_orderData?.developers)
      setPCs(_orderData?.codingPCs)
      setQAs(_orderData?.codingQAs)
      setCXs(_orderData?.codingCXs)

      // order object fields
      let orderData = _orderData?.order
      setOrderStatus(orderData?.order_status)
      setValue("assign_am", orderData?.assign_am)
      setValue("assign_tl", orderData?.assign_tl)
      setValue("assign_design_pc", orderData?.assign_design_pc)
      setValue("assign_coding_pc", orderData?.assign_coding_pc)
      setValue("assign_coding_qa", orderData?.assign_coding_qa)
      setValue("assign_cx", orderData?.assign_cx)
      setValue("template_height", { id: orderData?.template_height, label: orderData?.template_height })
      setReviewedBy(orderData?.reviewed_by)

      //set order id and status in store value
      setCurrentOrderIDValue(orderData?.order_id)
      setCurrentOrderStatusValue(orderData?.order_status) 

      // populate jobs details
      if (orderData?.jobs && orderData?.jobs.length > 0) {
        const updatedJobs = orderData?.jobs.map((job: any) => {
          const updatedTemplates = job.templates.map((template: any) => {
            return {
              ...template,
              service: job.service || '',
              template_height: { id : "", label : template?.template_height },
            }
          })

          // Return the job with the updated templates array
          return {
            ...job,
            templates: updatedTemplates,
          }
        })

        updatedJobs.forEach((job: any, index: number) => {
          appendJobsFields(job)
        })
      }
    }catch(err: any){}  
    return true
  }

  const fetchOrderData = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchOrder(id)
      if (resData.error === false) {
        await setInitialFormValues(resData?.data)
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  useEffect(() => {
    fetchOrderData(id)
  }, [id])

  const onSubmit = async (_data: any) => {
    try {
      // prepate payload
      const payload = {
        assign_tl_id: _data?.assign_tl?.id,
        assign_am_id: _data?.assign_am?.id,
        assign_design_pc_id:  _data?.assign_design_pc?.id || 0,
        assign_coding_pc_id:  _data?.assign_coding_pc?.id,
        assign_coding_qa_id:  _data?.assign_coding_qa?.id,
        assign_cx_id:  _data?.assign_cx?.id || 0,
        template_height:  _data?.template_height?.label,
        jobs: _data?.jobs?.map((job: any) => ({
          order_job_id: job?.order_job_id,
          notes: job?.notes,
          service: job?.service,
          templates: job.templates.map((template: any) => ({
            order_template_id: template.order_template_id,
            assign_developer_id: template?.assign_developer?.id || 0,
          })),
        })),
      }
      const resData: any = await updateAssignOrder(id, payload)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        // navigate('../list')
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setAlertMessage({
          severity: 'error',
          message: resData.message,
          data: resData.data,
        })
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setAlertMessage({
        severity: 'error',
        message: errData.message,
        data: errData.data,
      })
      toast.error(errData.message, Toaster)
    }
  }

  console.error(errors)

  return (
    <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card sx={{ maxWidth: '100%' }} variant='outlined'>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ mt: 3, mb: 3, px: 2 }}
              >
                {/* Order Under Review */}
              </Typography>
              <Grid
                sx={{ px: 2 }}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 3, sm: 6, md: 12 }}
              >
                <Grid item xs={3}>
                  <Controller
                    name="assign_tl"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        id="assign_tl"
                        options={TLs}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          return
                        }}
                        readOnly={(orderStatus === "Under Review" || orderStatus === "Assigned") ? false : true}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Assign TL"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="assign_am"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        id="assign_am"
                        options={AMs}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          return
                        }}
                        readOnly={(orderStatus === "Under Review" || orderStatus === "Assigned") ? false : true}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Assign AM"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disabled
                    multiple
                    id="reviewed_by"
                    options={reviewedBy}
                    value={reviewedBy}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.label === value.label
                    }
                    getOptionLabel={(option: any) => option.label || ''}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Reviewed By"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                {/* Design PC */}
                <Grid item xs={3}>
                  <Controller
                    name="assign_design_pc"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        id="assign_design_pc"
                        options={pcs}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          return
                        }}
                        readOnly={(orderStatus === "Under Review" || orderStatus === "Assigned") ? false : true}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Design PC"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                {/* Coding PC */}
                <Grid item xs={3}>
                  <Controller
                    name="assign_coding_pc"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        id="assign_coding_pc"
                        options={pcs}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          return
                        }}
                        readOnly={(orderStatus === "Under Review" || orderStatus === "Assigned") ? false : true}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="PC"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                {/* Coding QA */}
                <Grid item xs={3}>
                  <Controller
                    name="assign_coding_qa"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        id="assign_coding_qa"
                        options={qas}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          return
                        }}
                        readOnly={(orderStatus === "Under Review" || orderStatus === "Assigned") ? false : true}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="QA"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                {/* CX */}
                <Grid item xs={3}>
                  <Controller
                    name="assign_cx"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        id="assign_cx"
                        options={cxs}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          return
                        }}
                        readOnly={(orderStatus === "Under Review" || orderStatus === "Assigned") ? false : true}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="CX"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                {/* Height */}
                <Grid item xs={3}>
                  <Controller
                    name="template_height"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        id="template_height"
                        options={HEIGHTS}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          return
                        }}
                        readOnly={(orderStatus === "Under Review" || orderStatus === "Assigned") ? false : true}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Height"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                {/* Jobs Accordian */}
                <Grid item xs={12}>
                  {jobsFields.map((item: any, index: any) => {
                    return (
                      <Box key={index} sx={{ mb: '20px' }}>
                        <Accordion defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="job-content"
                            id={`job-header-${index}`}
                            sx={{
                              margin: '0px 0px',
                              bgcolor: '#b3b1b1',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography>Job ({item.order_job_id}) ({item?.service})</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{ p: '10px' }}>
                              <Suspense fallback={<CircularProgress />}>
                                      <LazyTemplatesTable
                                        key={index}
                                        jobService={item.service}
                                        rows={item.templates}
                                        control={control}
                                        setValue={setValue}
                                        trigger={trigger}
                                        jobIndex={index}
                                        developers={developers}
                                      />
                                    </Suspense>
                            </Box>
                            <Box sx={{ p: '10px' }}>
                              <Paper elevation={3} style={{ padding: '16px' }}>
                                <Typography variant="h6" gutterBottom>
                                  Template Info
                                </Typography>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle1">
                                      Job : {item?.job_type}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle1">
                                      Service: {item?.service}
                                    </Typography>
                                  </Grid>
                                  {/* Add more template details here */}
                                </Grid>
                              </Paper>
                            </Box>
                            <Box sx={{ p: '10px' }}>
                              <Grid item xs={12}>
                                <Controller
                                  name={`jobs.${index}.notes`}
                                  control={control}
                                  defaultValue={''}
                                  render={({ field, fieldState }: any) => (
                                    <TextField
                                      {...field}
                                      fullWidth
                                      id={`jobs.${index}.notes`}
                                      label="Notes"
                                      variant="outlined"
                                      multiline
                                      rows={4}
                                      error={fieldState.invalid}
                                      helperText={
                                        fieldState?.error?.message ||
                                        fieldState?.error?.label?.message
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                  })}
                </Grid>
                {/* Jobs Accordian*/}
              </Grid>
            </CardContent>
            <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
                Save
              </Button>
            </CardActions>
          </Box>
        </Card>
      </LocalizationProvider>
    </>
  )
})

export default TeamSelected
