import React from 'react'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AllPermissionList from "../../../../config/AllPermissionList";
import SideBarMenuItem from './SideBarMenuItem'
import { appConfig }  from "../../../../config/global";
import { Typography } from '@mui/material'
import useStore from '../../../../store'
import Menu from '../../../../interfaces/Menu'
import DashboardIcon from '../../../../assets/images/dashboard.png';
import UsersIcon from '../../../../assets/images/team.png';
import QuotesIcon from '../../../../assets/images/quote.png';
import OrdersIcon from '../../../../assets/images/order.png';
import ClientsIcon from '../../../../assets/images/service.png';
import ChecklistsIcon from '../../../../assets/images/checklist.png';
import DiscussionsIcon from '../../../../assets/images/discussion.png';
import EscalationsIcon from '../../../../assets/images/escalation.png';
import ZonesIcon from '../../../../assets/images/zone.png';
import FeedbacksIcon from '../../../../assets/images/feedback.png';
import EarningPointsIcon from '../../../../assets/images/earning-points.png';
import ExtraPointsIcon from '../../../../assets/images/extra-points.png';
import MastersIcon from '../../../../assets/images/master.png';
import MasterUsersIcon from '../../../../assets/images/m-users.png';
import MasterEscalationsIcon from '../../../../assets/images/m-escalations.png';
import MasterEarningPointsIcon from '../../../../assets/images/m-earning-points.png';
import MasterParametersIcon from '../../../../assets/images/manage-parameters.png';
import MasterCouponsIcon from '../../../../assets/images/coupon.png';
import MasterRolesIcon from '../../../../assets/images/role.png';
import './AppSidebar.css'

const drawerWidth = 260
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

interface AppSidebarProps {
  open?: boolean
  handleDrawer?(): void
}

const AppSidebar: React.FC<AppSidebarProps> = ({ open, handleDrawer }) => {
	const { AUTH } = useStore();
	const { permissions } = AUTH;

  const APP_BASE_URL = ''
  const MENU: Menu[] = [
    {
      name: 'Dashboard',
      icon: <img src={DashboardIcon} alt="Dashboard" className="menu-icon" />,
      url: APP_BASE_URL + '/dashboard',
      hasPermission : true,
    },
    {
      name: 'Users',
      icon: <img src={UsersIcon} alt="Users" className="menu-icon" />,
      url: APP_BASE_URL + '/users/list',
      hasPermission : ((permissions && permissions.indexOf(AllPermissionList.LIST_USERS) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_USER) >= 0)),
    },
    {
      name: 'Clients',
      icon: <img src={ClientsIcon} alt="Clients" className="menu-icon" />,
      url: APP_BASE_URL + '/clients/list',
      hasPermission : ((permissions && permissions.indexOf(AllPermissionList.LIST_CLIENTS) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_CLIENT) >= 0)),
    },
    {
      name: 'Quotes',
      icon: <img src={QuotesIcon} alt="Quotes" className="menu-icon" />,
      url: APP_BASE_URL + '/quotes/list',
      hasPermission : ((permissions && permissions.indexOf(AllPermissionList.LIST_QUOTES) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_QUOTE) >= 0)),
    },
    {
      name: 'Orders',
      icon: <img src={OrdersIcon} alt="Orders" className="menu-icon" />,
      url: APP_BASE_URL + '/orders',
      hasPermission : permissions && permissions.indexOf(AllPermissionList.LIST_ORDERS) >= 0,
    },
    {
      name: 'Checklists',
      icon: <img src={ChecklistsIcon} alt="Checklists" className="menu-icon" />,
      url: APP_BASE_URL + '/checklists',
      hasPermission : permissions && permissions.indexOf(AllPermissionList.LIST_CHECKLISTS) >= 0,
    },
    {
      name: 'Discussions',
      icon: <img src={DiscussionsIcon} alt="Discussions" className="menu-icon" />,
      url: APP_BASE_URL + '/discussions/list',
      hasPermission : ((permissions && permissions.indexOf(AllPermissionList.LIST_DISCUSSIONS) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_DISCUSSION) >= 0)),
    },
    {
      name: 'Escalations',
      icon: <img src={EscalationsIcon} alt="Escalations" className="menu-icon" />,
      url: APP_BASE_URL + '/escalations/list',
      hasPermission : ((permissions && permissions.indexOf(AllPermissionList.LIST_ESCALATIONS) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_ESCALATION) >= 0)),
    },
    {
      name: 'Zones',
      icon: <img src={ZonesIcon} alt="Zones" className="menu-icon" />,
      url: APP_BASE_URL + '/zones/list',
      hasPermission : ((permissions && permissions.indexOf(AllPermissionList.LIST_ZONES) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_ZONE) >= 0)),
    },
    {
      name: 'Feedbacks',
      icon: <img src={FeedbacksIcon} alt="Feedbacks" className="menu-icon" />,
      url: APP_BASE_URL + '/feedbacks/list',
      hasPermission : permissions && permissions.indexOf(AllPermissionList.LIST_FEEDBACKS) >= 0,
    },
    {
      name: 'My Earning Points',
      icon: <img src={EarningPointsIcon} alt="Earning Points" className="menu-icon" />,
      url: APP_BASE_URL + '/earning-points-log/list',
      hasPermission : permissions && permissions.indexOf(AllPermissionList.LIST_EARNING_POINTS) >= 0,
    },
    {
      name: 'Extra Points',
      icon: <img src={ExtraPointsIcon} alt="Extra Points" className="menu-icon" />,
      url: APP_BASE_URL + '/extra-points/list',
      hasPermission : ((permissions && permissions.indexOf(AllPermissionList.LIST_EXTRA_POINTS) >= 0) || (permissions && permissions.indexOf(AllPermissionList.ADD_EXTRA_POINT) >= 0)),
    },
    {
      name: 'Masters',
      icon: <img src={MastersIcon} alt="Masters" className="menu-icon" />,
      url: APP_BASE_URL + '/masters',
      hasPermission : permissions && permissions.indexOf(AllPermissionList.ACCESS_ALL_MASTERS) >= 0,
      childrens: [
        {
          name: 'Users',
          icon: <img src={MasterUsersIcon} alt="Users" className="menu-icon" />,
          url: APP_BASE_URL + '/masters/users',
          hasPermission : permissions && permissions.indexOf(AllPermissionList.ACCESS_ALL_MASTERS) >= 0,
        },
        {
          name: 'Escalations',
          icon: <img src={MasterEscalationsIcon} alt="Escalations" className="menu-icon" />,
          url: APP_BASE_URL + '/masters/escalations',
          hasPermission : permissions && permissions.indexOf(AllPermissionList.ACCESS_ALL_MASTERS) >= 0,
        },
        {
          name: 'Earning Points',
          icon: <img src={MasterEarningPointsIcon} alt="Earning Points" className="menu-icon" />,
          url: APP_BASE_URL + '/masters/earning-points',
          hasPermission : permissions && permissions.indexOf(AllPermissionList.ACCESS_ALL_MASTERS) >= 0,
        },
        {
          name: 'Manage Parameters',
          icon: <img src={MasterParametersIcon} alt="Manage Parameters" className="menu-icon" />,
          url: APP_BASE_URL + '/masters/parameters',
          hasPermission : permissions && permissions.indexOf(AllPermissionList.ACCESS_ALL_MASTERS) >= 0,
        },
        {
          name: 'Coupons',
          icon: <img src={MasterCouponsIcon} alt="Coupons" className="menu-icon" />,
          url: APP_BASE_URL + '/masters/coupons',
          hasPermission : permissions && permissions.indexOf(AllPermissionList.ACCESS_ALL_MASTERS) >= 0,
        },
        {
          name: 'Roles and Permissions',
          icon: <img src={MasterRolesIcon} alt="Roles and Permissions" className="menu-icon" />,
          url: APP_BASE_URL + '/masters/roles-permissions',
          hasPermission : permissions && permissions.indexOf(AllPermissionList.ACCESS_ALL_MASTERS) >= 0,
        },
      ],
    },
  ]

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {/* <h3>Email PMS v.{appConfig.version}</h3>
           */}
           <Typography variant="h3" component="h3" style={{textAlign:"center", fontSize: 20, margin: "0 auto"}}>
              Email PMS v.{appConfig.version}
          </Typography>
        </DrawerHeader>
        <Divider />
        <List>
          {MENU.map((menu: any, index: number) => (
            <SideBarMenuItem key={index} item={menu} menuOpen={open ?? true} />
          ))}
        </List>
      </Drawer>
    </>
  )
}

export default AppSidebar
