import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { observer } from 'mobx-react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import CategoryRoundedIcon from '../../../../../assets/images/category.png';
import SubjectRoundedIcon from '../../../../../assets/images/options.png';
  
const tabRoutes = [
  { label: 'Categories', value: '/masters/escalations/catgories', icon:<img src={CategoryRoundedIcon} alt='' className='list-icon'/> },
  { label: 'Sub-Categories', value: '/masters/escalations/subcategories', icon:  <img src={SubjectRoundedIcon} alt=''  className='list-icon'/>},
  // Add more tabs if needed
];

const Escaltion = observer(() => {
    const location = useLocation();
    const activeTabValue = tabRoutes.find(tab => location.pathname.startsWith(tab.value))?.value;

    return (
          <>
            <h1>Escalation</h1>
            <Box sx={{ width: '100%' }}>
            <Tabs value={activeTabValue} aria-label="icon label tabs example"  sx={{
            bgcolor: '#5c5c5c',
          }}>
              {tabRoutes.map(tab => (
                <Tab
                  key={tab.value}
                  icon={tab.icon}
                  label={tab.label}
                  value={tab.value}
                  component={Link}
                  to={tab.value}
                  sx={{
                    color: '#ffff',
                  }}
                />
              ))}
            </Tabs>
            </Box>
            <Outlet />
          </>
    )
})

export default Escaltion