import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { ClientListProps } from './ClientInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import RootStore from '../RootStore/RootStore'
import { CHANGE_STATUS } from '../../config/Constant'

export default class ClientStore {
  private rootstore: RootStore
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: ClientListProps[]
  editValues = null
  deleteValues = null

  constructor() {
    this.rootstore = new RootStore()
    makeAutoObservable(this)
  }

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent) => {
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request).then((data) => {
          params.success({
            rowData: data.clients.rows,
            rowCount: data.clients.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  setDeleteValues = (data: any) => {
    this.deleteValues = data
  }

  setEditValues = (data: any) => {
    this.editValues = data
  }

  // Setter Functions
  private setListData = (value?: ClientListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
    try{
      this.setListData([])
      const { data } = await axios.post(API_URL.CLIENT.LIST, payload)
      return Promise.resolve(data.data)
    } catch(err: any) {
      return Promise.reject(err.response.data)
    }
  }

  public createClient = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.CLIENT.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public getClientFormFieldData = async (): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const {data} = await axios.get(API_URL.CLIENT.CREATE_FORM_LIST_ITEMS)
      let dataObj = {
        data: data
      }
      loadingStore.setAppLoading(false)
      return Promise.resolve(dataObj)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public fetchClient = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.CLIENT.VIEW(_data))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateClient = async (_id: number, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.CLIENT.UPDATE(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public changeStatus = async (_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     _data.action_type = CHANGE_STATUS;
     const { data } = await axios.put(API_URL.CLIENT.CHANGE_STATUS(_data.id),_data)
     this.setupGrid(this.agGridParams)
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }
  public deleteData = async (_id: number): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.delete(API_URL.CLIENT.DELETE(_id))
      this.setupGrid(this.agGridParams)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  // Get client emails
  public getClients = async (_data: any): Promise<any> => {
    try {
      // loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.CLIENT.GET_CLIENTS, _data)
      // loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      // loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  // Get client emails
  public getClientEmails = async (_data: any): Promise<any> => {
    try {
      // loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.CLIENT.GET_CLIENT_EMAILS, _data)
      // loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      // loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  // Get client emails
  public getClientBillingEmails = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.CLIENT.GET_BILLING_EMAILS, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  // fetch client summary 
  public fetchClientSummary = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.CLIENT.CLIENT_SUMMARY(_data))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
}
