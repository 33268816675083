import { createTheme } from "@mui/material/styles"

// default theme style settings goes here
export const theme = createTheme({
    palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: '#fcb900',
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          light: '#0066ff',
          main: '#0044ff',
          // dark: will be calculated from palette.secondary.main,
          contrastText: '#ffcc00',
        }
      }
})