import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import {
  Card,
  Box,
  CardContent,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  CardActions,
  CircularProgress,
} from '@mui/material'
import * as uuid from 'uuid'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import OrderJobPartial from './OrderJobPartial'
import useStore from '../../../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../../../config/Toaster'
import debounce from '../../../../../../components/utilities/Debounce'
import AlertComponent, { AlertData } from '../../../../../../components/utilities/AlertComponent'
import { BaseResponse } from '../../../../../../interfaces/ApiResponse'
import { CURRENCIES, DEBOUNCE_AFTER_CHARS, DEFAULT_CURRENCY, dateTimeFormat } from '../../../../../../config/Constant'
import LoadingButton from '@mui/lab/LoadingButton'
const OrderDetail = observer(() => {
  const uuidValue = () => uuid.v4()
  const { ORDER, USER, QUOTE } = useStore()
  const { id } = useParams()
  const { fetchOrder, updateOrder, setCurrentOrderStatusValue, setCurrentOrderIDValue } = ORDER
  const { isAttributePriceChanging } = QUOTE

  // Client Email
  const [loading, setLoading] = useState<boolean>(false)
  const [currencyMenuItems, ] = useState<any>(CURRENCIES)
  const [currency, setCurrency] = useState<any>(DEFAULT_CURRENCY)
  const [reviewByOptions, setReviewByOptions] = useState<any>([])
  const { getUserList } = USER
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)

  const validationSchema = Yup.object().shape({
    currency: Yup.string().required('Currency is required'),
    final_amount: Yup.number().required(
      'Final order amount is required',
    ).typeError('Please enter valid amount').min(0, "Amount shouldn't be less than 0"),
    reviewed_by: Yup.array().nullable(),
    total_amount: Yup.number().nullable(),
    final_discount: Yup.number().required('Unique templates is required').min(0, "Discount shouldn't be less than 0").typeError('Please enter valid discount'),
      jobs: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Name is required').matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
          job_type: Yup.string().required('Job Type is required'),
          service: Yup.string().required('Service is required'),
          timeslot_id: Yup.string().required('Time slot is required'),
          layout_id: Yup.string().required('Layout is required'),
          addons: Yup.array().min(0, 'Please select at least one addons'), // (min => 0 === nullable) here
          unique_templates: Yup.number().required('Unique templates is required').typeError('Please enter valid value').min(1, "Minimum 1 template is required"),
          integrations: Yup.array().min(0, 'Please select at least one integration'),
          integration_setups: Yup.array().nullable(),
          adaptation_type: Yup.string().nullable(),
          adaptation_templates: Yup.number()
          .nullable()
          .typeError("Please enter valid value")
          .min(0, "Value shouldn't be less than 0")
          .test('conditional-validation', 'Adaptation quantity is required and should be greater than 0', function (
            value: any,
          ) {
            const adaptationType = this.parent.adaptation_type;
            if (adaptationType !== "" && (!value || value < 0 || value === undefined || value === null)) {
              return false
            }
            return true
          }),
          test_report_id: Yup.string().required('Email test report is required'),
          frameworks: Yup.array().min(0, 'Please select at least one framework'),
          design_amount: Yup.number().required('Design amount is required',
          ).typeError('Please enter valid design amount').min(0, "Design amount shouldn't be less than 0"),
          coding_amount: Yup.number().required('Develoment amount is required',
          ).typeError('Please enter valid Develoment amount').min(0, "Develoment amount shouldn't be less than 0"),
          est_delivery_datetime: Yup.string().required('Estimated delivery date time is required'),
          notes: Yup.string().required('Notes is required'),
        }),
    )
  })

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      currency: DEFAULT_CURRENCY,
      reviewed_by: [],
      final_amount: 0,
      final_discount: 0,
      jobs: [],
    },
  })

  const {
    fields: jobsFields,
    append: appendJobsFields,
    remove: removeJobsFields,
  } = useFieldArray<any>({
    control,
    name: 'jobs',
  })

  const setInitialFormValues = async (_quoteData: any) => {
    try{
    setValue('currency', _quoteData?.currency || '')
    setCurrency(_quoteData?.currency || '')
    setValue('total_amount', (_quoteData?.final_amount - _quoteData?.final_discount) || 0)
    setValue('final_amount', _quoteData?.final_amount || 0)
    setValue('final_discount', _quoteData?.final_discount || 0)
    setValue('reviewed_by', _quoteData?.reviewed_by || [])
    setReviewByOptions(_quoteData?.reviewed_by)

    //set order id and status in store value
    setCurrentOrderIDValue(_quoteData?.order_id)
    setCurrentOrderStatusValue(_quoteData?.order_status) 

    // populate jobs details
    if (_quoteData?.jobs && _quoteData?.jobs.length > 0) {
      _quoteData?.jobs.forEach((job: any, index: number) => {
        let jobObj = {
          quote_job_id: job?.quote_job_id || "",
          order_job_id: job?.order_job_id || "",
          name: job?.project_name || '',
          job_type: job?.job_type || '',
          service: job?.service || '',
          timeslot_id: job?.timeslot_id || 0,
          layout_id: job?.layout_id || 0,
          addons: job?.addons || [],
          unique_templates: job?.unique_templates || '',
          integrations: job?.integrations || [],
          integration_setups: job?.integration_setups || [],
          adaptation_type: (job?.adaptation_type === null) ? "" : (job?.adaptation_type === "0" ? "" : job?.adaptation_type),
          adaptation_templates: job?.adaptation_templates || 0,
          test_report_id: job?.test_report_id || '',
          frameworks: job?.frameworks || [],
          est_delivery_datetime: job?.est_delivery_datetime || '',
          design_amount: job?.design_amount || '',
          coding_amount: job?.coding_amount || '',
          notes: job?.notes || '',
        }
        appendJobsFields(jobObj)
      })
    }
    }catch(err: any){
      console.error(err);
    }
    return true
  }


  const fetchOrderData = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchOrder(id)
      if (resData.error === false) {
        await setInitialFormValues(resData?.data?.order)
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  // Fetch review by
  const fetchReviewByCall = async (newValue: string) => {
    setLoading(true)
    try {
      if (newValue.length >= DEBOUNCE_AFTER_CHARS) {
        const data: any = { user: newValue }
        const res = await getUserList(data)
        await setReviewByOptions(res.data.reporting_managers)
        await setLoading(false)
      } else {
        setReviewByOptions([]) // Clear options if input length is less than 3
        await setLoading(false)
      }
    } catch (err) {
      await setLoading(false)
      console.error(err)
    }
  }

  const debouncedfetchReviewByCall = debounce(fetchReviewByCall, 1000)
  const handleInputReviewBy = async (newValue: any) => {
    debouncedfetchReviewByCall(newValue)
  }

  useEffect(() => {
    fetchOrderData(id)
  }, [id])

  const handleAmountChange = (_data: any) => {
    let total_amount = 0;
    _data?.jobs?.forEach((job: any) => {
      total_amount += parseFloat(job?.design_amount) + parseFloat(job?.coding_amount);
    });
    // total amount = design amount + coding amount
    setValue(`total_amount`, Math.floor(total_amount))

    // final amount = total amount - final discount
    let final_discount = _data?.final_discount
    const finalAmount = total_amount - final_discount; // Subtract discount from total amount
    setValue(`final_amount`, Math.floor(finalAmount))
    trigger(`final_amount`)
  };

  // call useEffect once final discount or design/coding amount change
  useEffect(() => {
    handleAmountChange(watch()); 
    // used job 0 to 4 considering max 5 jobs
  }, [
      watch(`final_discount`),
      watch(`jobs.0.design_amount`),
      watch(`jobs.0.coding_amount`),
      watch(`jobs.1.design_amount`),
      watch(`jobs.1.coding_amount`),
      watch(`jobs.2.design_amount`),
      watch(`jobs.2.coding_amount`),
      watch(`jobs.3.design_amount`),
      watch(`jobs.3.coding_amount`),
      watch(`jobs.4.design_amount`),
      watch(`jobs.4.coding_amount`),
  ]);

  const onSubmit = async (_data: any) => {
   
    try {
      _data.is_fof = _data.is_fof === true ? 1 : 0

      if (_data?.jobs && _data?.jobs.length > 0) {
        Array.from(_data?.jobs).forEach((job: any, index: number) => {
          _data.jobs[index].est_delivery_datetime = dayjs(
            job?.est_delivery_datetime,
          ).format(dateTimeFormat)
          _data.jobs[index].order_job_id = job?.order_job_id
        })
      }

      const resData: any = await updateOrder(id, _data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        // navigate(0)
      } else {
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: resData.message, data: resData.data});
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err;
      window.scrollTo({top: 0, behavior: 'smooth' });
      setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      toast.error(err.message, Toaster)
      if (err?.data?.exist_email_ids) {
        toast.error(err?.data?.exist_email_ids, Toaster)
      }
    }
  }


  return (
    <>
      <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card sx={{ maxWidth: '100%' }} variant='outlined'>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <CardContent>
              
              <Grid
                sx={{ px: 2 }}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 3, sm: 6, md: 12 }}
              >
                {/****** Box *****/}

                <Grid item xs={3}>
                  <Controller
                    name="currency"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        disabled={true}
                        {...field}
                        id="outlined-select-currency"
                        select
                        label="Currency"
                        placeholder="Currency"
                        fullWidth
                        helperText={errors.currency?.message}
                        error={errors.currency ? true : false}
                        onChange={(e: any) => {
                          field.onChange(e)
                          setCurrency(e?.target?.value || "")
                        }}
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Currency
                        </MenuItem>
                        {currencyMenuItems &&
                          currencyMenuItems.map((e: any) => (
                            <MenuItem key={uuidValue()} value={e.label}>
                              {e.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  {jobsFields.map((item, index) => {
                    return (
                      <OrderJobPartial
                        key={index}
                        id={index}
                        errors={errors}
                        setValue={setValue}
                        control={control}
                        trigger={trigger}
                        removeJobsFields={removeJobsFields}
                        watch={watch}
                        currency={currency}
                      />
                    )
                  })}
                </Grid>

                {/* <Grid container item justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  startIcon={<AddCircleIcon />}
                  variant="contained"
                  color="success"
                  onClick={() => 
                    appendJobsFields({
                    })
                  }>
                  Create New Job
                </Button>
              </Grid> */}

                {/****** Box *****/}
                <Grid item xs={12}>
                  <Controller
                    name="reviewed_by"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        multiple
                        {...field}
                        disabled={true}
                        options={reviewByOptions}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onKeyUp={(e: any) =>
                          handleInputReviewBy(e.target.value)
                        }
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                        }}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Review By"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="total_amount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        disabled={true}
                        fullWidth
                        type="text"
                        id="total_amount"
                        label="Total Order Amount"
                        variant="outlined"
                        {...field}
                        InputProps={{
                          inputProps: { min: 0 }, 
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="final_amount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="text"
                        id="final_amount"
                        label="Final Order Amount"
                        variant="outlined"
                        helperText={errors.final_amount?.message}
                        error={errors.final_amount ? true : false}
                        {...field}
                        InputProps={{
                          inputProps: { min: 0 }, 
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="final_discount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="text"
                        id="final_discount"
                        label="Discount"
                        variant="outlined"
                        helperText={errors.final_discount?.message}
                        error={errors.final_discount ? true : false}
                        {...field}
                        InputProps={{
                          readOnly: true,
                          inputProps: { min: 0 }, 
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ mb: 1 , ml: 3}}>
              <LoadingButton type="submit" variant="contained" size="medium" loading={isAttributePriceChanging}>
              Save
            </LoadingButton>
            </CardActions>
          </Box>
        </Card>
      </LocalizationProvider>
    </>
  )
})

export default OrderDetail
