import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PageNotFound: React.FC = () => {

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        bgcolor: 'primary.main',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" component="h1" sx={{ mt: 2 }}>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        The page you are looking for might have been removed or does not exist.
      </Typography>
      <Link to="/dashboard" style={{ textDecoration: 'none', marginTop: '20px' }}>
            <Button variant="outlined" color="secondary">
              Go to Dashboard
            </Button>
      </Link>
    </Box>
  );
};

export default PageNotFound;