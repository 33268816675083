import { Box, Button, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper'
import useStore from '../../../../../../store'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const SubCategoryList = observer(() => {
  const { ESCALATION_CATEGORY } = useStore()
  const navigate = useNavigate()
  const { fetchSubCategoryList } = ESCALATION_CATEGORY
  const [rowData, setRowData] = useState<any>([])

  const [columnDefs] = useState<any>([
    {
      headerName: 'ID',
      field: 'id',
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Sub-Category Name',
      field: 'name',
    },
    {
      headerName: 'Category',
      field: 'escalation_category',
    },
  ])

  useEffect(() => {
    fetchSubCategoryList()
      .then((data: any) => {
        setRowData(data)
      })
      .catch((error: any) => {
        setRowData([])
      })
  }, [fetchSubCategoryList])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        spacing={2}
        sx={{ my: '20px' }}
      >
        <Box></Box>
        <Box sx={{ my: '20px' }}>
          <Button
            onClick={() => navigate('create')}
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            NEW SUB-CATEGORY
          </Button>
        </Box>
      </Stack>
      <Box sx={{}}>
        <AgGridWrapper
          type="clientSide"
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </Box>
    </>
  )
})

export default SubCategoryList
