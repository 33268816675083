import React, { useState } from 'react'
import useStore from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '../../../../assets/images/note.png'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'
import AllPermissionList from '../../../../config/AllPermissionList'
import Stack from '@mui/material/Stack';

const UserList = () => {
  const { USER, AUTH } = useStore()
  const navigate = useNavigate()
  const { setupGrid, setPageSize, perPage } = USER
  const { permissions } = AUTH


  // const changeStatusHandle = async (_data:any) => {
  //   try {
  //     const resData: any = await changeStatus(_data)
  //     if (resData.error === false) {
  //       toast.success(resData.message, Toaster)
  //     } else {
  //       toast.error(resData.message, Toaster)
  //     }
  //   } catch (err: any) {
  //     console.error(err)
  //     toast.error(err.message, Toaster)
  //   }
	// }

  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip title="Edit" sx={{ display : (permissions && permissions.indexOf(AllPermissionList.EDIT_USER) >= 0) ? "inline-flex" : "none" }}>
          <IconButton
            onClick={() => {
              navigate('../edit/' + params?.data?.enc_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  // const statusColumnRenderer = (params: any) => {
  //   const label = { inputProps: { 'aria-label': `Status Switch` } }
  //   return (
  //     <>
  //       <Switch 
  //         {...label}
  //          defaultChecked={params.value === 1}
  //          onChange= {() => changeStatusHandle({id:params?.data?.enc_id,status:params.value === 1 ? 0 : 1})}
  //          />
  //     </>
  //   )
  // }

  const [columnDefs, ] = useState<any>([
    {
      headerName: 'Name',
      field: 'user_name',
    },
    {
      headerName: 'Employee ID',
      field: 'employee_id',
    },
    {
      headerName: 'Email',
      field: 'email',
    },
    {
      headerName: 'Role',
      field: 'role_name',
    },
    {
      headerName: 'Department',
      field: 'department_name',
    },
    {
      headerName: 'Shift',
      field: 'shift_name',
    },
    { 
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter', 
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false, 
      },
      pinned: 'right'
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable : false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 70,
      minWidth: 80,
      pinned: 'right'
    },
  ])

  // const openDeleteModal: any = (data: any) => {
  //   setDeleteValues(data)
  //   setDeleteModal(true)
  // }

  // const closeDeleteModal = () => {
  //   setDeleteModal(false)
  // }

  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      spacing={2}
      sx={{ mb: '20px' }}
    >     <h1>Users</h1>
          <Box sx={{ mb: '20px' }}>
            <Button
              onClick={() => navigate('../create')}
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{ display : (permissions && permissions.indexOf(AllPermissionList.ADD_QUOTE) >= 0) ? "inline-flex" : "none" }}
            >
              NEW USER
            </Button>
          </Box>
    </Stack>


      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
      />
      <Box className="record-per-page-filter" sx={{ mt: '20px', ml: '-10px' }}>
        <RecordPerPage
          style={{ width: "150px" }}
          defaultValue={`${perPage}`}
          onChange={(e:any)=> setPageSize(e.target.value)}
        />
      </Box>
      {/* <UserDelete visible={deleteModal} close={closeDeleteModal} /> */}
    </>
  )
}

export default UserList
