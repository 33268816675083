import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AlertTitle } from "@mui/material";

export interface AlertData{
  severity: "success" | "info" | "warning" | "error";
  message: string,
  data?: any
  
}
interface AlertComponentProps {
  onClose(): void,
  severity: "error" | "info" | "success" | "warning",
  message: string,
  data?: any
}

const textUpperCase = (e: string) => {
  return e[0].toUpperCase() + e.slice(1)
}

const removeUnderscore = (e: string) => {
  return textUpperCase(e.replace('_', " "))
}

const AlertComponent:React.FC<AlertComponentProps> = ({
  onClose, 
  severity = "success",
  message,
  data = {}
}) => {
  return (
    <Box sx={{ width: "100%" , mt : "4px" }}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, py:3 }}
        >
          <AlertTitle>
            <strong>{textUpperCase(severity)}</strong>
          </AlertTitle>
          <p> {message}</p>
          <ul>
            {data.errors ? Object.keys(data.errors).map((e: string, i) => {
              return <li key={i}><b>{removeUnderscore(e)}</b> : {data.errors[e]} </li>
            }) : Object.keys(data).map((e: string, i) => {
              return <li key={i}><b>{removeUnderscore(e)}</b> : {data[e]} </li>
            })}
          </ul>
        </Alert>
    </Box>
  );
};

export default AlertComponent;
