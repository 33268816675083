import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import * as uuid from 'uuid'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import { CLIENT_TYPES } from '../../../../config/Constant'
import AlertComponent, { AlertData } from '../../../../components/utilities/AlertComponent'

const ClientCreate = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { CLIENT } = useStore()
  const { getClientFormFieldData, createClient } = CLIENT
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)

  const validationSchema = Yup.object().shape({
    client_name: Yup.string().required('Client name is required').matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
    company_name: Yup.string().required('Company name is required'),
    client_type: Yup.string().required('Client Type is required'),
    geo_name: Yup.string(),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Invalid phone number').nullable(),
    client_emails: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().required("This field is required").email('Invalid email address'),
        client_id:  Yup.string().nullable(),
      })
    ),
    client_billing_emails: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().required("This field is required").email('Invalid email address'),
        client_id:  Yup.string().nullable(),
      })
    )
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      client_name: '',
      company_name: '',
      client_type: '',
      geo_name: '',
      phone: '',
      client_emails: [{ email : "" }],
      client_billing_emails: [{ email : "" }],
    },
  })

  const {
    fields: clientEmailsFields,
    append: appendClientEmails,
    remove: removeClientEmails,
  } = useFieldArray<any>({
    control,
    name: 'client_emails',
  })

  const {
    fields: billingEmailsFields,
    append: appendBillingEmails,
    remove: removeBillingEmails,
  } = useFieldArray<any>({
    control,
    name: 'client_billing_emails',
  })

  const [geoList, setGeoList] = useState<any>([])

  const setFormFields = async () => {
    try {
      const fieldsData: any = await getClientFormFieldData()
      await setGeoList(fieldsData?.data?.data?.geos)
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  useEffect(() => {
    setFormFields()
  }, [])
  const onSubmit = async (_data: any) => {
    try {
      _data.client_emails = _data.client_emails.map((item:any) => item.email);
      _data.client_billing_emails = _data.client_billing_emails.map((item:any) => item.email);
      const resData: any = await createClient(_data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        navigate('../list')
      } else {
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: resData.message, data: resData.data});
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      toast.error(errData.message, Toaster)
      if (errData?.data?.exists_email) {
        toast.error(errData?.data?.exists_email, Toaster)
      }
      window.scrollTo({top: 0, behavior: 'smooth' });
      setAlertMessage({severity:'error', message: errData.message, data: errData.data});

    }
  }

  return (
    <>
    <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Add Client
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="client_name"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      fullWidth
                      id="client_name"
                      label="Client Name"
                      variant="outlined"
                      helperText={errors?.client_name?.message}
                      error={errors.client_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="company_name"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      fullWidth
                      id="company_name"
                      label="Company Name"
                      variant="outlined"
                      helperText={errors.company_name?.message}
                      error={errors.company_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      fullWidth
                      id="phone"
                      label="Phone"
                      variant="outlined"
                      helperText={errors.phone?.message}
                      error={errors.phone ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="client_type"
                  control={control}
                  defaultValue={'1'} // Set default value for this field
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      id="client_type"
                      select
                      label="Client Type"
                      fullWidth
                      helperText={errors.client_type?.message}
                      error={errors.client_type ? true : false}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select Client Type
                      </MenuItem>
                      {CLIENT_TYPES &&
                        CLIENT_TYPES.map((type: any) => (
                          <MenuItem key={uuidValue()} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="geo_name"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      id="geo_name"
                      select
                      label="GEO"
                      fullWidth
                      helperText={errors.geo_name?.message}
                      error={errors.geo_name ? true : false}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select GEO
                      </MenuItem>
                      {geoList &&
                        geoList.map((geo: any) => (
                          <MenuItem key={uuidValue()} value={geo.label}>
                            {geo.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid container item xs={12}>
                {clientEmailsFields.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <Grid
                        item
                        xs={12}
                        sx={{ mr: '80px', mb: '8px', mt: '8px' }}
                      >
                        <Controller
                          name={`client_emails.${index}.email`}
                          control={control}
                          render={({ field }: any) => (
                            <TextField
                              fullWidth
                              id={`client_emails.${index}.email`}
                              label={`Client Email ${(index + 1) === 1 ? "" : index + 1 } `}
                              variant="outlined"
                              helperText={
                                errors?.client_emails && errors?.client_emails[index]
                                ? errors?.client_emails[index].email.message
                                : ''
                              }
                              error={!!errors?.client_emails?.[index]?.email.message}
                              {...field}
                            />
                          )}
                        />
                        <Tooltip
                          title="Remove"
                          sx={{ visibility: index === 0 ? 'hidden' : '' }}
                        >
                          <IconButton onClick={() => removeClientEmails(index)}>
                            <DeleteForeverRoundedIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </div>
                  )
                })}
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  startIcon={<AddCircleIcon />}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    appendClientEmails({ email : "" })
                  }}
                >
                  Add Client Emails
                </Button>
              </Grid>
              <Grid container item xs={12}>
                {billingEmailsFields.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <Grid
                        item
                        xs={12}
                        sx={{ mr: '80px', mb: '8px', mt: '8px' }}
                      >
                        <Controller
                          name={`client_billing_emails.${index}.email`}
                          control={control}
                          render={({ field }: any) => (
                            <TextField
                              fullWidth
                              id={`client_billing_emails.${index}.email`}
                              label={`Billing Email ${(index + 1) === 1 ? "" : index + 1 } `}
                              variant="outlined"
                              helperText={
                                errors?.client_billing_emails && errors?.client_billing_emails[index]
                                ? errors?.client_billing_emails[index].email.message
                                : ''
                              }
                              error={!!errors?.client_billing_emails?.[index]?.email.message}
                              {...field}
                            />
                          )}
                        />
                        <Tooltip
                          title="Remove"
                          sx={{ visibility: index === 0 ? 'hidden' : '' }}
                        >
                          <IconButton
                            onClick={() => removeBillingEmails(index)}
                          >
                            <DeleteForeverRoundedIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </div>
                  )
                })}
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  startIcon={<AddCircleIcon />}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    appendBillingEmails({ email : "" })
                  }}
                >
                  Add Billing Emails
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={() => navigate('../list')}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default ClientCreate
