import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  FormControl,
  MenuItem,
  TextField
} from '@mui/material'
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material'
import useStore from '../../../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../../../config/Toaster'
import { AgGridReact } from 'ag-grid-react'
import {
  EMAIL_ONLY_CODING,
} from '../../../../../../config/Constant'
import CancelOrderModal from '../CancelOrderModal'
import CancelTemplateModal from './CancelTemplateModal'
import CancelOrderComponent from '../CancelOrderComponent'

const OrderTemplatesSelected = observer(() => {
  const { ORDER } = useStore()
  const { id } = useParams()
  const { fetchOrder, setStatusValue, updateOrderTemplatesStatus, fetchOrderTemplateStatus, setTemplatesStatusValue, setRemarks, setCurrentOrderStatusValue, setCurrentOrderIDValue } = ORDER
  const [cancelledStatusModal, setCancelledStatusModal] = useState<boolean>(false)
  const [templateCancelledStatusModal, setTemplateCancelledStatusModal] = useState<boolean>(false)
  const [jobs, setJobs] = useState<any>([])
  const [orderTemplateStatusArray, setOrderTemplateStatusArray] = useState<any>([])
  const [selectedStatus, setSelectedStatus] = useState<any>('');
  const [selectedTemplates, setSelectedTemplates] = useState<any>([]);

  const cancelOrderButtonHandle = () => {
    setRemarks("")
      setStatusValue({
        id : id, order_status : "Cancelled"
      })
      setCancelledStatusModal(true)
  }

  const closeCancelledStatusModal = () => {
    setCancelledStatusModal(false)
    setRemarks("")
  }

  const closeTemplateCancelledStatusModal = () => {
    setTemplateCancelledStatusModal(false)
  }

  // if status has been changed / api called then reset the states
  const templateStatusChanged = () => {
    setSelectedTemplates([])
    setSelectedStatus("")
  }

   const onSelectionChanged = (event: any) => {
    let selectedTemplateIds = event.api.getSelectedRows().map((row: any) => ({ order_job_id: row.order_job_id, order_template_id: row.order_template_id }))
    setSelectedTemplates(selectedTemplateIds)
  };

  const handleStatusChange = async (e: any, job_id: any, service: any) => {
      try {
        if(selectedStatus === "" || selectedStatus == null) {
          toast.error("Please select status", Toaster)
          return;
        }
        if(selectedTemplates.length <= 0){
          toast.warn("Please select at least one template", Toaster)
          return;
        }

        const postData = {
          order_job_id : job_id,
          service : service,
          order_templates: selectedTemplates,
          template_status_id: selectedStatus
        }

        let statusString = orderTemplateStatusArray.find((item: any) => item.id === selectedStatus);
        statusString =  statusString ? statusString.label : '';
        if(statusString === "Cancelled"){
            setTemplatesStatusValue(postData)
            setTemplateCancelledStatusModal(true)
            return;
        }
        const resData: any = await updateOrderTemplatesStatus(id, postData)
        if (resData.error === false) {
          await fetchOrderData(id)
          toast.success(resData.message, Toaster)
          templateStatusChanged()
        } else {
          toast.error(resData.message, Toaster)
        }
      } catch (err: any) {
        console.error(err)
        toast.error(err.message, Toaster)
      }
  };

  const setInitialFormValues = async (_orderData: any) => {
    // order object fields
    try{
      let orderData = _orderData.order

      //set order id and status in store value
      setCurrentOrderIDValue(orderData?.order_id)
      setCurrentOrderStatusValue(orderData?.order_status) 

      const updatedJobs = orderData?.jobs.map((job: any) => {
        const updatedTemplates = job.templates.map((template: any) => {
          return {
            ...template,
            service: job.service || '',
            assign_developer: template?.assign_developer?.label || '',
          }
        })

        let columnDefs = []
        if (job.service === EMAIL_ONLY_CODING) {
          columnDefs = [
            {
              headerCheckboxSelection: true, // Enable "Select All" checkbox in header
              checkboxSelection: true, // Enable checkboxes for individual rows
              width: 50, // Adjust width as needed
            },
            {
              headerName: 'Template ID',
              field: 'order_template_id',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
            },
            {
              headerName : "Type",
              field: 'template_type',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
              width: 150, // Adjust width as needed
            },
            {
              headerName : "Developer",
              field: 'assign_developer',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
            },
            {
              headerName : "Updated By",
              field: 'updated_by_name',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
            },
            {
              headerName : "Updated At",
              field: 'updated_date',
              // filter: 'agDateColumnFilter',
              // floatingFilterComponentParams: {
              //   suppressFilterButton: true, 
              // },
              // floatingFilter: true,
              // suppressMenu: true, 
            },
            {
              headerName : "Status",
              field: 'template_status.label',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
              width: 150, // Adjust width as needed
            }
          ]
        } else {
          columnDefs = [
            {
              headerCheckboxSelection: true, // Enable "Select All" checkbox in header
              checkboxSelection: true, // Enable checkboxes for individual rows
              width: 50, // Adjust width as needed
            },
            {
              headerName: 'Template ID',
              field: 'order_template_id',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
            },
            {
              headerName : "Type",
              field: 'template_type',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
              width: 150, // Adjust width as needed
            },
            {
              headerName : "Developer",
              field: 'assign_developer',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
            },
            {
              headerName : "Updated By",
              field: 'updated_by_name',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
            },
            {
              headerName : "Updated At",
              field: 'updated_date',
              // filter: 'agDateColumnFilter',
              // floatingFilterComponentParams: {
              //   suppressFilterButton: true, 
              // },
              // floatingFilter: true,
              // suppressMenu: true, 
            },
            {
              headerName : "Status",
              field: 'template_status.label',
              filter: 'agTextColumnFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true, 
              },
              floatingFilter: true,
              suppressMenu: true, 
              width: 150, // Adjust width as needed
            }
          ]
        }
        // Return the job with the updated templates array
        return {
          ...job,
          columnDefs: columnDefs,
          templates: updatedTemplates,
          selectedRows: []
        }
      })
      setJobs(updatedJobs || [])
    }catch(err: any){}
  }

  const fetchOrderData = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchOrder(id)
      if (resData.error === false) {
        await setInitialFormValues(resData?.data)
      } else {
        toast.error(resData.message, Toaster)
      }

      // TODO : call only single api and set templates in above api
      const orderTemplateStatusRes = await fetchOrderTemplateStatus(_id)
      if (orderTemplateStatusRes.error === false) {
        await setOrderTemplateStatusArray(orderTemplateStatusRes?.data.orderTemplateStatus)
      } else {
        toast.error(orderTemplateStatusRes.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  useEffect(() => {
    fetchOrderData(id)
  }, [id])

  return (
    <>
      <Box>
        {/* Jobs Accordian */}

        {jobs.map((item: any, index: any) => {
          return (
            <Box key={index} sx={{ p: '20px' }}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="job-content"
                  id={`job-header-${index}`}
                  sx={{
                    margin: '0px 0px',
                    bgcolor: '#b3b1b1',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>Job ({item.order_job_id}) ({item?.service})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ p: '20px' }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <TextField
                          select
                          value={selectedStatus}
                          placeholder='Select status'
                          label="Select status"
                          onChange={(e) => setSelectedStatus(e.target.value)}
                          SelectProps={{
                            MenuProps: {
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem value={""}>Select status</MenuItem>
                          {orderTemplateStatusArray.map((e:any, index: number) => (
                              <MenuItem key={index} value={e.id}>{e.label}</MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e:any) => handleStatusChange(e, item?.order_job_id, item?.service  )}
                      >
                        Change Status
                      </Button>
                    </Grid>
                  </Grid>
                    <Box className="ag-theme-alpine ag-grid-wrapper" sx={{ mt: "20px" }}>
                      <AgGridReact
                        // {...Config.grid.local}
                        rowData={item.templates}
                        columnDefs={item.columnDefs}
                        rowSelection="multiple"
                        onSelectionChanged={onSelectionChanged}
                        suppressRowClickSelection={true}
                      />
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )
        })}
        {/* Jobs Accordian*/}

        <CancelOrderComponent cancelOrderButtonHandle={cancelOrderButtonHandle}/>
        <CancelOrderModal visible={cancelledStatusModal} close={closeCancelledStatusModal} />
        <CancelTemplateModal visible={templateCancelledStatusModal} close={closeTemplateCancelledStatusModal} templateStatusChanged={templateStatusChanged} />
      </Box>
    </>
  )
})

export default OrderTemplatesSelected
