import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { QuoteListProps } from './QuoteInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'
import RootStore from '../RootStore/RootStore'
import { CHANGE_STATUS } from '../../config/Constant'

export default class QuoteStore {
  private rootstore: RootStore
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: QuoteListProps[]
  editValues = null
  statusValues = { id : 0, quote_status : "" }
  deleteValues = null
  isAttributePriceChanging: boolean = false

  constructor() {
    this.rootstore = new RootStore()
    makeAutoObservable(this)
  }

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent) => {
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request).then((data) => {
          params.success({
            rowData: data.quotes.rows,
            rowCount: data.quotes.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  setDeleteValues = (data: any) => {
    this.deleteValues = data
  }

  setStatusValue = (data: any) => {
    this.statusValues = data
  }

  setEditValues = (data: any) => {
    this.editValues = data
  }

  setAttributePriceChanging = (data: any) => {
    this.isAttributePriceChanging = data
  }

  // Setter Functions
  private setListData = (value?: QuoteListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
    try{
      this.setListData([])
      const { data } = await axios.post(API_URL.QUOTE.LIST, payload)
      return Promise.resolve(data.data)
    } catch(err: any) {
      return Promise.reject(err.response.data)
    }
  }

  public createQuote = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.QUOTE.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public fetchQuote = async (_data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.get(API_URL.QUOTE.VIEW(_data))
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public updateQuote = async (_id: number, _data: any): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.put(API_URL.QUOTE.UPDATE(_id), _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }
  public changeStatus = async (_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     _data.action_type = CHANGE_STATUS;
     const { data } = await axios.put(API_URL.QUOTE.CHANGE_STATUS(_data.id),_data)
     this.setupGrid(this.agGridParams)
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     this.setupGrid(this.agGridParams)
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
  }
  public deleteData = async (_id: number): Promise<any> => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.delete(API_URL.QUOTE.DELETE(_id))
      this.setupGrid(this.agGridParams)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }



  // Create Quote Form Data
  public getQuoteFormFieldData =async (): Promise<any> => {
    try{
      loadingStore.setAppLoading(true);
      const resData = await Promise.all([
        await axios.get(API_URL.QUOTE.GET_LIST_ITEMS),
      ]);

      const dataObj:any = {};
      await Promise.all(resData.map(res => {
         switch(res.config.url){
          case API_URL.QUOTE.GET_LIST_ITEMS:
            dataObj.listItems = res.data.data.createFormListItems
            return true
        }
      }))
      loadingStore.setAppLoading(false);
      return Promise.resolve(dataObj);
     }catch(err:any){
      loadingStore.setAppLoading(false);
      return Promise.reject(err.response.data);
     }
  }

  // fetch attribute prices
  public fetchAttriutePrices = async (_data: any): Promise<any> => {
    try {
      this.setAttributePriceChanging(true)
      const { data } =  await axios.post(API_URL.QUOTE.ATTRIBUTE_PRICE, _data)
      this.setAttributePriceChanging(false)
      return Promise.resolve(data)
    } catch (err:any) {
      this.setAttributePriceChanging(false)
      return Promise.reject(err.response.data)
    }
  }
}
