import React from 'react'
import { observer } from 'mobx-react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'

const StatusConfirmModal: React.FC<any> = observer(({ visible, close }) => {
  const { EXTRA_POINT } = useStore()
  const { changeStatus, statusValues } = EXTRA_POINT

  const changeStatusHandle = async () => {
    try {
      let _data: any = statusValues
      const resData: any = await changeStatus(_data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        close()
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      close()
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  return (
    <>
      <div>
        <Dialog
          open={visible}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure you want to change the status to "${statusValues.status}"?`}
          </DialogTitle>
          <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={changeStatusHandle} autoFocus>
              Yes, Change!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
})

export default StatusConfirmModal
