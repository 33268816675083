import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  CardActions,
  Button,
  Grid,
  MenuItem,
} from '@mui/material'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import * as uuid from 'uuid'
import AlertComponent, {
  AlertData,
} from '../../../../../../components/utilities/AlertComponent'
import Toaster from '../../../../../../config/Toaster'
import { BaseResponse } from '../../../../../../interfaces/ApiResponse'
import useStore from '../../../../../../store'
import { DEFAULT_CURRENCY, EMAIL_SERVICES, TIME_SLUG } from '../../../../../../config/Constant'
import { handleUsdPriceChange } from "../handleUSDPriceChangeUtility"

const TimeslotEdit = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { id } = useParams()
  const { TIMESLOT } = useStore()
  const { updateTimeslot, fetchTimeslot } = TIMESLOT
  const [alertMessage, setAlertMessage] = useState<AlertData | null>(null)
  const [exchangeRates, setExchangeRates] = useState<any>(null)

  const validationSchema = Yup.object().shape({
    time: Yup.number()
      .required('Timeslot is required')
      .typeError('Please enter valid Timeslot')
      .positive('Timeslot value must be greater than 0'),
    time_slug: Yup.string().required('Timeslot slug is required'),
    service: Yup.string().required('Service is required'),
    currencies: Yup.array().of(
      Yup.object().shape({
        design_price: Yup.number().nullable().typeError('Please enter valid price').min(0,'Value must be great or equal to 0'),
        coding_price: Yup.number()
          .required()
          .typeError('Please enter valid price')
          .positive('Price value must be greater than 0'),
      }),
    ),
  })

  const {
    handleSubmit,
    setError,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      time: 0,
      time_slug: 'Hrs',
      service: EMAIL_SERVICES ? EMAIL_SERVICES[0].label : "",
      currencies: [],
    },
  })

  const { fields: currencyFields } = useFieldArray<any>({
    control,
    name: 'currencies',
  })


  const fetchTimeslotData = async (id: any) => {
    try {
        const resData: any = await fetchTimeslot(id)
        if (resData.error === false) {
          setValue('time', resData.data.timeslot?.time || 0)
          setValue('time_slug', resData.data?.timeslot?.time_slug || '')
          setValue('service', resData.data?.timeslot?.service || '')

          setValue("currencies", resData?.data?.currencies)
          const currencyConversionRates = resData?.data?.currency_conversion_rates
          setExchangeRates(currencyConversionRates)
        } else {
          setAlertMessage({severity:'error', message: resData.message, data: resData.data});
          toast.error(resData?.message, Toaster)
        }
      } catch (err: any) {
        console.error(err)
        const errData: BaseResponse = err
        toast.error(errData.message, Toaster)
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
  }

  const onSubmit = async (_data: any) => {
      try {
      if(_data.currencies === undefined || _data.currencies === null || _data.currencies.length === 0) {
        toast.error("Currencies values are missing!", Toaster)
         return;
      }  
      const postData = _data
      const resData: BaseResponse = await updateTimeslot(id, postData);
      if (resData.error === false) {
        navigate("../timeslots")
        toast.success(resData?.message, Toaster)
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setAlertMessage({
          severity: 'error',
          message: resData.message,
          data: resData.data,
        })
        toast.error(resData?.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setAlertMessage({
        severity: 'error',
        message: errData.message,
        data: errData.data,
      })
      if (errData.data && errData.data['time']) {
        setError('time', {
          type: 'manual',
          message: errData.data['time'][0],
        })
      }
      toast.error(errData?.message, Toaster)
    }
  }

  useEffect(() => {
    fetchTimeslotData(id)
  }, [id])


  console.error(errors)

  return (
    <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Edit Timeslot
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="time"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      fullWidth
                      id="time"
                      label="Timeslot"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`time_slug`}
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      id={`time_slug`}
                      select
                      label="Time Slug"
                      fullWidth
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      onChange={(e: any) => {
                        field.onChange(e)
                      }}
                    >
                      {TIME_SLUG &&
                        TIME_SLUG.map((slug: any) => (
                          <MenuItem key={uuidValue()} value={slug.name}>
                            {slug.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`service`}
                  control={control}
                  defaultValue={EMAIL_SERVICES ? EMAIL_SERVICES[0].label : ""}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      id={`service`}
                      select
                      label="Service"
                      fullWidth
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      onChange={(e: any) => {
                        field.onChange(e)
                      }}
                    >
                      {EMAIL_SERVICES &&
                        EMAIL_SERVICES.map((service: any) => (
                          <MenuItem key={uuidValue()} value={service.label}>
                            {service.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ px: 10, py: 2 }}>
                  <Grid container spacing={2}>
                    {currencyFields.map(
                      (currency: any, currencyIndex: any) => (
                        <Grid
                          sx={{
                            mb: '4px',
                            mt: '4px',
                            alignItems: 'center',
                          }}
                          container
                          spacing={2}
                          key={currencyIndex}
                        >
                          <Grid item xs={3}>
                            <label
                              htmlFor={`currencies.${currencyIndex}.id`}
                            >
                              {currency.name}
                            </label>
                          </Grid>
                          <Grid item xs={3}>
                            <Controller
                              name={`currencies.${currencyIndex}.design_price`}
                              control={control}
                              render={({ field, fieldState }: any) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  type="number"
                                  id={`${currencyIndex}_design_price_${currency.name}`}
                                  label="Design Price"
                                  variant="outlined"
                                  onChange={(e: any, newVal: any) => {
                                    field.onChange(e)
                                    if (currency.name === DEFAULT_CURRENCY) {
                                      handleUsdPriceChange(
                                        e,
                                        0,
                                        currencyIndex,
                                        'design_price',
                                        setValue,
                                        trigger,
                                        exchangeRates,
                                        1
                                      )
                                    }
                                  }}
                                  error={fieldState.invalid}
                                  helperText={fieldState?.error?.message}
                                  InputProps={{
                                    inputProps: { min: 0 }, 
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Controller
                              name={`currencies.${currencyIndex}.coding_price`}
                              control={control}
                              render={({ field, fieldState }: any) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  type="number"
                                  id={`coding_price_${currencyIndex}_${currency.name}`}
                                  label="Coding Price"
                                  variant="outlined"
                                  onChange={(e: any) => {
                                    field.onChange(e)
                                    if (currency.name === DEFAULT_CURRENCY) {
                                      handleUsdPriceChange(
                                        e,
                                        0,
                                        currencyIndex,
                                        'coding_price',
                                        setValue,
                                        trigger,
                                        exchangeRates,
                                        1
                                      )
                                    }
                                  }}
                                  error={fieldState.invalid}
                                  helperText={fieldState?.error?.message}
                                  InputProps={{
                                    inputProps: { min: 0 }, 
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={(e) => navigate(-1)}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default TimeslotEdit
