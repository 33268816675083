import React, { useState } from 'react'
import { useNavigate , useParams } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AlertComponent, { AlertData } from '../../../components/utilities/AlertComponent'
import useStore from "../../../store"
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseResponse} from '../../../interfaces/ApiResponse'

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters long"),
  confirm_password: Yup.string().required("Confirm Password is required").min(8, "Password must be at least 8 characters long").oneOf([Yup.ref("password")], "Passwords do not match"),
});


export default function ResetPassword() {
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
	const { AUTH } = useStore();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched"
  });
  
  // const [saving, setSaving] = useState<boolean>(false);
  const navigate = useNavigate()
	const { resetPasswordWithToken } = AUTH;

  // use this token to distinguish user and pass in post API
  const { token } = useParams();
  const onSubmit = async (_data: any) => {
    try {
      setAlertMessage(null);	
      _data.reset_token = token;
      const resData: BaseResponse = await resetPasswordWithToken(_data);
        if(resData.error === false){
          setTimeout(() => {
            navigate("/login");
          }, 1000);
          setAlertMessage({
            severity:'success',
            message: resData.message,
            data: resData.data
          });
        }else{
          setAlertMessage({
            severity:'error',
            message: resData.message,
            data: resData.data
          });
        }
      } catch(err:any){
        const errData: BaseResponse = err;
        setAlertMessage({
          severity:'error',
          message: errData.message,
          data: errData.data
        });
      }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockResetOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}  sx={{ mt: 1 }}>
           <TextField
              margin="normal"
              required
              fullWidth
              {...register('password')}
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              helperText={errors.password?.message}
              error={errors.password ? true : false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              {...register('confirm_password')}
              label="Confirm Password"
              type={showConfPassword ? 'text' : 'password'}
              id="confirm_password"
              autoComplete="current-password"
              helperText={errors.confirm_password?.message}
              error={errors.confirm_password ? true : false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfPassword(!showConfPassword)}
                      edge="end"
                    >
                      {showConfPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          
          {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}

        </Box>
      </Box>
    </Container>
  )
}
