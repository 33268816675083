import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '../../../../assets/images/note.png'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import { Stack } from '@mui/material'

const RoleList = observer(() => {
  const { ROLE } = useStore()
  const navigate = useNavigate()
  const { fetchList, changeStatus } = ROLE
  const [rowData, setRowData] = useState<any>([])

  const changeStatusHandle = async (_data: any) => {
    try {
      const resData: any = await changeStatus(_data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        fetchList()
          .then((data: any) => {
            setRowData(data)
          })
          .catch((error: any) => {
            setRowData([])
          })
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  const statusColumnRenderer = (params: any) => {
    const label = { inputProps: { 'aria-label': `Status Switch` } }
    return (
      <>
        <Switch
          {...label}
          defaultChecked={params.value === 1}
          onChange={() =>
            changeStatusHandle({
              id: params.data?.enc_id,
              status: params.value === 1 ? 0 : 1,
            })
          }
        />
      </>
    )
  }

  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              navigate('edit/' + params?.data?.enc_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const [columnDefs] = useState<any>([
    {
      headerName: 'Role Name',
      field: 'name',
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: false,
      filter: false,
      cellRenderer: statusColumnRenderer,
      width: 100,
      minWidth: 100,
      pinned: 'right',
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 100,
      minWidth: 100,
      pinned: 'right',
    },
  ])

  useEffect(() => {
    fetchList()
      .then((data: any) => {
        setRowData(data)
      })
      .catch((error: any) => {
        setRowData([])
      })
  }, [fetchList])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        spacing={2}
        sx={{ my: '20px' }}
      >
        <h1>Roles</h1>
        <Box sx={{ mb: '20px' }}>
          <Button
            onClick={() => navigate('create')}
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            NEW ROLE
          </Button>
        </Box>
      </Stack>
      <AgGridWrapper
        type="clientSide"
        columnDefs={columnDefs}
        rowData={rowData}
      />
    </>
  )
})

export default RoleList
