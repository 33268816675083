import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '../../../../assets/images/note.png'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'
import { toast } from 'react-toastify';
import Toaster from '../../../../config/Toaster'
import StatusConfirmModal from './StatusConfirmModal'
import { DISCUSSION_STATUSES } from '../../../../config/Constant'
import { FormControl, MenuItem, Select, Stack } from '@mui/material'
import AllPermissionList from '../../../../config/AllPermissionList'

const DiscussionList = observer(() => {
  const { DISCUSSION, AUTH } = useStore()
  const navigate = useNavigate()
  const { setupGrid, agGridParams, setStatusValue, setPageSize, perPage } = DISCUSSION
  const {permissions} = AUTH
  const [statusConfirmModal, setStatusConfirmModal] = useState<boolean>(false)

  const changeStatusHandle = (_data: any) => {
    try {
        setStatusValue(_data)
        setStatusConfirmModal(true)
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  const actionColumnRenderer = (params: any) => {
    return (
      <>
      <Box sx={{ display: (params?.data?.discussion_status === DISCUSSION_STATUSES[0]?.name ? "block" : "none" ) }}>
        <Tooltip title="Edit" sx={{ display : (permissions && permissions.indexOf(AllPermissionList.EDIT_DISCUSSION) >= 0) ? "inline-flex" : "none" }}>
          <IconButton
            onClick={() => {
              navigate('../edit/' + params?.data?.enc_discussion_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </Box>
      </>
    )
  }

  const statusColumnRenderer = (params: any) => {

    let STATUSES = DISCUSSION_STATUSES
    let currentStatusIndex = STATUSES.findIndex(status => status.name === params.value);
    
    if(params.value === "Approved"){
      // display only Approved status
      STATUSES = STATUSES.filter(status => status.name === params.value);
    }else{
      // hide previous option of status with slice based on current status
      STATUSES = STATUSES.slice(currentStatusIndex);
    }

    return (
      <>
        {/* width of select box in status will be managed from here  */}
        <FormControl sx={{ m: "4px", width: 150 }} size="small">
          <Select
            id={`discussion-status-${params.data?.enc_discussion_id}`}
            defaultValue={params.value}
            onChange={(e) => changeStatusHandle({
              id: params.data?.enc_discussion_id,
              discussion_status: e.target.value,
            })}
          >
            {STATUSES.map((item: any) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))} 
          </Select>
        </FormControl>
      </>
    )
  }

  const [columnDefs, ] = useState<any>([
    {
      headerName: 'Discussion ID',
      field: 'discussion_id',
    },
    {
      headerName: 'Discussion Type',
      field: 'discussion_type',
    },
    {
      headerName: 'Order ID',
      field: 'order_id',
    },
    {
      headerName: 'Client Email',
      field: 'client_email_address',
      sortable: false,
    },
    {
      headerName: 'Meeting Link',
      field: 'meeting_link',
    },
    {
      headerName: 'Created By',
      field: 'created_by_name',
      sortable: false,
    },
    { 
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter', 
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false, 
      },
      pinned: 'right'
    },
    {
      headerName: 'Status',
      field: 'discussion_status',
      cellRenderer: statusColumnRenderer,
      pinned: 'right'
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable : false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 70,
      minWidth: 80,
      pinned: 'right'
    },
  ])

  const closeStatusConfirmModal = () => {
    setStatusConfirmModal(false)
    setupGrid(agGridParams)
  }

  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      spacing={2}
      sx={{ mb: '20px' }}
    > 
     <h1>Discussion</h1>
      <Box sx={{ mb: '20px' }}>
        <Button
          onClick={() => navigate('../create')}
          variant="contained"
          startIcon={<AddCircleIcon />}
          sx={{ display : (permissions && permissions.indexOf(AllPermissionList.ADD_DISCUSSION) >= 0) ? "inline-flex" : "none" }}
        >
          NEW DISCUSSION
        </Button>
      </Box>
      </Stack>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
        rowHeight={55}
      />
      <Box className="record-per-page-filter" sx={{ mt: '10px', ml: '-10px' }}>
        <RecordPerPage
          style={{ width: "150px" }}
          defaultValue={`${perPage}`}
          onChange={(e:any)=> setPageSize(e.target.value)}
        />
      </Box>
      <StatusConfirmModal visible={statusConfirmModal} close={closeStatusConfirmModal} />
    </>
  )
})

export default DiscussionList
