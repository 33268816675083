import React from 'react'
import { observer } from 'mobx-react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import useStore from '../../../../store'
import { toast } from 'react-toastify';
import Toaster from '../../../../config/Toaster'

const ClientDelete: React.FC<any> = observer(({ visible, close }) => {
  const { CLIENT } = useStore();
  const { deleteData, deleteValues } = CLIENT;

  const deleteRecord = async () => {
    try{
        await deleteData(deleteValues)
        close()
        toast.success('Client deleted successfully!', Toaster);
    }catch(err:any){
        close()
        console.error(err);
        toast.error(err.message, Toaster);
    }
  }

  return (
    <>
      <div>
        <Dialog
          open={visible}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this record?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={deleteRecord} autoFocus>
              Yes, Delete!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
})

export default ClientDelete
