import { DEFAULT_CURRENCY } from "../../../../../config/Constant";

// Function to convert USD price to other currencies
function convertToOtherCurrencies(usdPrice: any, exchangeRates: any) {
  try{
    const convertedPrices: any[] = [];
    
    for (const rate of exchangeRates) {
      const { currency, conversion_rate } = rate;
    const convertedPrice = usdPrice * parseFloat(conversion_rate);
    convertedPrices.push({ currency, convertedPrice: Math.round(convertedPrice) });
  }
  return convertedPrices;
  }catch(err: any){
    console.error(err);
    return [];
  }
}

export const handleUsdPriceChange = (event: any, timeSlotIndex: any, currencyIndex: any, type: any, setValue: any, trigger: any, exchangeRates: any, isFromTimeslotModule: any = 0) => {
  try{
    // only onChange of USD value
    if(currencyIndex !== 0 || event.target.value === null || event.target.value === undefined || event.target.value === ""){
      return;
    }
  
    const newUsdPrice = parseFloat(event.target.value);
    const convertedPrices = convertToOtherCurrencies(newUsdPrice, exchangeRates);

    if(convertedPrices.length > 0){  
      convertedPrices.forEach((item: any, index: number) => {
      if(item.currency === DEFAULT_CURRENCY){
        return;
      }
      
      // Backend Path : \app\Traits\CommonTraits.php , function : getTimeslotsWithCurrencies
      // REMEMBER : make sure order of currency_conversion_rates and any other currencies array coming from backend should be the same
      
      // if i'm changing the price from timeslot module or else other modules (esp,addons,layout)
      if(isFromTimeslotModule === 1){
        setValue(`currencies.${index}.${type}`, item?.convertedPrice || 0)
        trigger(`currencies.${index}.${type}`)
      }else{
        setValue(`timeslots.${timeSlotIndex}.currencies.${index}.${type}`, item?.convertedPrice || 0)
        trigger(`timeslots.${timeSlotIndex}.currencies.${index}.${type}`)
      }
      })
    }
  }catch(err: any){
    console.error(err);
  }
};