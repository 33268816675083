import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import AlertComponent, {
  AlertData,
} from '../../../../components/utilities/AlertComponent'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { dateFormat } from '../../../../config/Constant'

const Profile = observer(() => {
  const navigate = useNavigate()
  const { AUTH } = useStore()
  const { getMyProfile } = AUTH
  const {
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      first_name: '',
      last_name: '',
      employee_id: '',
      email: '',
      role_name: '',
      department_name: '',
      shift_name: '',
      reporting_manager_name: '',
      status: '1',
      confirmation_date: null,
    },
  })

  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)

  const setInitialFormValues = async (_userData: any) => {
    try{
      await setValue('first_name', _userData?.first_name || '')
      await setValue('last_name', _userData?.last_name || '')
      await setValue('employee_id', _userData?.employee_id || '')
      await setValue('confirmation_date', _userData?.confirmation_date || null)
      await setValue('email', _userData?.email || '')
      await setValue('status', _userData?.status || '')
      await setValue('role_name', _userData?.role_name || '')
      await setValue('department_name', _userData?.department_name || '')
      await setValue('shift_name', _userData?.shift_name || '')
      await setValue('reporting_manager_name', _userData?.reporting_manager_name || '')
    }catch(err: any){
      
    }
    return true
  }

  const fetchUserData = async () => {
    try {
      const resData: any = await getMyProfile()
      if (resData.error === false) {

        await setInitialFormValues(resData?.data?.user)
      } else {
          window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        setAlertMessage({
          severity:'error',
          message: resData.message,
          data: resData.data
        });
         toast.error(resData?.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      const errData: BaseResponse = err
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        setAlertMessage({
          severity:'error',
          message: errData.message,
          data: errData.data
        });
      toast.error(errData.message, Toaster)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  return (
    <>
     <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mb: 4, mt: 2 }}
            >
              Profile
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="first_name"
                      label="First Name"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                      helperText={errors.first_name?.message}
                      error={errors.first_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="last_name"
                      label="Last Name"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                      helperText={errors.last_name?.message}
                      error={errors.last_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      id="email"
                      label="Email"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                      helperText={errors.email?.message}
                      error={errors.email ? true : false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="employee_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="employee_id"
                      label="Employee ID"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                      helperText={errors.employee_id?.message}
                      error={errors.employee_id ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                    name={`confirmation_date`}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        readOnly={true}
                        {...field}
                        inputFormat={dateFormat} // Specify your desired date format
                        disablePast={true}
                        onChange={(newValue: any) => {
                          field.onChange(newValue)
                        }}
                        label="Confirmation Date"
                        renderInput={(params) => (
                          <TextField
                            id="confirmation_date"
                            fullWidth
                            {...params}
                            InputProps={{ readOnly: true }}
                            helperText={errors.confirmation_date?.message}
                            error={errors.confirmation_date ? true : false}
                          />
                        )}
                      />
                    )}
                  />
              </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="department_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      label="Department"
                      defaultValue={''}
                      fullWidth
                      InputProps={{ readOnly: true }}
                      helperText={errors.department_name?.message}
                      error={errors.department_name ? true : false}
                    >
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="reporting_manager_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      label="Reporting Manager"
                      defaultValue={field.value}
                      fullWidth
                      InputProps={{ readOnly: true }}
                      helperText={errors.reporting_manager_name?.message}
                      error={errors.reporting_manager_name ? true : false}
                    >
                   
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="shift_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      label="Shift"
                      defaultValue={field.value}
                      fullWidth
                      InputProps={{ readOnly: true }}
                      helperText={errors.shift_name?.message}
                      error={errors.shift_name ? true : false}
                    >
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="role_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="role"
                      label="Role"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                      helperText={errors.role_name?.message}
                      error={errors.role_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="button" variant="outlined" size="medium" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default Profile
