import React, { useMemo } from 'react'
import { AgGridReact, AgGridReactProps, AgReactUiProps } from 'ag-grid-react'
import Config from '../../config/Config'
import 'ag-grid-enterprise'
import Box from '@mui/material/Box'

interface AgGridWrapperProps extends AgReactUiProps, AgGridReactProps {
  type?: 'serverSide' | 'clientSide'
}

const AgGridWrapper: React.FC<AgGridWrapperProps> = ({
  type = 'clientSide',
  ...rest
}) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const gridType =
    type === 'serverSide' ? Config.grid.server : Config.grid.local

  return (
    <Box>
      <Box style={containerStyle}>
        <Box style={gridStyle} className="ag-theme-alpine ag-grid-wrapper">
          <AgGridReact {...gridType} {...rest} />
        </Box>
      </Box>
    </Box>
  )
}

export default AgGridWrapper
