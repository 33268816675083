import axios from 'axios'
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { makeAutoObservable } from 'mobx'
import API_URL from '../../config/ApiUrl'
import { DefaultPointCutoffListProps } from './DefaultPointCutoffInterface'
import Config from '../../config/Config'
import loadingStore from '../LoadingStore/LoadingStore'

export default class DefaultPointCutoffStore {
  agGridParams: any = null
  perPage = Config.grid.local.gridOptions?.paginationPageSize
  public list_data?: DefaultPointCutoffListProps[]
  editValues = null
  deleteValues = null

  constructor() {
    makeAutoObservable(this)
  }

  // Setup grid and set column size to autosize
  setGrid = (params: any) => {
    this.agGridParams = params
  }

  public setupGrid = (params: GridReadyEvent) => {
    this.setGrid(params)
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions)
    params.api.setServerSideDatasource(datasource)
  }

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  public setPageSize = (page: any = this.perPage) => {
    this.perPage = page
    if (this.agGridParams) {
      this.agGridParams.api.paginationSetPageSize(page)
    }
  }

  private createDatasource = (gridOptions?: GridOptions) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        // const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(params.request).then((data) => {
          params.success({
            rowData: data.default_point_cutoffs.rows,
            rowCount: data.default_point_cutoffs.lastRow,
          })
        }).catch((err: any) => {
          console.error(err)
        })
      },
    }
  }

  setDeleteValues = (data: any) => {
    this.deleteValues = data
  }

  setEditValues = (data: any) => {
    this.editValues = data
  }

  // Setter Functions
  private setListData = (value?: DefaultPointCutoffListProps[]): void => {
    this.list_data = value
  }

  // API Functions
  public fetchList = async (payload: any): Promise<any> => {
    try{
      this.setListData([])
      const { data } = await axios.post(API_URL.DEFAULT_POINT_CUTOFF.LIST, payload)
      return Promise.resolve(data.data)
    } catch(err: any) {
      return Promise.reject(err.response.data)
    }
  }

  public createDefaultPointCutoff = async (_data: any) => {
    try {
      loadingStore.setAppLoading(true)
      const { data } = await axios.post(API_URL.DEFAULT_POINT_CUTOFF.CREATE, _data)
      loadingStore.setAppLoading(false)
      return Promise.resolve(data)
    } catch (err:any) {
      loadingStore.setAppLoading(false)
      return Promise.reject(err.response.data)
    }
  }

  public fetchDefaultPointCutoff = async (_data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     const { data } = await axios.get(API_URL.DEFAULT_POINT_CUTOFF.VIEW(_data));
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
   }

   public updateDefaultPointCutoff = async (_id:number, _data: any): Promise<any> => {
    try{
     loadingStore.setAppLoading(true);
     _data.action_type = "Update";
     const { data } = await axios.put(API_URL.DEFAULT_POINT_CUTOFF.UPDATE(_id), _data);
     loadingStore.setAppLoading(false);
     return Promise.resolve(data);
    }catch(err:any){
     loadingStore.setAppLoading(false);
     return Promise.reject(err.response.data);
    }
   }
}
