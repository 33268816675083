import React, {  useState } from 'react'
import useStore from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EditIcon from '../../../../assets/images/note.png'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'
import { Tooltip, IconButton, Stack } from '@mui/material'
import { observer } from 'mobx-react'
import AllPermissionList from '../../../../config/AllPermissionList'

const ZoneList = observer(() => {
  const { ZONE, AUTH } = useStore()
  const navigate = useNavigate()
  const { setupGrid, setPageSize, perPage } = ZONE
  const {permissions} = AUTH


  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip title="Edit" sx={{ display : (permissions && permissions.indexOf(AllPermissionList.EDIT_ZONE) >= 0) ? "inline-flex" : "none" }}>
          <IconButton
            onClick={() => {
              navigate('../edit/' + params?.data?.enc_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </>
    )
  }


  const [columnDefs, ] = useState<any>([
    {
      headerName: 'Client Email',
      field: 'client_email',
    },
    {
      headerName: 'Order ID',
      field: 'order_id',
    },
    {
      headerName: 'Zone',
      field: 'zone',
    },
    {
      headerName: 'Created By',
      field: 'created_by_name',
    },
    {
      headerName: 'Updated By',
      field: 'updated_by_name',
    },
    {
      headerName: 'Created At',
      field : 'created_at',
      filter: 'agDateColumnFilter', 
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false, 
      },
      pinned: 'right'
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable : false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 70,
      minWidth: 80,
      pinned: 'right'
    },
  ])


  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      spacing={2}
      sx={{ mb: '20px' }}
    > 
     <h1>Zones</h1>
      <Box sx={{ mb: '20px' }}>
        <Button
          onClick={() => navigate('../create')}
          variant="contained"
          startIcon={<AddCircleIcon />}
          sx={{ display : (permissions && permissions.indexOf(AllPermissionList.ADD_ZONE) >= 0) ? "inline-flex" : "none" }}
        >
          NEW ZONE
        </Button>
      </Box>
      </Stack>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
      />
      <Box className="record-per-page-filter" sx={{ mt: '10px', ml: '-10px' }}>
        <RecordPerPage
          style={{ width: "150px" }}
          defaultValue={`${perPage}`}
          onChange={(e:any)=> setPageSize(e.target.value)}
        />
      </Box>
    </>
  )
})

export default ZoneList