import { createContext, useContext } from "react";
import { Context } from "vm";
import AuthStore from "./AuthStore/AuthStore";
import RootStore from "./RootStore/RootStore";
import UserStore from "./UserStore/UserStore";
import ClientStore from "./ClientStore/ClientStore";
import ClientOrderStore from "./ClientOrderStore/ClientOrderStore";
import QuoteStore from "./QuoteStore/QuoteStore";
import OrderStore from "./OrderStore/OrderStore";
import ChangeRequestStore from "./ChangeRequestStore/ChangeRequestStore";
import BacklogStore from "./BacklogStore/BacklogStore";
import DiscussionStore from "./DiscussionStore/DiscussionStore";
import FeedbackStore from "./FeedbackStore/FeedbackStore";
import RoleStore from "./RoleStore/RoleStore";
import CouponStore from "./CouponStore/CouponStore";
import ChecklistStore from "./ChecklistStore/ChecklistStore";
import DepartmentStore from "./DepartmentStore/DepartmentStore";
import ShiftStore from "./ShiftStore/ShiftStore";
import EscalationStore from "./EscalationStore/EscalationStore";
import EscalationCategoryStore from "./EscalationCategoryStore/EscalationCategory";
import ExtraPointStore from "./ExtraPointStore/ExtraPointStore";
import EarningPointsStore from "./EarningPoints/EarningPointsStore";
import EarningPointLogStore from "./EarningPointLogStore/EarningPointLogStore";
import DefaultPointCutoffStore from "./DefaultPointCutoffStore/DefaultPointCutoffStore";
import ESPStore from "./ESPStore/ESPStore";
import AddonStore from "./AddonStore/AddonStore";
import LayoutStore from "./LayoutStore/LayoutStore";
import TimeslotStore from "./TimeslotStore/TimeslotStore";
import CurrencyStore from "./CurrencyStore/CurrencyStore";
import ZoneStore from "./ZoneStore/ZoneStore";

const AppContext = createContext({
    ROOT                  :   new RootStore(),
	AUTH                  :   new AuthStore(),
	USER                  :   new UserStore(),
	CLIENT                :   new ClientStore(),
	CLIENT_ORDER          :   new ClientOrderStore(),
	QUOTE                 :   new QuoteStore(),
	ORDER                 :   new OrderStore(),
	CHANGE_REQUEST        :   new ChangeRequestStore(),
	BACKLOG               :   new BacklogStore(),
	DISCUSSION            :   new DiscussionStore(),
	FEEDBACK              :   new FeedbackStore(),
	ROLE                  :   new RoleStore(),
	COUPON                :   new CouponStore(),
	CHECKLIST             :   new ChecklistStore(),
	DEPARTMENT            :   new DepartmentStore(),
	SHIFT                 :   new ShiftStore(),
	ESCALATION            :   new EscalationStore(),
	ZONE                  :   new ZoneStore(),
	ESCALATION_CATEGORY   :   new EscalationCategoryStore(),
	EXTRA_POINT           :   new ExtraPointStore(),
	EARNING_POINT_LOG     :   new EarningPointLogStore(),
	DEFAULT_POINTS        :   new EarningPointsStore(),
	DEFAULT_POINT_CUTOFF  :   new DefaultPointCutoffStore(),
	ESP                   :   new ESPStore(),
	ADDON                 :   new AddonStore(),
	LAYOUT                :   new LayoutStore(),
	TIMESLOT              :   new TimeslotStore(),
	CURRENCY              :   new CurrencyStore(),
});

const useStore = (): Context => useContext(AppContext);

export default useStore;