import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  CardActions,
  Button,
  Grid,
  InputAdornment,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import AlertComponent, {
  AlertData,
} from '../../../../../../components/utilities/AlertComponent'
import { BaseResponse } from '../../../../../../interfaces/ApiResponse'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DynamicRolePointsRow from './DynamicRolePointsRow'
import { observer } from 'mobx-react'
import useStore from '../../../../../../store'
import Toaster from '../../../../../../config/Toaster'
import { toast } from 'react-toastify'

const DefaultEarningPointsEdit = observer(() => {
  const { id } = useParams()
  const { DEFAULT_POINTS } = useStore()
  const {
    fetchRolesArray,
    viewDefaultEarningPoints,
    updateDefaultEarningPoints,
  } = DEFAULT_POINTS
  const [alertMessage, setAlertMessage] = useState<AlertData | null>(null)
  const navigate = useNavigate()
  const [pointApplicableTo, setPointApplicableTo] = useState<any>(0)
  const [pointValueType, setPointValueType] = useState<any>(0) 

  const validationSchema = Yup.object().shape({
    user_roles: Yup.array().of(
      Yup.object().shape({
        user_role_id: Yup.string().required('Role name is required'),
        point: Yup.number()
          .required('Points name is required')
          .typeError('Please enter valid points')
          .min(-99, 'Points must be greater than -99')
          .max(100, 'Points must be less or equal to 100'),
      }),
    ),
    point: Yup.number()
      .nullable()
      .typeError('Please enter valid points')
      .test('conditional-validation', 'Point is required', function (
        value: any,
      ) {
        if (pointApplicableTo === 1 && !value) {
          return false
        }
        return true
      }).test('conditional-validation-percentage', 'Point must be between 0 to 100', function (
        value: any,
      ) {
        if ((pointApplicableTo === 1 && pointValueType === 1) && (!value || value < 0 || value > 100)) {
          return false
        }
        return true
      }),
  })

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      user_roles: [],
      point: 0,
    },
  })

  const {
    fields: roleFields,
    append: appendRoleFields,
    remove: removeRoleFields,
  } = useFieldArray<any>({
    control,
    name: 'user_roles',
  })

  function hasUniqueUserRoleIds(data: any) {
    const userRoleIds = new Set();
    for (const item of data) {
      if (userRoleIds.has(item.user_role_id)) {
        return false;
      }
      userRoleIds.add(item.user_role_id);
    }
    // it's unique
    return true;
  }

  const onSubmit = async (_data: any) => {
    try {
      if(pointApplicableTo === 0){
        if(_data?.user_roles){
          if(_data?.user_roles.length <= 0){
            toast.error("Please add alteast one role's points", Toaster)
            return;   
          }
        }
        const areRoleIdsUnique = hasUniqueUserRoleIds(_data?.user_roles);
        if(areRoleIdsUnique === false){
          toast.error("Please select unique roles for points", Toaster)
          return;
        }
      }
      const resData: BaseResponse = await updateDefaultEarningPoints(id, _data)
      if (resData.error === false) {
        toast.success(resData?.message, Toaster)
        navigate("../point-system")
      } else {
        toast.error(resData?.message, Toaster)
        setAlertMessage({
          severity: 'error',
          message: resData.message,
          data: resData.data,
        })
      }
    } catch (err: any) {
      console.error(err)
      const errData: BaseResponse = err
      toast.error(errData?.message, Toaster)
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setAlertMessage({
        severity: 'error',
        message: errData.message,
        data: errData.data,
      })
    }
  }

  const initFormFields = async () => {
    try {
      const resData: BaseResponse = await viewDefaultEarningPoints(id)
      if (resData.error === false) {
        setPointApplicableTo(
          resData?.data?.default_point_type?.point_applicable_to,
        )
        setPointValueType(
          resData?.data?.default_point_type?.point_value_type,
        )
        if (resData.data.default_point_type.user_roles.length > 0) {
          resData.data.default_point_type.user_roles.forEach(
            (fieldValue: any, index: any) => {
              appendRoleFields({
                user_role_id: fieldValue.user_role_id,
                point: fieldValue.point,
              })
            },
          )
        }
        setValue('point', resData?.data?.default_point_type?.point || 0)
      } else {
        toast.error(resData?.message, Toaster)
        setAlertMessage({
          severity: 'error',
          message: resData.message,
          data: resData.data,
        })
      }
    } catch (err: any) {
      console.error(err)
      const errData: BaseResponse = err
      toast.error(errData?.message, Toaster)
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setAlertMessage({
        severity: 'error',
        message: errData.message,
        data: errData.data,
      })
    }
  }
  useEffect(() => {
    console.error(errors)
    fetchRolesArray()
    initFormFields()
  }, [])
  return (
    <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Edit Point Type -{' '}
              {pointApplicableTo === 0
                ? 'Dynamic Role Wise'
                : 'Assigned Users Wise'}
            </Typography>

            {pointApplicableTo === 0 && (
              <>
                {/* Dynamic Block */}
                <Box>
                  <Grid item xs={12}>
                    {roleFields.map((item, index) => {
                      return (
                        <DynamicRolePointsRow
                          key={index}
                          id={index}
                          errors={errors}
                          control={control}
                          item={item}
                          removeJobsFields={removeRoleFields}
                        />
                      )
                    })}
                  </Grid>
                  {/* ./Dynamic Block */}
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      size="medium"
                      startIcon={<AddCircleIcon />}
                      variant="contained"
                      color="success"
                      onClick={() =>
                        appendRoleFields({
                          user_role_id: '',
                          point: 0,
                        })
                      }
                    >
                      Add
                    </Button>
                  </Grid>
                </Box>
              </>
            )}
            {pointApplicableTo === 1 && (
              <>
                <Box>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="assigned_users"
                        label="Assigned Users"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="point"
                        control={control}
                        render={({ field, fieldState }: any) => (
                          <TextField
                            fullWidth
                            id="name"
                            type="text"
                            label="Points"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message}
                            {...field}
                            InputProps={pointValueType === 1 ?{
                              endAdornment: (
                                <InputAdornment position="end">%</InputAdornment>
                              ),
                            } : {}}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={(e) => navigate(-1)}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default DefaultEarningPointsEdit
