import { Tooltip, IconButton, Box, Button, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
import useStore from '../../../../../store'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditIcon from '../../../../../assets/images/note.png'

const ShiftList = () => {
  const { SHIFT } = useStore()
  const navigate = useNavigate()
  const { fetchList } = SHIFT
  const [rowData, setRowData] = useState<any>([])

  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              navigate('edit/' + params.value)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const [columnDefs] = useState<any>([
    {
      headerName: 'Shift Name',
      field: 'name',
    },
    {
      headerName: 'Action',
      field: 'id',
      sortable: false,
      filter: false,
      cellRenderer: actionColumnRenderer,
    },
  ])

  useEffect(() => {
    fetchList()
      .then((data: any) => {
        setRowData(data)
      })
      .catch((error: any) => {
        setRowData([])
      })
  }, [fetchList])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        spacing={2}
        sx={{ my: '20px' }}
      >
        <Box></Box>
        <Box sx={{ my: '20px' }}>
          <Button
            onClick={() => navigate('create')}
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            NEW SHIFT
          </Button>
        </Box>
      </Stack>
      <Box sx={{}}>
        <AgGridWrapper
          type="clientSide"
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </Box>
    </>
  )
}

export default ShiftList
