import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Box, CardContent, Typography, TextField, CardActions, Button, Grid, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { observer } from 'mobx-react'
import AlertComponent, { AlertData } from '../../../../../../components/utilities/AlertComponent';
import Toaster from '../../../../../../config/Toaster';
import { BaseResponse } from '../../../../../../interfaces/ApiResponse';
import useStore from '../../../../../../store';


const validationSchema = Yup.object().shape({
    name: Yup.string().required("Sub-category name is required").min(2, "sub-category name must be at least 2 characters long").matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
    category:  Yup.object({
      label: Yup.string()
        .required('Category is required'),
      id: Yup.number().nullable(),
    }).required('Category is required'),
  });

const SubCategoryCreate = observer(() => {
    const navigate = useNavigate()
    const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
    const [categoryMenuItems, setCategoryMenuItems] = useState<any>([])
    const { ESCALATION_CATEGORY } = useStore();
    const { createEscalationSubCategory, fetchCategoryList } = ESCALATION_CATEGORY;
    const {
      handleSubmit,
      setError,
      control,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
      mode: "onTouched",
      defaultValues: {
        name: "",
        category: { label: '', id: null },
      },
    });
  
    const loadFormFieldData = async () => {
      try{
        const res = await fetchCategoryList();
        const data = res.map((e: any) =>  ({id: e.id, label: e.name}))
        setCategoryMenuItems(data)
      }catch(err: any){

      }
    }
    
    const onSubmit = async (_data: any) => {
      try {
        const postData = {
          name: _data?.name,
          escalation_category_id: _data?.category?.id,
        }
        const resData: BaseResponse = await createEscalationSubCategory(postData);
          if(resData.error === false){
            toast.success(resData?.message, Toaster)
            navigate("../")
          }else{
            window.scrollTo({top: 0, behavior: 'smooth' });
            setAlertMessage({severity:'error', message: resData.message, data: resData.data});
            toast.error(resData?.message, Toaster)
          }
        } catch(err:any){
          const errData: BaseResponse = err;
          window.scrollTo({top: 0, behavior: 'smooth' });
          setAlertMessage({severity:'error', message: errData.message, data: errData.data});
          if(errData.data && errData.data['name']){
            setError("name", {
              type: "manual",
              message: errData.data['name'][0],
            });
          }
          toast.error(errData?.message, Toaster);
        }
    };
  
  
    useEffect(() => {
      loadFormFieldData();
    }, [])
    
    return (
      <>
        <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
        <Card sx={{ maxWidth: '100%' }} variant='outlined'>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ mt: 2, mb: 2 }}
              >
                Add Sub-category
              </Typography>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={6}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="name"
                        label="Sub-category Name"
                        variant="outlined"
                        helperText={errors.name?.message}
                        error={errors.name ? true : false}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                <Controller
                  name="category"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={categoryMenuItems ?? []}
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Category"
                          variant="outlined"
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              </Grid>
            </CardContent>
            <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
                Save
              </Button>
              <Button type="button" variant="outlined" size="medium" onClick={ (e) => navigate(-1)}>
                Cancel
              </Button>
            </CardActions>
          </Box>
        </Card>
      </>
    );
})

export default SubCategoryCreate