import { toast } from 'react-toastify'
import Toaster from './Toaster'

export const appConfig = {
    version: process.env.REACT_APP_VERSION ?? "1.0.0"
}

export const Notification = {
    success: (data: any) => {
      toast.success(data.message, Toaster)
    },
    error: (data: any) => {
      toast.error(data.message, Toaster)
    },
};