import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Box,
  CardContent,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  CardActions,
  Button,
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import * as uuid from 'uuid'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import AlertComponent, {
  AlertData,
} from '../../../../components/utilities/AlertComponent'
import { ESCALATION_ZONE } from '../../../../config/Constant'

const ZoneEdit = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { id } = useParams()
  const { ZONE } = useStore()
  const { updateZone, fetchZone } = ZONE
  const [loading, setLoading] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<AlertData | null>(null)
  const [zoneHistory, setZoneHistory] = useState<any>([])
  const validationSchema = Yup.object().shape({
    order_id: Yup.string()
      .required('Order ID is required')
      .matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
    client_email: Yup.object({
        label: Yup.string()
          .required('No client is associated with this order id')
          .email('Invalid email address'),
        id: Yup.string().nullable(),
    }).required('No client is associated with this order id'),
    zone: Yup.string().required('zone is required'),
    remarks: Yup.string().required('Remarks is required'),
  })

  const [initialZoneValue, setInitialZoneValue] = useState<any>("")
  const [changedZoneValue, setChangedZoneValue] = useState<any>("")
  const [isDialogOpen, setIsDialogOpen] = useState<any>(false)
  const [remarks, setRemarks] = useState<any>("")

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  const closeDialog = () => {
    setChangedZoneValue("")
    setRemarks("")
    setIsDialogOpen(false)
  }

  const {
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      order_id: '',
      client_email: { label: '', id: null },
      zone: '',
      remarks: '',
    },
  })

  const setInitialFormValues = async (_zoneData: any) => {
    try {
      const zoneData = _zoneData?.zone
      setZoneHistory(zoneData.zone_history)
      setValue('order_id', zoneData?.order_id || '')
      setValue('client_email', zoneData?.client_email || { id: null, label: '' })
      setValue('zone', zoneData?.zone)
      setInitialZoneValue(zoneData?.zone)
    } catch (err: any) {}
    return true
  }

  const fetchZoneData = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchZone(_id)
      if (resData.error === false) {
        await setInitialFormValues(resData?.data)
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  useEffect(() => {
    fetchZoneData(id)
  }, [id])

  const saveRemarks = async () => {
    try {
        if(remarks === "" || remarks === null || remarks === undefined){
          toast.error("Please enter remarks!", Toaster); 
          return
        }
        setLoading(true)
        let _data:any = {
            id : id,
            zone: changedZoneValue,
            remarks : remarks,
        }
        const resData: any = await updateZone(_data)
        if (resData.error === false) {
            toast.success(resData.message, Toaster)
            closeDialog()
            fetchZoneData(id)
        } else {
          toast.error(resData.message, Toaster)
        }
       setLoading(false)
      } catch (err: any) {
        closeDialog()
        setLoading(false)
        console.error(err)
        toast.error(err.message, Toaster)
      }
  }

  return (
    <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 3, mb: 3, px: 2 }}
            >
              Edit Zone
            </Typography>
            <Grid
              sx={{ px: 2 }}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 3, sm: 6, md: 12 }}
            >
              <Grid item xs={4}>
                <Controller
                  name="order_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="order_id"
                      label="Order ID"
                      variant="outlined"
                      helperText={errors.order_id?.message}
                      error={errors.order_id ? true : false}
                      {...field}
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="client_email"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={[]}
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      disabled={true}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Email"
                          variant="outlined"
                          error={fieldState.invalid}
                          helperText={
                            fieldState?.error?.message ||
                            fieldState?.error?.label?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="zone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-select-zone"
                      select
                      label="Zone"
                      placeholder="Zone"
                      fullWidth
                      helperText={errors.zone?.message}
                      error={errors.zone ? true : false}
                      onChange={(e: any) => {
                        field.onChange(e)
                        if (e.target.value !== initialZoneValue) {
                          // Open the dialog when the zone is changed
                          if(e?.target?.value){
                              setChangedZoneValue(e?.target?.value)
                              openDialog()
                          }
                        }
                      }}
                    >
                      <MenuItem key={uuidValue()} value={''} disabled>
                        Select Zone
                      </MenuItem>
                      {ESCALATION_ZONE &&
                        ESCALATION_ZONE.map((e: any) => (
                          <MenuItem key={uuidValue()} value={e.label}>
                            {e.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>

              {/* Zone History */}
              { zoneHistory.length > 0 && 
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>History</Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date & Time</TableCell>
                        <TableCell>Zone</TableCell>
                        <TableCell>Remarks</TableCell>
                        <TableCell>Changed By</TableCell>
                        {/* Add more headers as needed */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {zoneHistory.map((item: any) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.created_at}</TableCell>
                          <TableCell>{item.zone}</TableCell>
                          <TableCell>{item.remarks}</TableCell>
                          <TableCell>{item.user_name}</TableCell>
                          {/* Add more table cells with data as needed */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              }

            </Grid>
          </CardContent>
          <CardActions sx={{ mb: 2 , ml: 2}}>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={() => navigate('../list')}
            >
              Back to List
            </Button>
          </CardActions>
        </Box>
      </Card>

      {/* Dialog for entering remarks */}
      <Dialog maxWidth={`sm`} fullWidth={true} open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Confirm Change to ({changedZoneValue}) ?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="remarks"
            label="Remarks"
            type="text"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            fullWidth
            variant="standard"
            placeholder='Please enter your remarks'
            multiline
            rows={2}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 1 , ml: 1}}>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
          <LoadingButton onClick={() => {saveRemarks()}} type="submit" variant="contained" size="medium" loading={loading}>
              Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default ZoneEdit
